import {
  GET_NEW_RVOD,
  SEARCH_RVOD_ERROR,
  SEARCH_RVOD_SUCCESS,
} from "../actions/types";

const initialState = null;
export const rvodReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_NEW_RVOD: {
      return {
        ...state,
        newArrivals: payload.contents,
      };
    }

    case SEARCH_RVOD_SUCCESS: {
      return {
        ...state,
        searchResults: {
          contentNameMon: payload.contentNameMon,
          contentNameEng: payload.contentNameEng,
          arPrice: payload.arPrice,
          promoArPrice: payload.promoArPrice,
          isPromo: payload.isPromo,
          contentId: payload.contentId,
        },
      };
    }

    case SEARCH_RVOD_ERROR: {
      return {
        ...state,
        searchResults: null,
      };
    }
    default: {
      return state;
    }
  }
};
