import {
  ACCOUNT_PAYMENT,
  CARD,
  MERCHANT_PAYMENT,
  QPAY,
  SOCIAL_PAY,
  UPOINT_PAYMENT,
} from "../actions/types";
import card from "../assets/img/merchant/Card.png";
import ddish from "../assets/img/merchant/Ddish.png";
import qPay from "../assets/img/merchant/QPay.png";
import upoint from "../assets/img/merchant/UPoint.png";
import socialPay from "../assets/img/merchant/SocialPay.png"

export const upointPayment = [
  {
    name: UPOINT_PAYMENT,
    nameText: "Upoint дансаар төлөх",
    img: upoint,
    text: "Та өөрийн Upoint дансаар төлөх боломжтой.",
    type: UPOINT_PAYMENT,
  },
];
export const accountPayment = [
  {
    name: ACCOUNT_PAYMENT,
    nameText: "Ddish дансаар төлөх",
    img: ddish,
    text: "Та өөрийн Ddish дансаар төлөх боломжтой.",
    type: ACCOUNT_PAYMENT,
  },
];
export const qpayPayment = [
  {
    name: QPAY,
    nameText: "QPay",
    img: qPay,
    text: "QPay ашиглан та ихэнх банкны Application-оор төлөх боломжтой.",
    type: MERCHANT_PAYMENT,
  },
];
export const merchantPayment = [
  {
    name: CARD,
    nameText: "Банкны карт",
    img: card,
    text: "Банкны картаар гүйлгээ хийх боломжтой. Та олон ашигладаг картаа холбох боломжтой.",
    type: MERCHANT_PAYMENT,
  },
  {
    name: SOCIAL_PAY,
    nameText: "SocialPay",
    img: socialPay,
    text: "SocialPay service ашиглаж гүйлгээ хийх боломжтой",
    type: MERCHANT_PAYMENT,
  },
];
