import axios from "axios";
import { useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  Row,
} from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import { webApiUrl } from "../helper/globals";
import * as messageActions from "../actions/messages";
import { TOAST_SUCCESS, TOAST_WARNING } from "../actions/types";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";

function Contact(props) {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  const [ip, setIp] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    let userId = auth.isLoggedIn ? user.adminNumber : "guest";
    let captcha = document.getElementById("captcha").value;
    if (validateCaptcha(captcha)) {
      let body = {
        user_id: userId,
        name: data.name,
        phone: data.phone,
        email: data.email,
        content: data.content,
        type: data.type,
        ip,
        site: "ddish",
      };
      axios
        .post(webApiUrl + "send_feedback", body)
        .then((res) => {
          props.setMessage({
            type: TOAST_SUCCESS,
            text: res.data.message,
          });
          navigate("/");
        })
        .catch((err) => {
          console.log("err.data", err.data);
        });
    } else {
      props.setMessage({
        type: TOAST_WARNING,
        text: "Captcha-г зөв оруулна уу.",
      });
    }
  };

  useEffect(() => {
    loadCaptchaEnginge(6, "#0047ba", "white", "numbers");
    axios.get("https://api.ipify.org?format=json").then((res) => {
      setIp(res.data.ip);
    });
  });
  return (
    <Container className="section-content">
      <Row>
        <div class="small-12 columns mar-btm-30">
          <ul class="row medium-up-2 large-up-3 block">
            <li class="column">
              <div class="contact-box">
                <i class="ddish contact"></i>
                <p>
                  Монгол улс, Улаанбаатар хот 14200,
                  <br />
                  Сүхбаатар дүүрэг, 8-р хороо,
                  <br />
                  Сэнтрал Тауэр, 14-р давхар
                </p>
              </div>
            </li>
            <li class="column">
              <div class="contact-box">
                <i class="calloffnet contact"></i>
                <p>
                  Лавлах төв: 77771434, 1434
                  <br /> Оффис: 77770101 <br />
                  Факс: 77770202
                </p>
              </div>
            </li>
            <li class="column">
              <div class="contact-box">
                <i class="sms contact"></i>
                <p>info@ddishtv.mn</p>
              </div>
            </li>
          </ul>
        </div>
      </Row>

      <Form name="form" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <FormGroup>
              <Form.Control
                id="name"
                type="text"
                placeholder="Нэр"
                isInvalid={errors.name}
                {...register("name", { required: true })}
              />
              <FormControl.Feedback type="invalid">
                Та нэрээ оруулна уу.
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup>
              <Form.Control
                id="phone"
                type="number"
                maxLength={8}
                placeholder="Утасны дугаар"
                {...register("phone", { required: true })}
                isInvalid={errors.phone}
              />
              <FormControl.Feedback type="invalid">
                Та утсаа оруулна уу.
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup>
              <Form.Control
                id="email"
                type="email"
                placeholder="Имэйл хаяг"
                {...register("email", { required: true })}
                isInvalid={errors.email}
              />
              <FormControl.Feedback type="invalid">
                Та email хаягаа оруулна уу.
              </FormControl.Feedback>
            </FormGroup>
            <FormGroup>
              <div style={{ display: "flex", alignItems: "top" }}>
                <Form.Check
                  inline
                  id="feedback"
                  name="type"
                  type="radio"
                  label="Санал"
                  value="feedback"
                  {...register("type", { required: true })}
                  isInvalid={errors.type}
                />
                <Form.Check
                  id="complaint"
                  name="type"
                  inline
                  type="radio"
                  label="Гомдол"
                  value="complaint"
                  {...register("type", { required: true })}
                  isInvalid={errors.type}
                />
                <Form.Check
                  id="partnership"
                  inline
                  name="type"
                  type="radio"
                  label="Хамтран ажиллах"
                  value="partnership"
                  {...register("type", { required: true })}
                  isInvalid={errors.type}
                />
              </div>
              {errors.type && (
                <span style={{ color: "red", fontSize: "0.875em" }}>
                  Та санал хүсэлтийн төрлөө оруулна уу.
                </span>
              )}
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <FormControl
                id="content"
                rows="4"
                as="textarea"
                placeholder="Энд бичнэ үү..."
                {...register("content", { required: true })}
                isInvalid={errors.content}
              />
              <FormControl.Feedback type="invalid">
                Та санал хүсэлтээ оруулна уу.
              </FormControl.Feedback>
            </FormGroup>
            <Row>
              <Col>
                <FormGroup>
                  <Form.Control
                    id="captcha"
                    type="text"
                    placeholder="Зураг дээрх код"
                  />
                </FormGroup>
              </Col>
              <Col>
                <LoadCanvasTemplate />
              </Col>
            </Row>
          </Col>
          <Col md={{ offset: 9, span: 3 }}>
            <button
              className="button success had-icon icon-sms"
              type="submit"
              style={{ float: "right" }}
            >
              Илгээх
            </button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
const mapActionToProps = (dispatch) => {
  return {
    setMessage: (payload) => dispatch(messageActions.setMessage(payload)),
  };
};
export default connect(null, mapActionToProps)(Contact);
