import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router";
import useWindowDimensions from "./GetWindowDimensions";

function OnlineOrderShowForm({ finalCheck, closeForm }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isLoading, setIsLoading] = useState();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const location = useLocation();
  const handleBackButton = () => {
    navigate("/");
  };
  if (location.pathname === "/online-order-user-form") {
    window.addEventListener("popstate", handleBackButton);
  }
  const [data, setData] = useState();
  const final = JSON.parse(localStorage.getItem("order"));
  const [org, setOrg] = useState("");
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_ONLINE_ORDER_URL}/v1/address/getAddress/${final.aimagId}/${final.sumId}/${final.bagId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "ZGRfb25saW5lMTpERClPTkxJTkUx",
          },
        }
      )
      .then((res) => {
        setData(res.data.addressInfo[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleSubmit = (value) => {
    setIsLoading(true);
    let pre = {
      ...final,
      organizationNo: org !== "" && org.length > 6 && isValid ? org : "",
    };
    axios
      .post(
        `${process.env.REACT_APP_ONLINE_ORDER_URL}/v1/OrderItem/orderReg`,
        pre,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "ZGRfb25saW5lMTpERClPTkxJTkUx",
          },
        }
      )
      .then(function (response) {
        if (response.data.isSuccess == false) {
          toast.info(`${response.data.resultMessage}`, {
            position: "top-right",
          });
          setIsLoading(false);
        } else {
          navigate("/online-payment", {
            state: {
              qpay: response.data.qpayInfo,
              invoice: response.data.orderCode,
              bundle: final.bundleId,
              channel: final.channelNo,
              qpayUrl: response.data.qpayInfo.qpayUrl,
            },
          });
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        setIsLoading(false);
      });
  };
  const [show, setShow] = useState(false);
  const showModal = () => {
    setShow(true);
  };
  const [showInput, setShowInput] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [name, setName] = useState("");
  const handleE = (el) => {
    if (el == "I") {
      setShowInput(true);
    } else {
      setOrg("");
      setShowInput(false);
    }
  };
  const handleSubmitOrg = (val) => {
    if (val.target.value.length == 7) {
      if (val.target.value.length > 6) {
        axios
          .get(
            `${process.env.REACT_APP_ONLINE_ORDER_URL}/v1/OrderItem/organizationCheck/${val.target.value}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "ZGRfb25saW5lMTpERClPTkxJTkUx",
              },
            }
          )
          .then(function (response) {
            if (response.data.resultMessage !== "Амжилттай") {
              toast.info(`${response.data.resultMessage}`, {
                position: "top-right",
              });
            } else {
              setTooltip(true);
              setName(response.data.name);
              setIsValid(true);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  };

  return (
    <div className=" select-none online-order-container-main-absolute2">
      {isLoading && (
        <div className="online-order-container-main-absolute center">
          <div className="loading-body">
            <div className="spinner-border text-primary" role="status"></div>
            <span className="sr-only loading-body-text">Түр хүлээнэ үү...</span>
          </div>
        </div>
      )}
      {show && (
        <div className="body-body">
          <div className="pop-up">
            <div className="box2 pt-3 mt-5 spacer">
              <div
                onClick={() => {
                  setShow(false);
                }}
                className="close-button"
              >
                &#10006;
              </div>
              <div className="p-3">
                <h6>Санамж</h6>
                <p>
                  Онлайн захиалга хийхэд буцаалт хийх боломжгүй тул эрхэм
                  хэрэглэгч та сонголтоо зөв хийнэ үү
                </p>
                <h5
                  style={{
                    textTransform: "uppercase",
                    marginTop: "10px",
                  }}
                >
                  E-баримт авах төрлийг сонгоно уу.
                </h5>
                <select
                  onChange={(e) => {
                    handleE(e.target.value);
                  }}
                  name=""
                  id=""
                  style={{ width: "200px" }}
                >
                  <option disabled value="0">
                    Сонгох
                  </option>
                  <option value="C">Хувь хүн</option>
                  <option value="I">Байгууллага</option>
                </select>
                {showInput && (
                  <div
                    className="relative w-100"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      onChange={(e) => {
                        setOrg(e.target.value);
                        // setShow(!show);
                        setTooltip(false);
                        setIsValid(false);
                        handleSubmitOrg(e);
                      }}
                      type="text"
                      className="text-cus5 mb-5"
                      placeholder="Код"
                      maxLength={7}
                    />
                    {tooltip && (
                      <div className="relative">
                        <div className="tooltip-top"></div>
                        <div className="tooltip-body">
                          <span>{name}</span>
                          <button
                            onClick={() => {
                              setTooltip(false);
                            }}
                          >
                            x
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <a
                target={"_blank"}
                onClick={() => {
                  handleSubmit("app");
                }}
                className="button select-none"
              >
                Төлбөр төлөх
              </a>
            </div>
          </div>
        </div>
      )}
      <div style={{ height: "56px" }}></div>
      <div
        style={{ justifyContent: "start" }}
        className="container width-100 pt-4 pt-md-4 pb-2"
      >
        <h3>
          <i>Таны захиалга:</i>
        </h3>
      </div>
      <div className="container width-100">
        <div className="select-cell">
          <h6>Овог</h6>
          <div className="react-select-container-block2">{final.lName}</div>
        </div>
        <div className="select-cell ">
          <h6>Нэр</h6>
          <div className="react-select-container-block2">{final.fName}</div>
        </div>
        <div className="select-cell">
          <h6>Регистрийн дугаар</h6>
          <div className="react-select-container-block2">{final.regNo}</div>
        </div>
      </div>
      <div className="container width-100 pb-2">
        <div className="select-cell">
          <h6>Холбоо барих дугаар</h6>
          <div className="react-select-container-block2">{final.phoneNo1}</div>
        </div>
        <div className="select-cell ">
          <h6>Холбогдох нэмэлт дугаар</h6>
          <div className="react-select-container-block2">{final.phoneNo2}</div>
        </div>
        <div className="select-cell"></div>
      </div>
      <div className="container width-100 ">
        <div className="select-cell">
          <h6>Хот, Аймаг</h6>
          <div className="react-select-container-block2">{data?.aimagName}</div>
        </div>

        <div className="select-cell">
          <h6>Дүүрэг, Сум</h6>
          <div className="react-select-container-block2">{data?.sumName}</div>
        </div>

        <div className="select-cell ">
          <h6>Хороо, Баг</h6>
          <div className="react-select-container-block2">{data?.bagName}</div>
        </div>
      </div>
      <div className="container width-100 pt-1">
        <div className="select-cell">
          <h6>Гудамж, Тоот</h6>
          <div className="react-select-container-block3">{final?.streetNo}</div>
        </div>
      </div>

      <div className="container width-100">
        <div className="select-cell mt-2">
          <h6>Хүргэлтийн дэлгэрэнгүй хаяг</h6>
          <div className="react-select-container-block3">
            {final.fullAddress}
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", flexDirection: "row" }}
        className="container width-100 "
      >
        <div className="select-cell mt-3 mt-md-3">
          <h6>Сонгосон багц төхөөрөмж: </h6>
          <div className="img-container">
            {final.bundleId == 2 ? (
              <img
                className="img-product"
                src="https://www.ddishtv.mn:3000/u/device/Intek box bagts.jpg"
                alt=""
              />
            ) : (
              <img
                className="img-product"
                src="https://www.ddishtv.mn:3000/u/device/Bagts%20tuhuurumj%202.jpg"
                alt=""
              />
            )}
          </div>
        </div>
      </div>
      <div className="bottom-indicator pt-2 bottom-container">
        <div style={{ display: "flex", gap: "10px" }}>
          <div className="">
            {finalCheck.fName !== "" && (
              <div
                onClick={() => {
                  closeForm(false);
                }}
                type="submit"
                className="bubbly-button4 select-none"
              >
                Засах
              </div>
            )}
          </div>
          <div className="">
            <div
              onClick={() => {
                localStorage.clear();
                window.location.reload();
              }}
              type="submit"
              className="bubbly-button15 select-none"
            >
              Устгах
            </div>
          </div>
        </div>

        <div className="">
          <div
            onClick={() => {
              showModal();
            }}
            type="submit"
            className="bubbly-button5 select-none"
          >
            Баталгаажуулах
          </div>
        </div>
      </div>
      <div className="pb-5 pt-3"></div>
    </div>
  );
}

export default OnlineOrderShowForm;
