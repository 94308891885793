const productsInner = [
  {
    product_content: "standard",
    product_title: "standard",
    product_id: 107,
    created_at: "2023-02-09 14:29:37",
    alias:
      "Гэр бүлийн гишүүн бүрд зориулж олон төрлийн сувгуудаар төрөлжүүлсэн багц",
    packages: "86+ суваг",
    help_id: 3,
    certain_img: "standart-ch.png",
    selectable: "12HD",
    selectable_desc: `Монголын бүх ТВ сувгууд; Өргөн нэвтрүүлгийн ТВ сувгууд | Арилжааны ТВ сувгууд | Орон нутгийн ТВ сувгууд; Гадаад дотоодын төрөлжсөн сувгууд; Хүүхдийн | Энтертайнмент | Танин мэдэхүй | Кино сувгууд | Спорт сувгууд
      `,
    price: 15000,
    picture: "standard.png",
    menu_order: 6,
    is_active: 1,
    show: "true",
    updated_at: "2023-02-09 14:29:37",
    is_app: 1,
    eng_selectable_desc: "",
  },
  {
    product_content: "standard",
    product_title: "standard",
    product_id: 108,
    created_at: "2023-02-09 14:29:37",
    alias:
      "Монгол гадаадын шинэ шилдэг кинонуудыг төрөл жанраар нь төрөлжүүлсэн хамгийн олон кино сувагтай багц",
    packages: "120+ суваг",
    help_id: 3,
    certain_img: "happy-ch.png",
    selectable: "25HD",
    selectable_desc:
      "Монголын бүх ТВ сувгууд; Өргөн нэвтрүүлгийн ТВ сувгууд | Арилжааны ТВ сувгууд | Орон нутгийн ТВ сувгууд; Гадаад дотоодын төрөлжсөн сувгууд; Хүүхдийн | Энтертайнмент | Танин мэдэхүй | Кино сувгууд | Спорт сувгууд; Хамгийн олон кино сувгууд; Холливуд болон дэлхийн шинэ, шилдэг кино, контентуудыг монгол дуу оруулалт, дуу дүрсний өндөр чанартайгаар хүргэх зөвхөн ДДЭШ-ээр гардаг 10 кино суваг | Дэлхийн шилдэг HBO кино сувгууд",
    price: 25000,
    picture: "happy.png",
    menu_order: 6,
    is_active: 1,
    show: "true",
    updated_at: "2023-02-09 14:29:37",
    is_app: 1,
    eng_selectable_desc: "",
  },
  {
    product_content: "super",
    product_title: "super",
    product_id: 109,
    created_at: "2023-02-09 14:29:37",
    alias:
      "Монгол гадаадын алдартай бүх төрлийн спортын контентын сувгуудыг хамгийн олон кино сувгуудаар нэмж баяжуулсан багц",
    packages: "124+ суваг",
    help_id: 3,
    certain_img: "super-ch.png",
    selectable: "29HD",
    selectable_desc: `Монголын бүх ТВ сувгууд; Өргөн нэвтрүүлгийн ТВ сувгууд | Арилжааны ТВ сувгууд | Орон нутгийн ТВ сувгууд; Гадаад дотоодын төрөлжсөн сувгууд; Хүүхдийн | Энтертайнмент | Танин мэдэхүй | Кино сувгууд | Спорт сувгууд; Хамгийн олон кино сувгууд; Холливуд болон дэлхийн шинэ, шилдэг кино, контентуудыг монгол дуу оруулалт, дуу дүрсний өндөр чанартайгаар хүргэх зөвхөн ДДЭШ-ээр гардаг 10 кино суваг | Дэлхийн шилдэг HBO кино сувгууд;
    Спортын шилдэг контентуудтай спорт сувгууд; Үндэсний бөхийн алтан цом | Монголын Сагсан бөмбөгийн дээд лиг MBA | Монголын Воллейболын дээд лиг MVA | FIFA 3x3 | NBA | FIFA | Champions league | Bundesleague | Premiere league | Europa league Seria A | One champoinship | UFC`,
    price: 30000,
    picture: "super.png",
    menu_order: 6,
    is_active: 1,
    show: "true",
    updated_at: "2023-02-09 14:29:37",
    is_app: 1,
    eng_selectable_desc: "",
  },
  {
    product_content:
      '<div>\n<div>\n<p style="margin-left:0in; margin-right:0in">ТВ суваг&nbsp;</p>\n\n<p style="margin-left:0in; margin-right:0in">120+ суваг</p>\n\n<p style="margin-left:0in; margin-right:0in">Интернэт - үндсэн хурд 2 Mbps</p>\n\n<p style="margin-left:0in; margin-right:0in">50GB Дата эрх</p>\n\n<p style="margin-left:0in; margin-right:0in">Шөнийн цагаар /01:00-08:00/ хурд - 5Mbps</p>\n\n<p style="margin-left:0in; margin-right:0in">Шөнийн цагаар дата - хязгааргүй&nbsp;</p>\n\n<p style="margin-left:0in; margin-right:0in">Сүлжээндээ хязгааргүй яриа&nbsp;</p>\n\n<p style="margin-left:0in; margin-right:0in">77-той дугаар луу - Хязгааргүй үнэгүй&nbsp;</p>\n\n<p style="margin-left:0in; margin-right:0in">Юнителийн сүлжээнд - 30 минут</p>\n\n<p style="margin-left:0in; margin-right:0in">КИНО САН-с сар бүр 1 кино үзэх эрх</p>\n\n<p style="margin-left:0in; margin-right:0in">LookTV эрх</p>\n\n<p style="margin-left:0in; margin-right:0in">Суваг - ҮНЭГҮЙ</p>\n\n<p style="margin-left:0in; margin-right:0in">Кино багц - Үнэгүй&nbsp;</p>\n\n<p style="margin-left:0in; margin-right:0in">ҮНЭ : 33&#39;000 / 1 сар</p>\n</div>\n</div>\n',
    product_title: "Гэр дүүрэн 33",
    product_id: 0,
    created_at: "2020-05-14 10:38:41",
    alias: "Ger",
    packages: "null",
    help_id: 2,
    selectable: "null",
    selectable_desc:
      "ТВ суваг  120+ суваг; Интернэт - үндсэн хурд 2 Mbps 50GB Дата эрх  Шөнийн цагаар /01:00-08:00/ хурд - 5Mbps  Шөнийн цагаар дата - хязгааргүй ;Сүлжээндээ хязгааргүй яриа  77-той дугаар луу - Хязгааргүй үнэгүй  Юнителийн сүлжээнд - 30 минут;КИНО САН-с сар бүр 1 кино үзэх эрх; LookTV эрх Суваг - ҮНЭГҮЙ  Кино багц - Үнэгүй",
    price: 35500,
    picture: "ger.jpg ",
    menu_order: 2,
    is_active: 1,
    show: "false",
    updated_at: "2020-06-16 10:28:32",
    is_app: 0,
    eng_selectable_desc:
      "120+ channels;Internet - 2Mbps, 50GB Data; At night /01:00 am-08:00 am/ - 5Mbps, unlimited data; Calls to 77****** numbers – Unlimited and free; Calls in the Unitel network – 30 minutes free ; VOD: 1 movie credit each month; LookTV: TV channel – FREE Movie streaming – FREE",
  },
  {
    product_content:
      "<p>ТВ суваг&nbsp;</p>\r\n\r\n<p>128+ суваг</p>\r\n\r\n<p>Интернэт - үндсэн хурд 4 Mbps</p>\r\n\r\n<p>100GB дата эрх</p>\r\n\r\n<p>Шөнийн цагаар /01:00-08:00/ хурд - 5Mbps</p>\r\n\r\n<p>Шөнийн цагаар дата хязгааргүй&nbsp;</p>\r\n\r\n<p>Шилжих хурдаар ашиглах дата - 100GB&nbsp;</p>\r\n\r\n<p>Шилжих хурд - 128Kbps</p>\r\n\r\n<p>Сүлжээндээ хязгааргүй яриа</p>\r\n\r\n<p>77-той дугаар руу - Хязгааргүй минут</p>\r\n\r\n<p>Юнителийн сүлжээнд 30 минут</p>\r\n\r\n<p>КИНО САН-с сар бүр 3 кино үзэх эрх</p>\r\n\r\n<p>LookTV эрх</p>\r\n\r\n<p>Суваг - ҮНЭГҮЙ</p>\r\n\r\n<p>Кино багц - ҮНЭГҮЙ</p>\r\n\r\n<p>ҮНЭ: 39&#39;000 / 1 сар</p>\r\n",
    product_title: "Гэр дүүрэн 39",
    product_id: 0,
    created_at: "2020-05-28 17:41:55",
    alias: "ger2",
    packages: "",
    help_id: 1,
    selectable: "",
    selectable_desc:
      "ТВ суваг  128+ суваг ; Интернэт - үндсэн хурд 4 Mbps  100GB дата эрх  Шөнийн цагаар /01:00-08:00/ хурд - 5Mbps  Шөнийн цагаар дата хязгааргүй   Шилжих хурдаар ашиглах дата - 100GB   Шилжих хурд - 128Kbps ; Сүлжээндээ хязгааргүй яриа  77-той дугаар руу - Хязгааргүй минут  Юнителийн сүлжээнд 30 минут ;  КИНО САН-с сар бүр 3 кино үзэх эрх  ; LookTV эрх  Суваг - ҮНЭГҮЙ  Кино багц - ҮНЭГҮЙ ",
    price: 41500,
    picture: "ger.jpg ",
    menu_order: 6,
    is_active: 1,
    show: "false",
    updated_at: "2020-06-16 10:28:44",
    is_app: 0,
    eng_selectable_desc:
      "128+ channels; Internet - 4Mbps, 100GB Data; At night /01:00 am-08:00 am/ - speed 5Mbps, unlimited data; Calls to 77****** numbers – Unlimited and free; Calls in the Unitel network – 30 minutes free; VOD: 3 movie credits each month; LookTV: TV channel – FREE Movie streaming – FREE",
  },
];

module.exports = { productsInner };
