import { useEffect, useState } from "react";
import { Container, ListGroup, Row, Tab, TabContainer } from "react-bootstrap";
import AdditionalChannels from "./AdditionalChannels";
import AllChannels from "./AllChannels";
import PaidChannels from "./PaidChannels";
import Rural from "./Rural";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../Layout/Breadcrumb";

export default function Channels() {
  const { category } = useParams();
  const [key, setKey] = useState(category);
  const [header, setHeader] = useState("СУВГУУД");
  useEffect(() => {
    setKey(category);
  }, [category]);

  return (
    <Breadcrumbs
      title={header}
      content={
        <TabContainer activeKey={key}>
          <Container className="section-content">
            <Row>
              <ListGroup className="list-group-horizontal-md">
                <ListGroup.Item
                  eventKey="main"
                  action
                  href="#"
                  className="tabs-title tabs"
                  onClick={() => {
                    setKey("main");
                    setHeader("СУВГУУД");
                  }}
                >
                  СУВГУУД
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  eventKey="paid"
                  href="#"
                  className="tabs-title tabs"
                  onClick={() => {
                    setKey("paid");
                    setHeader("ТӨЛБӨРТ СУВГУУД");
                  }}
                >
                  ТӨЛБӨРТ СУВГУУД
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  eventKey="additional"
                  href="#"
                  className="tabs-title tabs"
                  onClick={() => {
                    setKey("additional");
                    setHeader("НЭМЭЛТ БАГЦ");
                  }}
                >
                  НЭМЭЛТ БАГЦ
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  eventKey="rural"
                  href="#"
                  className="tabs-title tabs"
                  onClick={() => {
                    setKey("rural");
                    setHeader("ОРОН НУТАГ");
                  }}
                >
                  ОРОН НУТАГ
                </ListGroup.Item>
              </ListGroup>
            </Row>
          </Container>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <AllChannels />
            </Tab.Pane>
            <Tab.Pane eventKey="paid">
              <PaidChannels />
            </Tab.Pane>
            <Tab.Pane eventKey="additional">
              <AdditionalChannels />
            </Tab.Pane>
            <Tab.Pane eventKey="rural">
              <Rural />
            </Tab.Pane>
          </Tab.Content>
        </TabContainer>
      }
      route={[{ url: "/channel/main", text: "Сувгууд" }]}
    />
  );
}
