import { Button, Col, Container, Row } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getAnonymous } from "../helper/requests";
import * as messageActions from "../actions/messages";
import { TOAST_INFO } from "../actions/types";

function QPay(props) {
  const merchant = useSelector((state) => state.merchant);
  const product = useSelector((state) => state.product);
  const order = useSelector((state) => state.order);
  const navigate = useNavigate();
  if (merchant?.qpay == null) return <></>;
  function checkInvoice() {
    getAnonymous(
      "/qpay/26/" + order.orderId,
      (res) => {
        if (res.data.isSuccess) {
          navigate("/service/payment/" + order.orderId);
        } else {
          props.setMessage({
            type: TOAST_INFO,
            text: "Та гүйлгээгээ дахин шалгана уу.",
          });
        }
      },
      (err) => {}
    );
  }

  function topTable() {
    let descriptionText = "";
    if (product != null && product.selectedProduct) {
      descriptionText = product.selectedProduct.orderType.text;
      return (
        <div>
          <table borderless>
            <thead>
              <th centered>БҮТЭЭГДЭХҮҮН</th>
              <th centered>ТАЙЛБАР</th>
              <th centered>ДҮН</th>
            </thead>
            <tbody>
              <tr>
                <td>{product.selectedProduct.product.productName}</td>
                <td>{descriptionText}</td>
                <td>{product.selectedProduct?.price}₮ </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
  }
  return (
    <Container>
      <Row>{topTable}</Row>
      <Row>
        <Col sm={4}>
          Та дараах QR code-ыг банкний application-аар уншуулж төлбөрөө төлнө
          үү!
        </Col>
        <Col sm={8}>
          <a href={merchant.qpay.qpayUrl}>
            <img
              src={merchant.qpay.qrImageUrl}
              alt="qr payment"
              onError={(e) => {
                e.target.onError = null;
                e.target.src =
                  "data:image/png;base64," + merchant.qpay.qrImageUrl;
              }}
            />
          </a>
          <Row>
            <Button
              onClick={() => {
                checkInvoice();
              }}
            >
              Төлбөр шалгах
            </Button>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
const mapActionToProps = (dispatch) => {
  return {
    setMessage: (payload) => dispatch(messageActions.setMessage(payload)),
  };
};
export default connect(null, mapActionToProps)(QPay);
