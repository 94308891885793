import { Badge, Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getDays } from "../../helper/helper";
import placeHolder from "../../assets/img/placeholder.png";
import { useEffect, useState } from "react";
import dateFormat from "dateformat";
import { get } from "../../helper/requests";

export default function NVod() {
  const auth = useSelector((state) => state.auth);
  let d = new Date();

  const [selectedChannel, setSelectedChannel] = useState();
  const [selectedDay, setSelectedDay] = useState(dateFormat(d, "yyyy-mm-dd"));

  const isLoggedIn = auth.isLoggedIn;
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    function getChannels() {
      get(
        "vodlist",
        (res) => {
          setChannels(res.data.vodChannels);
          setSelectedChannel(res.data.vodChannels[0]);
        },
        (err) => {}
      );
    }
    if (!isLoaded) {
      getChannels();
      setIsLoaded(true);
    }
  }, [isLoaded, isLoggedIn, selectedDay, selectedChannel]);
  const [channels, setChannels] = useState([]);
  const [movies, setMovies] = useState([]);
  const days = getDays(5);
  const navigate = useNavigate();
  useEffect(() => {
    function getMovies(selectedChannel, selectedDay) {
      get(
        `vodlist/prm/${selectedChannel?.productId}/${selectedDay}`,
        (res) => {
          setMovies(res.data.programList);
        },
        (err) => {
          setMovies([]);
        }
      );
    }
    getMovies(selectedChannel, dateFormat(selectedDay, "yyyymmdd"));
  }, [selectedChannel, selectedDay, isLoggedIn, isLoaded]);

  var movieCard = (movie) => {
    return (
      <>
        <Row>
          <Col sm="3">
            <img
              src={movie.posterUrl !== "" ? movie.posterUrl : placeHolder}
              onError={(err) => {
                err.target.onError = null;
                err.target.src = placeHolder;
              }}
              style={{
                width: "240px",
                height: "310px",
              }}
              alt={movie.contentNameMon}
            />
          </Col>
          <Col sm="8">
            <h3 style={{ color: "#0047ba" }}>{movie.contentNameMon}</h3>
            <Badge>{movie.contentGenres}</Badge>
            <br />
            <p>{movie.contentDescr}</p>
            <p>
              Цаг:
              <span className="movie-content-code">
                {dateFormat(movie.beginDate.replace(" ", "T"), "HH:MM")}
              </span>
            </p>
            <p>
              Код:
              <span className="movie-content-code">{movie.smsCode}</span>
            </p>
            Үнэ:
            <span className="movie-content-code">{movie.contentPrice}</span>
            <br />
            <br />
            <Button
              className="float-right"
              onClick={() => {
                navigate(
                  `/movie/${movie.contentId}/${
                    movie.productId
                  }/${movie.inDate.replace(/-/g, "")}/${
                    movie.smsCode
                  }/${dateFormat(movie.beginDate.replace(" ", "T"), "HHMM")}`
                );
              }}
            >
              Дэлгэрэнгүй
            </Button>
          </Col>
        </Row>
        <br />
      </>
    );
  };

  return channels != null
    ? channels.map((channel) => {
        return (
          <>
            <>
              <Col md={5}>
                <img
                  src={channel.channelLogo}
                  alt="channel logo"
                  width="50%"
                  onClick={() => setSelectedChannel(channel.productId)}
                />
              </Col>
            </>
            <Row style={{ marginTop: "30px" }}>
              {days?.map((e) => {
                return (
                  <Col
                    md={2}
                    onClick={() => {
                      setSelectedDay(e);
                    }}
                    className={
                      "item text-center movie-channel-item " +
                      (selectedDay === e ? "active" : "")
                    }
                    style={{}}
                  >
                    <a href>{e}</a>
                  </Col>
                );
              })}
            </Row>
            <br />
            {movies === [] ? (
              <>Тухайн өдрийн киноны мэдээллийг оруулаагүй байна.</>
            ) : (
              <>
                {movies?.map((e) => {
                  return movieCard(e);
                })}
              </>
            )}
          </>
        );
      })
    : null;
}
