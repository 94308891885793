import {
  Col,
  Container,
  Row,
  Modal,
  Spinner,
  ModalBody,
  ModalHeader,
} from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import * as userActions from "../../actions/user";
import * as accountActions from "../../actions/account";
import * as upointActions from "../../actions/upoint";
import * as merchantActions from "../../actions/merchant";
import * as messageActions from "../../actions/messages";

import {
  ACCOUNT_PAYMENT,
  MERCHANT_PAYMENT,
  QPAY_PAYMENT,
  TOAST_WARNING,
  UPOINT_PAYMENT,
} from "../../actions/types";
import { useNavigate } from "react-router";
import {
  accountPayment,
  merchantPayment,
  qpayPayment,
  upointPayment,
} from "../../helper/banks";
import QPay from "../QPay";
import { useEffect, useState } from "react";

function Order(props) {
  const auth = useSelector((state) => state.auth);
  const merchant = useSelector((state) => state.merchant);
  const user = useSelector((state) => state.user);
  const order = useSelector((state) => state.order);
  useEffect(() => {
    if (auth.isLoggedIn) {
      props.getAccount();
    }
  }, [auth.isLoggedIn]);
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [loaderShow] = useState(false);
  const product = useSelector((state) => state.product);
  const selectedProduct = useSelector(
    (state) => state?.product?.selectedProduct ?? null
  );
  function accountOrder() {
    props.accountOrder(product.selectedProduct);
    return navigate("/service/payment/complete/");
  }
  function upointOrder() {
    props.upointOrder(product.selectedProduct);
    return navigate("/service/payment/complete/");
  }
  function topTable() {
    let descriptionText = "";
    if (product != null && product.selectedProduct) {
      descriptionText = product?.selectedProduct?.orderType?.text;
      return (
        <table borderless style={{ overflowY: "scroll" }}>
          <thead>
            <th centered>БҮТЭЭГДЭХҮҮН</th>
            <th centered>ТАЙЛБАР</th>
            <th centered>ДҮН</th>
          </thead>
          <tbody>
            <tr>
              <td>{product.selectedProduct.product.productName}</td>
              <td>{descriptionText}</td>
              <td>{product.selectedProduct?.price}₮ </td>
            </tr>
          </tbody>
        </table>
      );
    }
  }
  if (product == null) {
    navigate("/");
  }
  const [count, setCount] = useState(0);
  const [back, setBack] = useState(false);
  if (order?.orderId != null && order?.paymentUrl != null && count > 0) {
    navigate("/order/bank");
  }
  function bankTable(bank) {
    return (
      <Col>
        <div
          class="card card-custom"
          onClick={() => {
            props.merchantOrder(bank.name, selectedProduct, auth.isLoggedIn);
            setModalShow(true);
            setCount(count + 1);
            // handleModal();
          }}
        >
          <div class="row ">
            <div class="col-md-3">
              <img
                src={bank.img}
                class="img-fluid rounded-start card-child"
                alt={bank.alt}
                style={{
                  width: "75px",
                  margin: "10px 20px",
                  position: "relative",
                }}
              />
            </div>
            <div class="col-md-9">
              <div class="card-body">
                <h5 class="card-title">{bank.nameText}</h5>
                <p class="card-text">{bank.text}</p>
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  }
  function qpay(bank) {
    return (
      <Col>
        <div
          class="card card-custom"
          onClick={() => {
            props.merchantOrder(bank.name, selectedProduct, auth.isLoggedIn);
            setModalShow(true);
            setCount(count + 1);
            setBack(true);
            // handleModal();
          }}
        >
          <div class="row ">
            <div class="col-md-3">
              <img
                src={bank.img}
                class="img-fluid rounded-start card-child"
                alt={bank.alt}
                style={{
                  width: "75px",
                  margin: "10px 20px",
                  position: "relative",
                }}
              />
            </div>
            <div class="col-md-9">
              <div class="card-body">
                <h5 class="card-title">{bank.nameText}</h5>
                <p class="card-text">{bank.text}</p>
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  }

  function accountTable(bank) {
    return (
      <Col>
        <div
          className="card card-custom"
          onClick={() => {
            if (
              product?.selectedProduct?.price >
              parseInt(user?.account?.counter?.counterBalance)
            ) {
              props.setMessage({
                type: TOAST_WARNING,
                text: "Үндсэн дансны үлдэгдэл хүрэлцэхгүй байна.",
              });
            } else accountOrder();
          }}
        >
          <div class="row">
            <div class="col-md-3">
              <img
                src={bank.img}
                class="img-fluid rounded-start card-child"
                alt={bank.alt}
                style={{ width: "75px", margin: "10px 20px" }}
              />
            </div>
            <div class="col-md-9">
              <div class="card-body">
                <h5 class="card-title">{bank.nameText}</h5>
                <p class="card-text">{bank.text}</p>
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  }

  function upointTable(bank) {
    return (
      <Col>
        <div
          class="card card-custom"
          onClick={() => {
            upointOrder();
          }}
        >
          <div class="row ">
            <div class="col-md-3">
              <img
                src={bank.img}
                class="img-fluid rounded-start card-child"
                alt={bank.alt}
                style={{
                  width: "75px",
                  margin: "10px 20px",
                  position: "relative",
                }}
              />
            </div>
            <div class="col-md-9">
              <div class="card-body">
                <h5 class="card-title">{bank.nameText}</h5>
                <p class="card-text">{bank.text}</p>
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  }
  return (
    <Container className="section-content" fluid>
      <Row>{topTable()}</Row>
      <Row>
        <Col md={{ span: 12 }}>
          <h3>
            Таны төлөх дүн:{" "}
            <span style={{ color: "#0047bb" }}>
              {product?.selectedProduct?.price ?? ""}₮
            </span>
          </h3>
          {auth.isLoggedIn ? (
            <h3>
              Таны дансны үлдэгдэл:{" "}
              <span style={{ color: "#0047bb" }}>
                {user?.account?.counter?.counterBalance ?? ""}₮
              </span>
            </h3>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <Row className=" mar-btm-50">
        <label>Үйлчилгээний нөхцөл</label>
        <div class="callout">
          <div class="container">
            <h6>Ерөнхий заалт</h6>
            <ul>
              <li>
                <a href="http://www.ddishtv.mn">www.ddishtv.mn</a>&nbsp;нь ДДэш
                ТВ ХХК-ийн бүтээгдэхүүн үйлчилгээ, худалдааны албан ёсны вэбсайт
                бөгөөд энэхүү журам нь уг вэб сайтаас үйлчилгээ авах, худалдаа
                хийхтэй холбоотойгоор үүсэх харилцааг зохицуулахад оршино.
              </li>
              <li>
                Энэхүү журам нь ДДэш-ийн хэрэглэгч вэбсайтаар үйлчилгээ авах,
                худалдаа хийхээс өмнө хүлээн зөвшөөрч, баталгаажуулсны үндсэн
                дээр хэрэгжинэ.
              </li>
            </ul>
            <p>
              <strong>Вэбсайт түүний үйл ажиллагаа</strong>
            </p>
            <p>
              <strong>
                <u>Ерөнхий</u>
              </strong>
            </p>
            <ul>
              <li>
                <a href="http://www.ddishtv.mn">www.ddishtv.mn</a>
                &nbsp;вэбсайт нь тогтмол үйл ажиллагаатай байж, байнга
                шинэчлэгдэн, ДДэш ТВ ХХК-ийн хамгийн сүүлийн үеийн мэдээ
                мэдээлэл болон бүтээгдэхүүн үйлчилгээнүүдийг онлайнаар
                хэрэглэгчдэд үзүүлэхэд чиглэсэн зорилго, бүтэцтэй байна.
              </li>
              <li>
                <a href="http://www.ddishtv.mn">www.ddishtv.mn</a>&nbsp;нь өдөр
                бүр 24 цагаар олон нийтэд нээлттэй ажиллана.
              </li>
              <li>
                Онлайн чат нь ажлын өдрүүдэд 09:00-22:00, амралтын өдрүүдэд
                10:00-19:00 цагийн хооронд хэрэглэгчдийн сонирхсон асуултанд
                хариулна.
              </li>
              <li>
                Вэбсайттай холбоотой болон шинэ үйлчилгээ, бараа бүтээгдэхүүний
                талаарх санал гомдлоо{" "}
                <a href="mailto:business_planning@ddishtv.mn">
                  business_planning@ddishtv.mn
                </a>
                -рүү и-мэйл илгээн шийдвэрлүүлж болно.
              </li>
              <li>
                Хэрэглэгчээс гарсан гомдол, маргаантай асуудлыг{" "}
                <a href="http://www.ddishtv.mn">www.ddishtv.mn</a> вэбсайтын
                үйлчилгээний журам болон компанийн дотоод журам, Монгол улсын
                хууль тогтоомжийн дагуу шийдвэрлэнэ.
              </li>
            </ul>
            <ul>
              <li>
                Хэрэглэгч гишүүнчлэлийн нэр, нэвтрэх нууц үгээ бусдад алдсанаас
                үүсэх аливаа хариуцлагыг ДДэш ТВ ХХК хариуцахгүй.
              </li>
              <li>
                Хэрэглэгч төлбөрөө зөвхөн{" "}
                <a href="http://www.ddishtv.mn">www.ddishtv.mn</a> вэб хуудсаар
                санал болгосон банкны карт болон дансаар шилжүүлэн үйлчилгээ
                авах, худалдаа хийх боломжтой байна.
              </li>
            </ul>
            <p>
              <strong>
                <u>Мэдээлэл, үнэ тариф</u>
              </strong>
            </p>
            <ul>
              <li>
                ДДэш ТВ нь вэбсайтад тавигдсан мэдээллийн агуулга, үнэ тарифын
                шинэчлэл, урамшуулал болон шинэ үйлчилгээний мэдээлэл зэргийг
                бүрэн хариуцна.
              </li>
              <li>
                <a href="http://www.ddishtv.mn">www.ddishtv.mn</a>
                &nbsp;вэбсайтад байрласан үйлчилгээ болон худалдаалж буй
                бүтээгдэхүүн үйлчилгээний үнэ тарифт банкны шимтгэл багтсан
                болно.
              </li>
              <li>
                Бүтээгдэхүүн үйлчилгээ болон борлуулах барааны үнэ тариф
                өөрчлөгдөж болно.
              </li>
            </ul>
            <p>
              <strong>Төлбөр төлөх</strong>
            </p>
            <ul>
              <li>
                Хэрэглэгч өөрийн болон бусад хэрэглэгчийн ДДэш-ийн төлбөрийг
                төлөх боломжтой байна.
              </li>
              <li>
                Хэрэглэгч төлбөр төлөхдөө смарт картын дугаарыг заавал бичих
                болон төлөх мөнгөн дүнгээ сайтар шалгах шаардлагатай.
              </li>
              <li>
                Хэрэглэгчийн төлбөр төлсөн мэдээлэл програмд ирсэн даруйд энэ
                тухай мэдээллийг хэрэглэгчийн бүртгэлтэй дугаарт, мэйл хаягруу
                явуулна.
              </li>
            </ul>
            <p class="text-center">---ДДэш ТВ ХХК---</p>
          </div>
        </div>
      </Row>
      <Row xs={1} sm={1} md={2} lg={2}>
        {product?.selectedProduct?.orderType?.methods?.map((m) => {
          switch (m) {
            case ACCOUNT_PAYMENT: {
              return accountPayment.map((bank) => {
                return accountTable(bank);
              });
            }
            case QPAY_PAYMENT: {
              return qpayPayment.map((bank) => {
                return qpay(bank);
              });
            }
            case MERCHANT_PAYMENT: {
              return merchantPayment.map((bank) => {
                return bankTable(bank);
              });
            }
            case UPOINT_PAYMENT: {
              return auth.isLoggedIn
                ? upointPayment.map((bank) => {
                    return upointTable(bank);
                  })
                : null;
            }
            default:
              return null;
          }
        })}
      </Row>
      <Modal
        show={modalShow}
        centered
        onHide={() => {
          setModalShow(false);
          props.qpayClear();
        }}
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader closeButton />
        <ModalBody>
          {merchant?.qpay == null ? (
            <Row>
              <Col sm={3}>
                <Spinner animation="border" show={loaderShow} />
              </Col>
              <Col sm={9}>Сервертэй холбогдож байна.</Col>
            </Row>
          ) : (
            <QPay />
          )}
        </ModalBody>
      </Modal>
    </Container>
  );
}

const mapActionToProps = (dispatch) => {
  return {
    getAccount: () => dispatch(userActions.getCounter()),
    accountOrder: (order) => dispatch(accountActions.accountOrder(order)),
    merchantOrder: (bank, selectedProduct, saveCard) =>
      dispatch(merchantActions.merchantOrder(bank, selectedProduct, saveCard)),
    setMessage: (payload) => dispatch(messageActions.setMessage(payload)),
    orderClear: () => dispatch(merchantActions.orderClear()),
    qpayClear: () => dispatch(merchantActions.qpayClear()),
    upointOrder: (order) => dispatch(upointActions.upointOrder(order)),
  };
};
export default connect(null, mapActionToProps)(Order);
