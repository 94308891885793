import { Link } from "react-router-dom";
export default function Footer() {
  function showCurrentYear() {
    return new Date().getFullYear();
  }
  return (
    <footer>
      <div className="row">
        <ul>
          <li>
            <a href="/about">Бидний тухай</a>
          </li>
          <li className="ver-line ">
            <span className="cursor-initial">|</span>
          </li>
          <li>
            <Link to="press">Мэдээлэл</Link>
          </li>
          <li className="ver-line">
            <span className="cursor-initial">|</span>
          </li>
          <li>
            <Link to="branch">Салбарын байршил</Link>
          </li>
          <li className="ver-line">
            <span className="cursor-initial">|</span>
          </li>
          <li>
            <Link
              to="agreement"
              // onClick={() => {
              //   window.open(
              //     "https://ddishtv.mn:3000/u/DDish_undsen%20_geree.pdf",
              //     "_blank",
              //     "strWindowFeatures"
              //   );
              // }}
              // href
            >
              Үйлчилгээний нөхцөл
            </Link>
          </li>
          <li className="ver-line">
            <span className="cursor-initial">|</span>
          </li>
          <li>
            <Link to="jobs">Хүний нөөц</Link>
          </li>
          <li className="ver-line">
            <span className="cursor-initial">|</span>
          </li>
          <li>
            <Link to="contact">Холбоо барих</Link>
          </li>
          <li className="ver-line">
            <span className="cursor-initial">|</span>
          </li>
          <li>
            <a href="ddish_logo.zip">Лого татах</a>
          </li>

          {/* <li>
            <a href="/en">English</a>
          </li> */}
          <li className="text-right">&#169;{showCurrentYear()} DDISH</li>
        </ul>
      </div>
    </footer>
  );
}
