import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  FormLabel,
  Row,
  FormControl,
  Alert,
} from "react-bootstrap";
import { useEffect, useMemo, useState } from "react";
import { getAnonymous, postAnonymous } from "../helper/requests";
import DatePicker from "react-datepicker";
import * as actions from "../actions/messages";
import { TOAST_INFO, TOAST_SUCCESS, TOAST_WARNING } from "../actions/types";
import { connect } from "react-redux";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { dateToString } from "../helper/helper";
import { useNavigate } from "react-router";
function OrderForm(props) {
  const districts = useMemo(
    () => [
      {
        id: "1",
        name: "Баянгол",
      },
      {
        id: "2",
        name: "Чингэлтэй",
      },
      {
        id: "3",
        name: "Хан-Уул",
      },
      {
        id: "4",
        name: "Баянзүрх",
      },
      {
        id: "7",
        name: "СонгиноХайрхан",
      },
      {
        id: "9",
        name: "Сүхбаатар",
      },
    ],
    []
  );
  const [equipments, setEquipments] = useState([]);
  const [khoroos, setKhoroos] = useState([]);
  const [isloaded, setIsloaded] = useState(false);
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [equipmentEnabled, setEquipmentEnabled] = useState(true);
  const [farArea, setFarArea] = useState(false);
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    var kh = JSON.parse(data.khoroo);
    let isEquipment = document.getElementById("isEquipment").checked;
    let equipments = [];
    selectedEquipments.forEach((e) => {
      equipments.push(e.value);
    });
    let body = {
      channelNo: "5",
      callType: props.callType,
      districtNo: data.district,
      bagNo: kh.id,
      phone: data.phone,
      phone1: data.phone1,
      deliveryDate: dateToString(date),
      equipments: isEquipment === true ? equipments : [],
      addressDetial: data.addressDetail,
      note: data.note,
    };
    await postAnonymous(
      `neworder/neworder/CEC/`,
      body,
      (res) => {
        if (res.data.isSuccess) {
          props.setMessage({
            type: TOAST_SUCCESS,
            text: "Таны захиалгыг амжилттай хүлээж авлаа.",
          });
          navigate("../");
        } else
          props.setMessage({
            type: TOAST_WARNING,
            text: res.data.resultMessage,
          });
      },
      (err) => {
        props.setMessage({
          type: TOAST_WARNING,
          text: err.data.resultMessage,
        });
      }
    );
  };
  const onInvalid = (err) => {
    console.log(err);
  };

  const isWeekday = (date) => {
    const day = date.getDay();
    if (!farArea) {
      return true;
    } else return day !== 0 && day !== 1 && day !== 2 && day !== 4 && day !== 5;
  };
  useEffect(() => {
    if (!isloaded) {
      getEquipments();
      setIsloaded(true);
    }
  }, [isloaded, districts]);

  function getEquipments() {
    getAnonymous(`/newOrder/District/Type/1`, (res) => {
      let options = [];
      res.data.equipments.map((e) => {
        return options.push({ value: parseInt(e.id), label: e.latinName });
      });
      setEquipments(options);
    });
  }

  function getKhoroo(districtId) {
    getAnonymous(
      `/newOrder/District/${districtId}`,
      (res) => {
        if (!res.data.isSuccess) throw res.data;
        setKhoroos(res.data.khoroos);
      },
      (err) => {
        console.log("err.data", err);
        setKhoroos([]);
        props.setMessage({ type: TOAST_WARNING, text: err.resultMessage });
      }
    );
  }
  return (
    <Container className="section-container section">
      <Form onSubmit={handleSubmit(onSubmit, onInvalid)}>
        <Row>
          <Alert variant="warning">
            Зөвхөн Улаанбаатар хотод захиалга өгөх боломжтой
          </Alert>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <FormGroup>
              <Form.Label className="text-start">
                Дүүрэг <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Select
                id="district"
                {...register("district", { required: true })}
                defaultValue={null}
                onChange={(e) => {
                  getKhoroo(e.target.value);
                }}
                isInvalid={errors.district}
              >
                <option value="" selected disabled></option>
                {districts.map((e) => {
                  return <option value={e.id}>{e.name}</option>;
                })}
              </Form.Select>
              <FormControl.Feedback type="invalid">
                Дүүргээ оруулна уу
              </FormControl.Feedback>
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup>
              <Form.Label className="text-start">
                Хороо <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Select
                id="khoroo"
                {...register("khoroo", { required: true })}
                onChange={(e) => {
                  let value = JSON.parse(e.target.value);
                  let d = new Date();
                  d.setDate(d.getDate() + parseInt(value.availability));
                  setStartDate(d);
                  setDate(d);
                  if (value.range === "Холын нэмэгдэлтэй бүс") {
                    setFarArea(true);
                    props.setMessage({
                      type: TOAST_INFO,
                      text: "Таны сонгосон чиглэл холын бүсд харъяалагдаж байгаа тул зөвхөн Лхагва, Бямба гарагт очих боломжтой. Үйлчилгээний төлбөр 30.000₮",
                    });
                  } else {
                    setFarArea(false);
                  }
                }}
                isInvalid={errors.khoroo}
              >
                {khoroos?.map((e) => {
                  return (
                    <option value={JSON.stringify(e)}>{e.khorooName}</option>
                  );
                })}
              </Form.Select>
              <FormControl.Feedback type="invalid">
                Хороогоо оруулна уу
              </FormControl.Feedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <FormGroup>
              <FormLabel className="text-start">
                Утас 1 <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Form.Control
                type="tel"
                id="phone"
                pattern="[0-9]{8}"
                {...register("phone", { required: true })}
                isInvalid={errors.phone}
              />
              <FormControl.Feedback type="invalid">
                Утсаа оруулна уу
              </FormControl.Feedback>
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup>
              <FormLabel className="text-start">
                Утас 2 <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Form.Control
                type="tel"
                id="phone1"
                pattern="[0-9]{8}"
                {...register("phone1", { required: true })}
                isInvalid={errors.phone1}
              />
              <FormControl.Feedback type="invalid">
                Утсаа оруулна уу
              </FormControl.Feedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <FormGroup>
            <FormLabel className="text-start">
              Очих өдөр <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <DatePicker
              selected={date}
              {...register("startDate")}
              onChange={(date) => setDate(date)}
              minDate={startDate}
              filterDate={isWeekday}
              isInvalid={errors.startDate}
            />
            <FormControl.Feedback type="invalid">
              Очих өдрөө оруулна уу
            </FormControl.Feedback>
          </FormGroup>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Check
              type="checkBox"
              id="isEquipment"
              label="Антенн, төхөөрөмжид дагалдах хэрэгсэл авах эсэх"
              onChange={(e) => {
                setEquipmentEnabled(!e.target.checked);
              }}
              // {...register("isEquipment")}
            ></Form.Check>
          </Col>
          <Col md={6}>
            <Select
              name="equipment"
              isMulti
              options={equipments}
              onChange={setSelectedEquipments}
              isDisabled={equipmentEnabled}
              placeholder="Сонголтоо хийнэ үү"
            />
          </Col>
        </Row>
        <Row>
          <FormGroup>
            <FormLabel className="text-start">
              Хаягийн дэлгэрэнгүй <span style={{ color: "red" }}>*</span>
            </FormLabel>
            <Form.Control
              type="text"
              id="address"
              aria-describedby="addressHelp"
              {...register("addressDetail", { required: true })}
              isInvalid={errors.addressDetail}
            />
            <Form.Text id="addressHelp" muted className="text-start">
              Хаягийн дэлгэрэнгүй мэдээллийг оруулна уу.
            </Form.Text>
          </FormGroup>
          <FormGroup>
            <FormLabel className="text-start">Нэмэлт мэдээлэл</FormLabel>
            <Form.Control
              type="text"
              id="additionalInfo"
              {...register("note")}
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup>
            <Button
              disabled={isSubmitting}
              type="submit"
              style={{ marginBottom: "20px" }}
            >
              Захиалах
            </Button>
          </FormGroup>
        </Row>
      </Form>
    </Container>
  );
}

const mapActionsToProps = (dispatch) => {
  return {
    setMessage: (message) => dispatch(actions.setMessage(message)),
  };
};
export default connect(null, mapActionsToProps)(OrderForm);
