import axios from "axios";
import { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  ListGroup,
  ListGroupItem,
  Pagination,
  Container,
} from "react-bootstrap";
import { webApiUrl, webUrl } from "../../helper/globals";
import dateFormat from "dateformat";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
var h2p = require("html2plaintext");

export default function PromoList() {
  const [news, setNews] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const itemsPerPage = 9;
  const navigate = useNavigate();
  var items = (pageCount) => {
    let list = [];
    let count = Math.ceil(pageCount / itemsPerPage);

    for (let i = 1; i <= count; i++) {
      list.push(
        <Pagination.Item
          key={i}
          active={i === activePage}
          onClick={(s) => {
            setActivePage(parseInt(s.target.text));
          }}
        >
          {i}
        </Pagination.Item>
      );
    }
    return list;
  };
  useEffect(() => {
    axios
      .get(webApiUrl + `promo/${activePage}/${itemsPerPage}`)
      .then((result) => {
        const AllNews = result.data.result.promos;
        setTotalCount(result.data.result.total);
        setNews(AllNews);
      })
      .catch((err) => console.log(err));
  }, [activePage]);
  return (
    <Container className="section-content">
      <Row xs={1} md={3} className="g-4 ">
        {news?.map((e) => (
          <Col>
            <Card style={{ height: "380px", backgroundColor: "#F3F4F6" }}>
              <Card.Img
                variant="top"
                src={webUrl + e.picture}
                style={{ height: "160px" }}
                onClick={() => navigate("/promo/" + e.post_id)}
              />
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  justifyContent: "flex-end",
                  alignItems: "stretch",
                  alignContent: "center",
                }}
              >
                <Card.Title>
                  <Link
                    to={"/promo/" + e.post_id}
                    style={{ color: "#524f4f", fontSize: 18 }}
                  >
                    {e.post_title}
                  </Link>
                </Card.Title>

                <ListGroup className="list-group-flush">
                  <ListGroupItem
                    style={{ backgroundColor: "#F3F4F6", color: "#868181" }}
                  >
                    {h2p(e.post_content).substring(0, 50)}...
                  </ListGroupItem>
                  <ListGroupItem
                    style={{ backgroundColor: "#F3F4F6", color: "#868181" }}
                  >
                    {dateFormat(e.created_at, "yyyy-mm-dd")}
                  </ListGroupItem>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <Row>
        <Col className="d-flex flex-row-reverse">
          <Pagination>
            <Pagination>{items(totalCount)}</Pagination>
          </Pagination>
        </Col>
      </Row>
    </Container>
  );
}
