import { useLocation, useNavigate } from "react-router";
import admin from "../../assets/img/ico_admin.png";
import useWindowDimensions from "./GetWindowDimensions";
import { toast } from "react-toastify";
function OrderOnline() {
  const navigate = useNavigate();
  const location = useLocation();
  const param = location.pathname.slice(14);
  const { width } = useWindowDimensions();
  // console.log(width);
  return (
    <div className="online-order-container-main">
      <div style={{ height: "55px" }}></div>
      <div
        style={{
          backgroundColor: "#304bb6",
        }}
        className="online-order-body parent-z h-screen"
      >
        <ul className="row block main-service d-sticky relative child-z pt-5">
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <button
              style={{
                border: "none",
                background: "linear-gradient( 10deg, #aeb5fd 30%, #4060ff 70%)",
                marginTop: "100px",
              }}
              className="d-order-o-2 cursor-pointer mb-4 shadow "
              onClick={() => {
                navigate("/neworder");
              }}
            >
              <img
                src={admin}
                alt=""
                width="30px"
                height="30px"
                className="mb-1 mt-2 "
              />
              <div style={{ width: "100%" }} className="d-flex-cus">
                <span className="pt-1">
                  ТӨХӨӨРӨМЖИЙН ЗАХИАЛГА СУУРИЛУУЛАЛТ
                  <br /> /УБ/
                </span>
                {/* <span className="sub">байршил шалгах</span> */}
              </div>
            </button>
            <button
              style={{
                border: "none",
                background:
                  "linear-gradient(to bottom, #4060ff 10%, #aeb5fd 100%)",
              }}
              className=" d-order-o-2 cursor-pointer shadow"
              onClick={() => {
                navigate(`/online-order-form/${param}`);
              }}
            >
              <img
                src={admin}
                alt=""
                width="30px"
                height="30px"
                className="mb-1 mt-2"
              />
              <span className="pt-2">
                ТӨХӨӨРӨМЖИЙН ХҮРГЭЛТ
                <br />
                /Улаанбаатар, Орон нутаг/
              </span>
              {/* <span className="sub">хүргэлт</span> */}
            </button>
            <button
              style={{
                border: "none",
                background: "linear-gradient( 10deg, #aeb5fd 30%, #4060ff 70%)",
              }}
              className="d-order-o-2 cursor-pointer mt-4 shadow "
              onClick={() => {
                navigate("/online-order-check");
              }}
            >
              <img
                src={admin}
                alt=""
                width="30px"
                height="30px"
                className="mb-1 mt-2 "
              />
              <div style={{ width: "100%" }} className="d-flex-cus">
                <span className="pt-1">ЗАХИАЛГА ШАЛГАХ</span>
                {/* <span className="sub">байршил шалгах</span> */}
              </div>
            </button>
          </div>
        </ul>
      </div>
    </div>
  );
}

export default OrderOnline;
