import { Button, Col, Container, Row } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import * as merchantActions from "../../actions/merchant";

import { useParams, useNavigate } from "react-router-dom";
import {
  KHAN_BANK,
  ACCOUNT_PAYMENT,
  XAC_BANK,
  STATE_BANK,
  UPOINT_PAYMENT,
} from "../../actions/types";
import { useState } from "react";
import { getAnonymous } from "../../helper/requests";
function Payment(props) {
  function checkInvoice() {
    getAnonymous(
      "/qpay/26/" + orderId,
      (res) => {
        setSuccess(res.data.isSuccess);
      },
      (err) => { }
    );
  }
  const navigate = useNavigate();
  const banker = (bankName) =>
    bankName ? <h5>Банкны нэр: {bankName} </h5> : "";

  const order = useSelector((state) => state.order);

  const { orderId } = useParams();
  const [isAccountCharge] = useState(order?.method === ACCOUNT_PAYMENT);
  let bankText = "";
  const [isChecked, setIsChecked] = useState(false);
  const [success, setSuccess] = useState(null);
  const { bankName } = useParams();
  let orderText =
    orderId !== "complete" ? <h5>Гүйлгээний дугаар: {orderId} </h5> : null;
  if (
    (order?.method !== ACCOUNT_PAYMENT && !isChecked) ||
    (order?.method === UPOINT_PAYMENT && !isChecked) ||
    order === null
  ) {
    if (order == null) {
      props.loadOrder();
    } else {
      setIsChecked(true);
    }
    checkInvoice();

    switch (bankName) {
      case KHAN_BANK: {
        bankText = "Хаан Банк";
        break;
      }
      case XAC_BANK: {
        bankText = "Хас Банк";
        break;
      }
      case STATE_BANK: {
        bankText = "Төрийн Банк";
        break;
      }
      default:
        break;
    }
    setIsChecked(true);
  }
  let descriptionText =
    order?.order?.orderType?.text ?? order?.order?.product?.orderType?.text;
  let accountTypeText = isAccountCharge ? "Үндсэн дансаар" : "Банкны картаар";
  let successText = order?.isSuccess || success ? "Амжилттай" : "Амжилтгүй";
  return (
    <Container className="section-content">
      <Row>
        <Col>
          <h3 className="text-header">Гүйлгээний баримт:</h3>
          <h5 className="text-child">Гүйлгээний төрөл: {accountTypeText}</h5>
          <h5 className="text-child">
            Үйлчилгээ:{" "}
            {order?.order?.product?.productName ??
              order?.order?.product?.product?.productName}
          </h5 >
          <h5 className="text-child">Дэлгэрэнгүй: {descriptionText}</h5>
          {banker(bankText)}
          {orderText}
          <h5 className="text-child ">
            Нийт мөнгөн дүн: {order?.order?.price ?? order?.order?.order?.price}
            ₮
          </h5>
          <h5 className="text-child">Үр дүн: {successText}</h5>
          <Button
            onClick={() => {
              props.clearOrder();
              navigate("/");
            }}
          >
            Буцах
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

const mapActionToProps = (dispatch) => {
  return {
    loadOrder: () => dispatch(merchantActions.loadOrder()),
    clearOrder: () => dispatch(merchantActions.orderClear()),
  };
};
export default connect(null, mapActionToProps)(Payment);
