import { Container } from "react-bootstrap";
import OrderForm from "./OrderForm";

export default function AntennaOrder() {
  return (
    <>
      <Container
        className="section-container"
        style={{ marginBottom: 20 }}
      ></Container>
      <OrderForm callType="989" />
    </>
  );
}
