import {
  SEARCH_ACCOUNT,
  SEARCH_CLEAR,
  SEARCH_ERROR,
  SEARCH_SUCCESS,
} from "../actions/types";

const initialState = null;
const searchReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_ACCOUNT: {
      return {
        ...state,
        account: payload,
      };
    }
    case SEARCH_SUCCESS: {
      return {
        ...state,
        result: payload,
      };
    }
    case SEARCH_ERROR: {
      return {
        ...state,
        err: payload,
      };
    }

    case SEARCH_CLEAR: {
      return null;
    }
    default:
      return state;
  }
};

export default searchReducer;
