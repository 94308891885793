import BottomButtons from "./BottomButtons";
import Banner2 from "./Banner";
export default function Home() {
  return (
    <div>
      <Banner2 type="home" />
      <BottomButtons />
    </div>
  );
}
