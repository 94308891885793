import { Container, Row, Col } from "react-bootstrap";
import LoginForm from "../components/modals/Login";

function Login() {
  return (
    <Container className="section-content">
      <Row>
        <Col md={{ span: 4, offset: 1 }}>
          <p className="text-justify">
            Та өөрийн Админ дугаар болон Смарт картын дугаараар бүртгүүлж
            нэвтрэн орсноор доорх үйлчилгээнүүдийг авах боломжтой.
          </p>
          <ul className="check l26 text-left">
            <li>Багц сунгах</li>
            <li>Багц ахиулах</li>
            <li>ДДЭШ данс цэнэглэх</li>
            <li>Кино түрээслэн үзэх</li>
            <li>Үйлчилгээний тохиргоо хийх</li>
          </ul>
        </Col>
        <Col md={{ span: 4, offset: 1 }}>
          <LoginForm />
        </Col>
      </Row>
    </Container>
  );
}
export default Login;
