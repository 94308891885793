import { getAnonymous, getSecured } from "../helper/requests";
import { GET_LIVE, GET_LIVE_ERROR } from "./types";

export const getLive = () => {
  return function (dispatch, getState) {
    const isLoggedIn = getState().auth.isLoggedIn;
    if (isLoggedIn)
      getSecured(
        "vodlist/live",
        (res) => {
          dispatch({
            type: GET_LIVE,
            payload: { pic: res.data.liveLogo, liveList: res.data.liveList },
          });
        },
        (err) => {
          dispatch({ type: GET_LIVE_ERROR, payload: { err: err } });
        }
      );
    else {
      getAnonymous(
        "vodlist/live",
        (res) => {
          dispatch({
            type: GET_LIVE,
            payload: { pic: res.data.liveLogo, liveList: res.data.liveList },
          });
        },
        (err) => {
          dispatch({ type: GET_LIVE_ERROR, payload: { err: err } });
        }
      );
    }
  };
};
