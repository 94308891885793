import {
  SET_MESSAGE,
  CLEAR_MESSAGE,
  TOAST_NORMAL,
  TOAST_ERROR,
  TOAST_INFO,
  TOAST_SUCCESS,
  TOAST_WARNING,
} from "./types";
import { toast } from "react-toastify";
export const setMessage = (message) => {
  return function (dispatch) {
    dispatch({
      type: SET_MESSAGE,
      payload: message,
    });
    let settings = {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    switch (message.type) {
      case TOAST_NORMAL: {
        toast(message.text, settings);
        break;
      }
      case TOAST_ERROR: {
        toast.error(message.text, settings);
        break;
      }

      case TOAST_INFO: {
        toast.info(message.text, settings);
        break;
      }
      case TOAST_SUCCESS: {
        toast.success(message.text, settings);
        break;
      }
      case TOAST_WARNING: {
        toast.warn(message.text, settings);
        break;
      }
      default:
        return;
    }
  };
};
export const clearMessage = () => ({
  type: CLEAR_MESSAGE,
});
