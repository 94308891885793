import About from "../components/about";
import Home from "../components/Layout/Home";
import UserInfo from "../components/User/UserInfo";
import Breadcrumbs from "../components/Layout/Breadcrumb";
import Products from "../components/Packages/Product";
import Order from "../components/Payment/Order";
import Payment from "../components/Payment/Payment";
import Post from "../components/Posts/Post";
import PromoList from "../components/Posts/PromoList";
import Promo from "../components/Posts/Promo";
import PostList from "../components/Posts/PostList";
import Movie from "../components/Movie/Movie";
import MovieList from "../components/Movie/MovieList";
import HelpList from "../components/Help/HelpList";
import Help from "../components/Help/Help";
import Jobs from "../components/Jobs/Jobs";
import Live from "../components/Movie/Live";
import Login from "../components/Login";
import AccountCharge from "../components/Charge/AccountCharge";
import NoLoginCharge from "../components/Charge/NoLoginCharge";
import Devices from "../components/Devices";
import Device from "../components/Device";
import Branches from "../components/Branches/Branches";
import Channels from "../components/Channels/Channels";
import NewOrder from "../components/NewOrder";
import Contact from "../components/Contact";
import CV from "../components/Jobs/CV";
import VAT from "../components/User/VAT";
import Search from "../components/Search";
import QPay from "../components/QPay";
import GolomtRedirect from "../components/GolomtRedirect";
import ServiceAgreement from "../components/ServiceAgreement";
import AntennaOrder from "../components/AntennaOrder";
import Transaction from "../components/Transaction";
import BankCard from "../components/BankCard";
import OrderOnline from "../components/OrderOnline/OrderOnline";
import OnlineOrderForm from "../components/OrderOnline/OnlineOrderForm";
import OnlineOrderCheck from "../components/OrderOnline/OnlineOrderCheck";
import OnlinePayment from "../components/OrderOnline/OnlinePayment";
import OnlineOrderShowForm from "../components/OrderOnline/OnlineOrderShowForm";
import OnlineOrderDeal from "../components/OrderOnline/OnlineOrderDeal";
import OnlineOrderDevices from "../components/OrderOnline/OnlineOrderDevices";
import MovieHistoryMain from "../components/MovieHistory/MovieHistoryMain";
import MovieWritingSection from "../components/MovieHistory/Movie_WritingSection";
import Movie_FileUpload from "../components/MovieHistory/Movie_FileUpload";
import Content from "../components/Posts/Content";
import OnlineOrderFormLunar from "../components/OrderOnline/OnlineOrderFormLunar";
import NewOrderForm from "../components/NewOrderForm";
import EcontractApp from "../econtrant_app/Econtract_app";
export const Routing = {
  Public: [
    {
      path: "/",
      component: (
        <div className="page-content">
          <Home />
        </div>
      ),
    },
    {
      path: "/online-order-user-form",
      component: <OnlineOrderShowForm />,
    },
    // {
    //   path: "/movie-history-type",
    //   component: <MovieWritingSection />,
    // },
    // {
    //   path: "/movie-history-file-upload",
    //   component: <Movie_FileUpload />,
    // },
    // {
    //   path: "/movie-history",
    //   component: <MovieHistoryMain />,
    // },
    {
      path: "/online-order-contract",
      component: <OnlineOrderDeal />,
    },
    {
      path: "/econtract-app-download",
      component: (
        <Breadcrumbs
          title="Econtract app"
          content={<EcontractApp />}
          route={[]}
        />
      ),
    },
    {
      path: "/online-order/:id",
      component: <OrderOnline />,
    },
    {
      path: "/online-payment",
      component: <OnlinePayment />,
    },
    {
      path: "/online-order-form/pre/:id",
      component: <OnlineOrderForm />,
    },
    {
      path: "/online-order-form/:id",
      component: <OnlineOrderForm />,
    },
    {
      path: "/online-order-check",
      component: <OnlineOrderCheck />,
    },
    {
      path: "api.php/merchant/golomtResponse",
      component: <GolomtRedirect />,
    },
    {
      path: "transaction",
      component: (
        <Breadcrumbs
          title="ТӨЛБӨРИЙН БАРИМТ"
          content={<Transaction />}
          route={[]}
        />
      ),
    },
    {
      path: "order/bank",
      component: (
        <Breadcrumbs title="БАНКНЫ КАРТУУД" content={<BankCard />} route={[]} />
      ),
    },
    {
      path: "/about",
      component: <About />,
    },
    {
      path: "/payment/",
      component: (
        <Breadcrumbs
          title="ТӨЛБӨР ТӨЛӨХ"
          content={<NoLoginCharge />}
          route={[{ url: "/payment/", text: "Төлбөр төлөх" }]}
        />
      ),
    },
    {
      path: "/order",
      component: (
        <Breadcrumbs title="Таны захиалга" content={<Order />} route={[]} />
      ),
    },
    {
      path: "/cv/:id",
      component: (
        <Breadcrumbs
          title="Ажлын анкет"
          content={<CV />}
          route={[{ url: "/jobs", text: "Ажлын байр" }]}
        />
      ),
    },
    {
      path: "/press",
      component: (
        <Breadcrumbs
          title="МЭДЭЭЛЭЛ"
          content={<PostList />}
          route={[{ url: "/press", text: "МЭДЭЭЛЭЛ" }]}
        />
      ),
    },
    {
      path: "/press/:id",
      component: <Post />,
    },
    {
      path: "/content/:id",
      component: <Content />,
    },
    {
      path: "/promo",
      component: (
        <Breadcrumbs
          title="УРАМШУУЛАЛ"
          content={<PromoList />}
          route={[{ url: "/promo", text: "Урамшуулал" }]}
        />
      ),
    },
    {
      path: "/promo/:id",
      component: <Promo />,
    },
    {
      path: "/helpdesk",
      component: (
        <Breadcrumbs
          title="ТУСЛАМЖ"
          content={<HelpList language="mn" />}
          route={[{ url: "/helpdesk", text: "Тусламж" }]}
        />
      ),
    },
    {
      path: "/helpdesk/:id",
      component: <Help />,
    },
    {
      path: "/jobs",
      component: <Jobs />,
    },
    {
      path: "/channel/:category",
      component: <Channels />,
    },
    {
      path: "/store",
      component: <Devices />,
    },
    {
      path: "/store/:id",
      component: (
        <Breadcrumbs
          title="Төхөөрөмж"
          content={<Device />}
          route={[{ url: "/store", text: "Төхөөрөмж" }]}
        />
      ),
    },
    {
      path: "/packages",
      component: (
        <div className="page-content">
          <Products />,
        </div>
      ),
    },
    {
      path: "/branch",
      component: (
        <Breadcrumbs
          title="САЛБАРЫН БАЙРШИЛ"
          content={<Branches />}
          route={[{ url: "/branch", text: "Салбарын байршил" }]}
        />
      ),
    },
    {
      path: "/newOrder",
      component: (
        <Breadcrumbs
          title="ШИНЭ ХЭРЭГЛЭГЧ"
          content={<NewOrder />}
          route={[{ url: "/newOrder", text: "Шинэ хэрэглэгч" }]}
        />
      ),
    },
    {
      path: "/newOrderForm",
      component: (
        <Breadcrumbs
          title="ШИНЭ ХЭРЭГЛЭГЧ"
          content={<NewOrderForm />}
          route={[{ url: "/newOrderForm", text: "Шинэ хэрэглэгч" }]}
        />
      ),
    },
    {
      path: "/antennaOrder",
      component: (
        <Breadcrumbs
          title="АНТЕН ТОХИРГООНЫ ЗАХИАЛГА"
          content={<AntennaOrder />}
          route={[{ url: "/antennaOrder", text: "Антен тохиргооны захиалга" }]}
        />
      ),
    },
    {
      path: "/live",
      component: <Live />,
    },
    {
      path: "/movie",
      component: <MovieList />,
    },
    {
      path: "/movie/:id/:productId/:inDate/:smsCode/:time",
      component: <Movie />,
    },
    {
      path: "/contact",
      component: (
        <Breadcrumbs
          title="Холбоо барих"
          content={<Contact />}
          route={[{ url: "/contact", text: "Холбоо барих" }]}
        />
      ),
    },
    {
      path: "/search",
      component: (
        <Breadcrumbs
          title="Хайлтын үр дүн"
          content={<Search />}
          route={[{ url: "/search", text: "Хайлтын үр дүн" }]}
        />
      ),
    },
    {
      path: "/service/qpay/:orderId",
      component: (
        <Breadcrumbs title="ТӨЛБӨРИЙН БАРИМТ" content={<QPay />} route={[]} />
      ),
    },
    {
      path: "/service/payment/:orderId",
      component: (
        <Breadcrumbs
          title="ТӨЛБӨРИЙН БАРИМТ"
          content={<Payment />}
          route={[]}
        />
      ),
    },
    {
      path: "/agreement",
      component: <ServiceAgreement />,
    },
  ],
  Private: [
    {
      path: "/service/user",
      component: (
        <Breadcrumbs
          title="ХЭРЭГЛЭГЧИЙН МЭДЭЭЛЭЛ"
          content={<UserInfo />}
          route={[{ url: "/service/user", text: "Хэрэглэгчийн мэдээлэл" }]}
        />
      ),
    },
    {
      path: "/service/vat",
      component: (
        <Breadcrumbs
          title="НӨАТ-ын баримтын түүх"
          content={<VAT />}
          route={[{ url: "/service/user", text: "Хэрэглэгчийн мэдээлэл" }]}
        />
      ),
    },

    {
      path: "/service/payment/",
      component: (
        <Breadcrumbs
          title="ТӨЛБӨР ТӨЛӨХ"
          content={<AccountCharge />}
          route={[{ url: "/service/payment", text: "Төлбөр төлөх" }]}
        />
      ),
    },
  ],
  Special: [
    {
      path: "/login",
      component: (
        <Breadcrumbs
          title="НЭВТРЭХ"
          content={<Login />}
          route={[{ url: "/login", text: "Нэвтрэх" }]}
        />
      ),
    },
  ],

  English: [],
};
