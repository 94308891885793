import { useNavigate, useLocation } from "react-router";
import QRCode from "react-qr-code";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useEffect, useState } from "react";
function OnlineOrderDeal() {
  const navigate = useNavigate();
  const location = useLocation();
  const handleBackButton = () => {
    navigate("/");
  };
  if (location.pathname === "/online-order-contract") {
    window.addEventListener("popstate", handleBackButton);
  }
  const [modalShow, setModalShow] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setModalShow(true);
      localStorage.removeItem("order");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  // console.log(location.state);
  return (
    <div className="online-order-container">
      <div style={{ height: "56px" }}></div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        shipTime={location.state.shipTime}
      />
      <div
        style={{ background: "white" }}
        className="container padding-100 center2 pt-3"
      >
        <div className="e-container">
          <img src="ebarimt.png" alt="" className="img" />
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "80%", width: "80%" }}
            value={location?.state?.mtaResult?.qrData}
            viewBox={`0 0 256 256`}
            level="L"
          />
          <div className="e-container-2 mb-0">
            <p className="mb-0 mt-2">
              ТТД: {location.state.mtaResult.merchantId}
            </p>
            <p className="mb-0">ДДТД: {location.state.mtaResult.billId}</p>
            <p className="mb-0">
              Сугалааны дугаар: {location.state.mtaResult.lotterryNo}
            </p>
            <p className="mb-0">
              Үнийн дүн : {location.state.mtaResult.amount}
            </p>
            <p style={{ textAlign: "start" }}>
              Огноо: {new Date().toLocaleString()}
            </p>
          </div>
        </div>
        <button
          onClick={() => {
            navigate("/online-order-check");
          }}
          className="bubbly-button6 mb-5 "
        >
          Захиалга шалгах
        </button>
      </div>
    </div>
  );
}

export default OnlineOrderDeal;

function MyVerticallyCenteredModal(props) {
  return (
    <>
      <Modal {...props} centered>
        {/* <Modal.Header closeButton>
          <Modal.Title>Санамж</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          {/* <h4
            style={{
              textAlign: "center",
              marginTop: "5px",
            }}
            className=""
          >
            Таны захиалга {props.shipTime}ийн дотор хүргэгдэнэ.
          </h4> */}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              fill="green"
              className="bi bi-check-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
          </div>
          <h6
            style={{
              // color: "silver",
              marginTop: "8px",
              fontSize: "14px",
              textAlign: "center",
              padding: "0 10px",
              fontWeight: "500",
            }}
            className=""
          >
            <i>
              <strong>-Таны захиалга амжилттай баталгаажлаа.</strong>
              <br />
              Та захиалга шалгах цэсээр нэвтэрч захиалгатай холбоотой мэдээлэлээ
              харна уу. Таны захиалга {props.shipTime}ийн дотор хүргэгдэнэ.
            </i>
          </h6>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              style={{
                fontSize: "13px",
              }}
              variant="primary"
              onClick={props.onHide}
            >
              <i>Хаах</i>
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
