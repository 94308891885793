import { Button, Col, Container, Row, Modal } from "react-bootstrap";
import { webUrl } from "../../helper/globals";
import { useState } from "react";
import { useNavigate } from "react-router";

export default function PaidChannels() {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  function handleClose() {
    setOpenModal(false);
  }
  return (
    <Container fluid>
      <Row>
        <Row style={{ textAlign: "justify" }}>
          <Col
            style={{
              display: " flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            xs={12}
            md={6}
          >
            <img
              src={webUrl + "/u/channel/72X52%20NEW.jpg"}
              alt="new movie"
            />
          </Col>
          <Col xs={12} md={6}>
            <Row>
              <p>
                ШИНЭ КИНО сувгаас Монгол болон дэлхийн шинэ, шилдэг кинонуудыг албан ёсны эрхтэй, дуу, дүрсний өндөр чанартайгаар цагийн хуваарийн дагуу кино тус бүрээр нь захиалан үзэх боломжтой. 
              </p>
              <p>
                Суваг: 800
                <br />
                Үнэ: Контент тус бүрийн үнээр 
              </p>
              <p>
                Кино, контент захиалахдаа: ДДэш-ийн смарт картаа цэнэглээд админ болон гишүүн дугаараасаа <b>Сувгийн дугаар</b> зай аваад <b>Киноны код</b>оо бичиж 139898 дугаарт илгээгээрэй. /Жишээ нь: 800 12/
              </p>
              <p>
                Жич: Киногоо тухайн өдөр нь захиалах бөгөөд захиалсан киноны дараагийн давталт үнэгүй. 
              </p>
            </Row>
            <Row>
              <Button
                onClick={() => {
                  navigate("/movie");
                }}
                style={{ marginBottom: "10px" }}
              >
                Захиалах
              </Button>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row style={{ textAlign: "justify" }}>
          <Col
            xs={12}
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              <img
                src="https://my.ddishtv.mn:5001/nvod/LIVE.png"
                alt="ddish live"
                width="100%"
              />
              <p>
                Хошин урлаг, нийтийн дуу, рок попын тоглолт, соёл урлаг, спортын сонирхолтой, үзүүштэй арга хэмжээнүүдийг цаг алдалгүй Монгол орны хаанаас ч шууд хүлээн авч үзээрэй.
              </p>
              <p>
                Суваг: 900
                <br />
                Үнэ: Контент тус бүрийн үнээр
              </p>
              <p>
                Кино, контент захиалахдаа: ДДэш-ийн смарт картаа цэнэглээд админ болон гишүүн дугаараасаа <b>Сувгийн дугаар</b> зай аваад <b>Киноны код</b>оо бичиж 139898 дугаарт илгээгээрэй. /Жишээ нь: 900 12/
              </p>
              </div>
              <Button
                onClick={() => {
                  navigate("/live");
                }}
                className="w-100"
                style={{ bottom: "0%", marginBottom: "10px" }}
              >
                Захиалах
              </Button>
          
          </Col>
          <Col
            style={{
              display: " flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            xs={12}
            md={6}
          >
            <img
              src={webUrl + "/u/logo/Banner%20live.jpg"}
              alt="ddish live"
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
      </Row>
      <Modal show={openModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Нэвтрэнэ үү</Modal.Title>
        </Modal.Header>
        <Modal.Body>Та нэвтэрч орсноор үйлчилгээг авах боломжтой.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Болих
          </Button>
          <Button variant="primary" onClick={() => navigate("/login")}>
            Нэвтрэх
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
