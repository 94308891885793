import {
  GET_ACCOUNT,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAIL,
  GET_INFO,
  GET_INFO_FAIL,
  GET_INFO_SUCCESS,
  POSTPAID,
  PREPAID,
} from "./types";
import store from "../store";
import { getSecured } from "../helper/requests";

var _ = require("lodash");
export const getInfo = () => {
  const state = store.getState();
  return function (dispatch) {
    dispatch({ type: GET_INFO, payload: state.auth.user.access_token });
    getSecured(
      "getUserInfo",
      (res) => {
        let ordered = _.sortBy(res.data.activeProducts, ["orderingNo"]);
        let userInfo = {
          ...res.data,
          productId:
            res.data.activeProducts.length === 0
              ? null
              : ordered[ordered.length - 1].productId,
        };
        dispatch({
          type: GET_INFO_SUCCESS,
          payload: { ...userInfo },
        });
        localStorage.setItem("user", JSON.stringify(userInfo));
      },
      (err) => {
        dispatch({ type: GET_INFO_FAIL, payload: JSON.stringify(err) });
      }
    );
  };
};

export const getCounter = () => {
  const state = store.getState();
  return function (dispatch) {
    dispatch({ type: GET_ACCOUNT, payload: state.auth.user.access_token });
    getSecured(
      "getUserInfo/0",
      (res) => {
        dispatch({
          type: GET_ACCOUNT_SUCCESS,
          payload: {
            counter: res.data.mainCounter,
          },
        });
      },
      (err) => {
        dispatch({ type: GET_ACCOUNT_FAIL, payload: { err: err.Error } });
      }
    );
  };
};
export const checkType = () => {
  return function (dispatch) {
    getSecured(
      "getuserinfo/checktype",
      (res) => {
        dispatch({ type: PREPAID });
      },
      (err) => {
        dispatch({ type: POSTPAID });
      }
    );
  };
};
