import axios from "axios";
import { useEffect, useState } from "react";
import {
  Col,
  Container,
  Form,
  Row,
  Tab,
  ListGroup,
  Pagination,
  PageItem,
} from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { webApiUrl, webUrl } from "../helper/globals";
var h2p = require("html2plaintext");
export default function Search() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get("query"));
  const [q, setQ] = useState(null);
  const [alll, setAlll] = useState([]);
  const [allPaged, setAllPaged] = useState([]);
  const [products, setProducts] = useState([]);
  const [productsPaged, setProductsPaged] = useState([]);
  const [helps, setHelps] = useState([]);
  const [helpsPaged, setHelpsPaged] = useState([]);
  const [posts, setPosts] = useState([]);
  const [postsPaged, setPostsPaged] = useState([]);

  const [allSelectedPage, setAllSelectedPage] = useState(1);
  const [productSelectedPage, setProductSelectedPage] = useState(1);
  const [helpSelectedPage, setHelpSelectedPage] = useState(1);
  const [postSelectedPage, setPostSelectedPage] = useState(1);
  const itemsPerPage = 5;

  const navigate = useNavigate();
  useEffect(() => {
    let body = [
      {
        type: "product",
        title: query,
      },
      {
        type: "help",
        title: query,
      },
      {
        type: "post",
        title: query,
      },
    ];
    axios
      .all(body.map((bo) => axios.post(webApiUrl + "search", bo)))
      .then((res) => {
        let all = [];
        let prod = [];
        let help = [];
        let post = [];
        res[0].data.result.forEach((e) => {
          prod.push({
            title: e.product_title,
            text: h2p(e.product_content).substring(0, 100) + "...",
            link: "/packages",
            pic: e.picture,
          });
        });
        res[1].data.result.forEach((e) => {
          help.push({
            title: e.helpdesk_title,
            text: h2p(e.helpdesk_content).substring(0, 100) + "...",
            link: "/helpdesk/" + e.help_id,
            pic: null,
          });
        });

        res[2].data.result.forEach((e) => {
          post.push({
            title: e.post_title,
            text: h2p(e.post_content).substring(0, 100) + "...",
            link: "/" + e.type + "/" + e.post_id,
            pic: e.picture,
          });
        });
        all.push(...post);
        all.push(...prod);
        all.push(...help);
        setHelps(help);

        setPosts(post);
        setProducts(prod);
        setAlll(all);
        setAllPaged(all.slice(0, 5));
        setPostsPaged(post.slice(0, 5));
        setProductsPaged(prod.slice(0, 5));
        setHelpsPaged(help.slice(0, 5));
      });
  }, [query]);

  useEffect(() => {
    setAllPaged(
      alll.slice(
        (allSelectedPage - 1) * itemsPerPage,
        (allSelectedPage - 1) * itemsPerPage + itemsPerPage
      )
    );
  }, [allSelectedPage]);

  useEffect(() => {
    setProductsPaged(
      products.slice(
        (productSelectedPage - 1) * itemsPerPage,
        (productSelectedPage - 1) * itemsPerPage + itemsPerPage
      )
    );
  }, [productSelectedPage]);
  useEffect(() => {
    setHelpsPaged(
      helps.slice(
        (helpSelectedPage - 1) * itemsPerPage,
        (helpSelectedPage - 1) * itemsPerPage + itemsPerPage
      )
    );
  }, [helpSelectedPage]);
  useEffect(() => {
    setPostsPaged(
      posts.slice(
        (postSelectedPage - 1) * itemsPerPage,
        (postSelectedPage - 1) * itemsPerPage + itemsPerPage
      )
    );
  }, [postSelectedPage]);

  var allPagination = () => {
    let list = [];
    let count = Math.ceil(alll.length / itemsPerPage);

    for (let i = 1; i <= count; i++) {
      list.push(
        <PageItem
          key={i}
          active={i === allSelectedPage}
          onClick={(s) => {
            setAllSelectedPage(parseInt(s.target.text));
          }}
        >
          {i}
        </PageItem>
      );
    }
    return list;
  };
  var productPagination = () => {
    let list = [];
    let count = Math.ceil(products.length / itemsPerPage);

    for (let i = 1; i <= count; i++) {
      list.push(
        <PageItem
          key={i}
          active={i === productSelectedPage}
          onClick={(s) => {
            setProductSelectedPage(parseInt(s.target.text));
          }}
        >
          {i}
        </PageItem>
      );
    }
    return list;
  };
  var helpPagination = () => {
    let list = [];
    let count = Math.ceil(helps.length / itemsPerPage);

    for (let i = 1; i <= count; i++) {
      list.push(
        <PageItem
          key={i}
          active={i === helpSelectedPage}
          onClick={(s) => {
            setHelpSelectedPage(parseInt(s.target.text));
          }}
        >
          {i}
        </PageItem>
      );
    }
    return list;
  };
  var postPagination = () => {
    let list = [];
    let count = Math.ceil(posts.length / itemsPerPage);

    for (let i = 1; i <= count; i++) {
      list.push(
        <PageItem
          key={i}
          active={i === postSelectedPage}
          onClick={(s) => {
            setPostSelectedPage(parseInt(s.target.text));
          }}
        >
          {i}
        </PageItem>
      );
    }
    return list;
  };
  function productList() {
    return (
      <Row>
        {productsPaged?.map((e) => {
          return (
            <div
              onClick={() => {
                navigate(e.link);
              }}
            >
              <div className="title" style={{ color: "#7a7a7a" }}>
                {e.title}
              </div>
              <Row style={{ color: "#B0B0B0" }}>{e.text}</Row>
            </div>
          );
        })}
      </Row>
    );
  }
  function helpList() {
    return (
      <Row>
        {helpsPaged?.map((e) => {
          return (
            <div
              onClick={() => {
                navigate(e.link);
              }}
            >
              <div className="title" style={{ color: "#7a7a7a" }}>
                {e.title}
              </div>
              <Row style={{ color: "#B0B0B0" }}>{e.text}</Row>
            </div>
          );
        })}
      </Row>
    );
  }
  function postList() {
    return (
      <Row>
        {postsPaged?.map((e) => {
          return (
            <Row
              onClick={() => {
                navigate(e.link);
              }}
              style={{ marginBottom: "5px" }}
            >
              <Col md={2}>
                <img
                  src={webUrl + e.pic}
                  alt=""
                  style={{ width: "200px" }}
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = webUrl + "img/default.png";
                  }}
                />
              </Col>
              <Col md={8}>
                <div className="title" style={{ color: "#7a7a7a" }}>
                  {e.title}
                </div>

                <Row style={{ color: "#B0B0B0" }}>{e.text}</Row>
              </Col>
            </Row>
          );
        })}
      </Row>
    );
  }
  function allList() {
    return (
      <Row>
        {allPaged?.map((e) => {
          return (
            <Row
              onClick={() => {
                navigate(e.link);
              }}
              style={{ marginBottom: "5px" }}
            >
              <Col md={2}>
                <img
                  src={webUrl + e.pic}
                  alt=""
                  style={{ width: "200px" }}
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = webUrl + "img/default.png";
                  }}
                />
              </Col>
              <Col md={8}>
                <div className="title" style={{ color: "#7a7a7a" }}>
                  {e.title}
                </div>

                <Row style={{ color: "#B0B0B0" }}>{e.text}</Row>
              </Col>
            </Row>
          );
        })}
      </Row>
    );
  }

  return (
    <Container className="section-content">
      <Row className="mar-btm-50">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            let qq = document.getElementById("query").value;
            setQuery(qq);
            setSearchParams({ query });
          }}
        >
          <Row>
            <Col md={5}>
              <div class="input-group">
                <input
                  class="input-group-field ng-pristine ng-valid ng-not-empty ng-touched"
                  type="text"
                  id="query"
                  value={q}
                  placeholder="Хайх утгаа оруулна уу."
                  onChange={(e) => {
                    setQ(e.target.value);
                  }}
                />
                <span
                  class="input-group-label"
                  style={{ padding: "0", width: "50px", height: "46px" }}
                >
                  <button
                    style={{ width: "100%", height: "100%" }}
                    type="submit"
                  >
                    <i class="search"></i>
                  </button>
                </span>
              </div>
            </Col>
          </Row>
        </Form>
      </Row>
      <Row>
        <Row>
          <Tab.Container defaultActiveKey="#all">
            {/* <Row> */}
            <Col md={10}>
              <ListGroup className="list-group-horizontal-md" centered>
                <ListGroup.Item action href="#all" className="tabs-title help">
                  Бүгд
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  href="#product"
                  className="tabs-title help"
                >
                  Бүтээгдэхүүн үйлчилгээ
                </ListGroup.Item>
                <ListGroup.Item action href="#help" className="tabs-title help">
                  Тусламж
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  href="#posts"
                  className="tabs-title help"
                >
                  Урамшуулал,мэдээ
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Row>Хайлтын үр дүн</Row>
            <Tab.Content>
              <Tab.Pane eventKey="#all">
                {allList()}
                <Pagination size="sm">{allPagination()}</Pagination>
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey="#product">
                {productList()}
                <Pagination size="sm">{productPagination()}</Pagination>
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey="#help">
                {helpList()}
                <Pagination size="sm">{helpPagination()}</Pagination>
              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey="#posts">
                {postList()}
                <Pagination size="sm">{postPagination()}</Pagination>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Row>
      </Row>
    </Container>
  );
}
