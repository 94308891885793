import axios from "axios";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardImg,
  Col,
  Container,
  ListGroup,
  Row,
} from "react-bootstrap";
import Slider from "react-slick";
import { webApiUrl, webUrl } from "../../helper/globals";
import PleaseLogin from "../modals/PleaseLogin";
import { productsInner } from "./raw";
export default function Packages() {
  const [packages, setPackages] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    axios.get(webApiUrl + `products/`).then((res) => {
      if (!isLoaded) {
        setPackages(res.data.result);
        setIsLoaded(true);
      }
    });
  }, [packages, isLoaded]);

  function handleClose() {
    setOpenModal(false);
  }
  function packageView() {
    return productsInner.map((e, ind) => {
      var splitText = e.selectable_desc?.split(";");
      const ids = [107, 108, 109];
      return (
        <Col>
          {ids.includes(e.product_id) ? (
            <Card
              style={{
                height: "842px",
                marginLeft: "5px",
                borderStyle: "none",
                boxShadow: "0 0 5px silver",
                padding: "10px 5px 15px 15px",
                fontFamily: "Roboto",
              }}
              className="text-center text-hover slab"
            >
              <CardImg
                // src={webUrl + e.picture}
                src={e.picture}
                style={{
                  width: "250px",
                  height: e.product_id === 107 || 108 || 109 ? "78px" : "120px",
                  marginTop: "10px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "10px",
                }}
              />
              <div className="ch-container-x">
                <div style={{ padding: "0 20px" }}>
                  <div
                    style={{
                      border:
                        e.product_id === 107
                          ? "2px solid #64bf75"
                          : e.product_id === 108
                          ? "2px solid #00b8c6"
                          : "2px solid #7e81bc",
                      borderRadius: "10px",
                      color: "#9099a5",
                      width: "200px",
                    }}
                    className=""
                  ></div>
                </div>
              </div>

              <div style={{ padding: "0 20px" }}>
                <div
                  style={{
                    fontSize: "12px",
                    lineHeight: "15px",
                    fontFamily: "Roboto",
                    height: "60px",
                  }}
                  className="header-text-card"
                >
                  {e.alias}
                </div>
                <div
                  style={{
                    marginTop: "20px",
                  }}
                  className=""
                >
                  <div
                    style={{
                      color: "#636f80",
                      fontWeight: "500",
                      fontFamily: "Roboto ",
                      fontSize: "12px",
                      marginBottom: "-6px",
                      marginTop: "10px",
                    }}
                  >
                    30хоног
                  </div>
                  {e.product_id === 107 && (
                    <div
                      style={{
                        color: "#64bf75",
                        fontWeight: "500",
                        fontFamily: "Roboto",
                        fontSize: "22px",
                        margin: 0,
                      }}
                    >
                      {e.price}₮
                    </div>
                  )}
                  {e.product_id === 108 && (
                    <div
                      style={{
                        color: "#00b8c6",
                        fontWeight: "700",
                        fontFamily: "Roboto",
                        fontSize: "22px",
                        margin: 0,
                      }}
                    >
                      {e.price}₮
                    </div>
                  )}
                  {e.product_id === 109 && (
                    <div
                      style={{
                        color: "#7e81bc",
                        fontWeight: "700",
                        fontFamily: "Roboto",
                        fontSize: "22px",
                        margin: 0,
                      }}
                    >
                      {e.price}₮
                    </div>
                  )}
                  <div
                    style={{
                      color: "#636f80",
                      fontWeight: "500",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      marginBottom: "-8px",
                    }}
                  >
                    {e.packages}
                  </div>
                  {e.product_id === 107 && (
                    <div
                      style={{
                        color: "#64bf75",
                        fontWeight: "700",
                        fontFamily: "Roboto",
                        fontSize: "22px",
                      }}
                    >
                      {e.selectable}
                    </div>
                  )}
                  {e.product_id === 108 && (
                    <div
                      style={{
                        color: "#00b8c6",
                        fontWeight: "700",
                        fontFamily: "Roboto",
                        fontSize: "22px",
                      }}
                    >
                      {e.selectable}
                    </div>
                  )}
                  {e.product_id === 109 && (
                    <div
                      style={{
                        color: "#7e81bc",
                        fontWeight: "700",
                        fontFamily: "Roboto",
                        fontSize: "22px",
                      }}
                    >
                      {e.selectable}
                    </div>
                  )}
                </div>
                <p className="p-0 m-0 p-container">
                  {splitText?.map((s, indexCus) => {
                    const returnColor = (id) => {
                      if (id === 0) {
                        return "#64bf75";
                      }
                      if (id === 2) {
                        return "#64bf75";
                      }
                      if (id === 4) {
                        return "#00b8c6";
                      }
                      if (id > 4) {
                        return "#7e81bc";
                      }
                    };
                    return (
                      <div
                        style={{
                          color:
                            indexCus % 2 == 0
                              ? returnColor(indexCus)
                              : "#636f80",
                          fontWeight: indexCus % 2 == 0 ? "500" : "400",
                          fontFamily: "Roboto",
                          fontSize: "12px",
                          margin: "0",
                          padding: "0",
                          lineHeight: "15px",
                          paddingTop: indexCus % 2 == 0 && "10px",
                        }}
                      >
                        {s}
                      </div>
                    );
                  })}
                </p>
              </div>
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  justifyContent: "flex-end",
                  alignItems: "stretch",
                  alignContent: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor:
                      e.product_id === 107
                        ? "#64bf75"
                        : e.product_id === 108
                        ? "#00b8c6"
                        : "#7e81bc",
                  }}
                  className="certain-ch"
                >
                  {e.product_id === 107
                    ? "Онцлох гэр бүлийн сувгууд"
                    : e.product_id === 108
                    ? "Онцлох кино сувгууд"
                    : "Онцлох спортын контентууд"}
                </div>
                <CardImg
                  // src={webUrl + e.picture}
                  src={e.certain_img}
                  style={{
                    width: "250px",
                    height:
                      e.product_id === 107 || 108 || 109 ? "78px" : "120px",
                    marginTop: "10px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: "5px",
                  }}
                />
                <ListGroup variant="flush"></ListGroup>
                {e.is_app === 1 ? (
                  <Button
                    variant="outline-primary"
                    onClick={() => setOpenModal(true)}
                  >
                    Сонгох
                  </Button>
                ) : (
                  <div style={{ marginBottom: "38px" }} />
                )}
              </Card.Body>
            </Card>
          ) : (
            <Card
              style={{
                height: "840px",
                marginLeft: "5px",
                borderStyle: "none",
                boxShadow: "0 0 5px silver",
                padding: "10px 5px 15px 15px",
              }}
              className="text-center text-hover"
            >
              <CardImg
                // src={webUrl + e.picture}
                src={e.picture}
                style={{
                  width: "150px",
                  height: "83px",
                  marginTop: "10px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "5px",
                }}
              />
              <div style={{ padding: "0 20px" }}>
                <div
                  style={{
                    border: "2px solid #636f80",
                    borderRadius: "10px",
                  }}
                  className=""
                ></div>
              </div>

              <div style={{ padding: "0 20px" }}>
                <div
                  style={{
                    color: "#636f80",
                    fontWeight: "500",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    marginBottom: "-6px",
                    marginTop: "10px",
                  }}
                >
                  30хоног
                </div>
                <div className="">
                  <div
                    style={{
                      color: "#64bf75",
                      fontWeight: "700",
                      fontFamily: "Roboto",
                      fontSize: "22px",
                      margin: 0,
                    }}
                  >
                    {e.price}₮
                  </div>
                </div>
                <div className="p-0 m-0 p-container">
                  {splitText?.map((s, indexCus) => {
                    const returnColor = (id) => {
                      if (id === 0) {
                        return "#64bf75";
                      }
                      if (id === 2) {
                        return "#64bf75";
                      }
                      if (id > 2) {
                        return "#64bf75";
                      }
                    };
                    return (
                      <div
                        style={{
                          color:
                            indexCus % 2 == 0
                              ? returnColor(indexCus)
                              : "#636f80",
                          fontWeight: indexCus % 2 == 0 ? "500" : "400",
                          fontFamily: "Roboto",
                          fontSize: "12px",
                          margin: "0",
                          padding: "0",
                          lineHeight: "15px",
                          paddingTop: indexCus % 2 == 0 && "10px",
                        }}
                      >
                        {s}
                      </div>
                    );
                  })}
                </div>
              </div>
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  justifyContent: "flex-end",
                  alignItems: "stretch",
                  alignContent: "center",
                }}
              >
                <ListGroup variant="flush"></ListGroup>
                {e.is_app === 1 ? (
                  <Button variant="primary" onClick={() => setOpenModal(true)}>
                    Сонгох
                  </Button>
                ) : (
                  <div style={{ marginBottom: "38px" }} />
                )}
              </Card.Body>
            </Card>
          )}
        </Col>
      );
    });
  }
  let settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Container fluid className="section section-content mar-top-40">
      <Container>
        <Row>
          <div className="package">
            <Slider {...settings}>{packageView()}</Slider>
          </div>
        </Row>
      </Container>
      <PleaseLogin isOpen={openModal} onHide={handleClose} />
    </Container>
  );
}
