function Deal() {
  return (
    <div className="deal margin-cus2">
      <br clear="all" />
      <h6 className="">НЭГ. ЕРӨНХИЙ ЗҮЙЛ</h6>
      <p align="start" className="m-0 line-height">
        1.1. Нэг талаас хиймэл дагуулын телевизийн үйлчилгээ үзүүлэгч “ДДэш ТВ”
        ХХК /цаашид “ДДэш” гэх/, Нөгөө талаас энэхүү гэрээний нөхцөлтэй бүрэн
        танилцаж хүлээн зөвшөөрсөн иргэн /цаашид “Хэрэглэгч” гэх, дангаар нь
        “Тал” хамтад нь “Талууд” гэх/ нар дор дурдсан нөхцөлийг харилцан
        тохиролцож байгуулав.
      </p>
      <p align="start" className="m-0 line-height">
        1.2. ДДэш нь телевизийн олон сувгийн нэвтрүүлгийг /цаашид “Үйлчилгээ”
        гэх/ хэрэглэгчид үзүүлэх, хэрэглэгч нь гэрээнд заасны дагуу төлбөр төлөх
        хэлцлийн нөхцөл, түүнтэй холбогдон үүсэх бусад харилцааг энэхүү
        гэрээгээр зохицуулна.
      </p>
      <p align="start" className="m-0 line-height">
        1.3. Энэхүү гэрээнд заасан нэр томьёог дор дурдсан утгаар ойлгоно.
      </p>
      <p align="start" className="m-0 line-height">
        1.3.1 Хэрэглэгч - ДДэштэй үйлчилгээний гэрээ байгуулж, үйлчилгээний
        төлбөрийг хугацаанд нь төлөх үүрэг бүхий аж ахуйн нэгж болон 18 насанд
        хүрсэн Монгол Улсын иргэн, гадаад улсын иргэн болон харьяалалгүй хүнийг;
      </p>
      <p align="start" className="m-0 line-height">
        1.3.2 Үйлчилгээг хаах - Үйлчилгээнийхээ хугацааг сунгаагүй хэрэглэгчийн
        үйлчилгээг ДДэш-ийн зүгээс түр зогсоохыг;
      </p>
      <p align="start" className="m-0 line-height">
        1.3.3 Дэлгэрэнгүй хуулга - Хэрэглэгчийн ашигласан үйлчилгээний
        дэлгэрэнгүй мэдээллийг тусгасан баримтыг;
      </p>
      <p align="start" className="m-0 line-height">
        1.3.4 Нууц дугаар - Хэрэглэгч нь ДДэш-ийн үйлчилгээний данснаасаа төлбөр
        төлөх тохиолдолд ашиглах, хэрэглэгчийн өөрийн сонгосон 4 оронтой тоог;
      </p>
      <p align="start" className="m-0 line-height">
        1.3.5 Сарын суурь хураамж - Хэрэглэгчийн сонгосон үйлчилгээний төрлөөс
        хамаарч сар /1 сар нь 30 хоногоор тооцох/ бүр урьдчилан төлөх төлбөрийг;
      </p>
      <p align="start" className="m-0 line-height">
        1.3.6 Нэмэлт үйлчилгээ - Түрээсийн кино контентын үйлчилгээ болон
        төлбөрт сувгийг;
      </p>
      <p align="start" className="m-0 line-height">
        1.3.7 Үндсэн сувгийн багц – Өргөн нэвтрүүлгийн тухай хуулийн 4.1.19-д
        заасны дагуу хамгийн бага үнэтэй багцыг;
      </p>
      <p align="left" className="m-0 line-height">
        1.3.8 Нэмэлт сувгийн багц - Үндсэн багцыг болон бусад нэмэлтээр авч
        болох кино болон спорт сувгуудыг багтаасан багцыг;
      </p>
      <p align="left" className="m-0 line-height">
        1.3.9 Кино сан - Хэрэглэгч төлбөр төлж кино түрээслэн үзэх боломж бүхий
        үйлчилгээг;
      </p>
      <p align="start" className="m-0 line-height">
        1.3.10 Багц төхөөрөмж - Компаниас үйлчилгээнд гаргаж байгаа тавган
        антен, хүлээн авагч болон бусад дагалдах хэрэгсэл, төхөөрөмжүүдийг;
      </p>
      <p align="left" className="m-0 line-height">
        1.3.11 Дагалдах хэрэгсэл - Багцын төхөөрөмжүүдээс салгаж дангаар нь
        борлуулах боломжтой төхөөрөмжийг;
      </p>
      <p align="start" className="m-0 line-height">
        1.3.12 Aнтен - Хиймэл дагуулаас ирж байгаа дохиог 1 цэгт төвлөрүүлж
        дуран /ЛНБ/ руу дамжуулах төхөөрөмжийг;
      </p>
      <p align="start" className="m-0 line-height">
        1.3.13 Дуран буюу ЛНБ - Тавган антенаас ирсэн дохиог шуугиангүйгээр
        өсгөж хүлээн авагч руу кабель утсаар дамжуулах төхөөрөмжийг;
      </p>
      <p align="start" className="m-0 line-height">
        1.3.14 Коксиал кабель утас - Дурангаас өгсөн дохиог унтралт багатайгаар
        хүлээн авагч руу дамжуулах холбох хэрэгслийг;
      </p>
      <p align="start" className="m-0 line-height">
        1.3.15 Set top box - Хүлээн авагч төхөөрөмжийг;
      </p>
      <p align="start" className="m-0 line-height">
        1.3.16 Смарт карт - Хиймэл дагуулаас нууцлалтайгаар ирж байгаа дохио
        буюу мэдээллийг хэрэглэгчийн хүлээн авагч дээр задлан хүргэж байгаа
        хэрэгслийг;
      </p>
      <p align="left" className="m-0 line-height">
        1.3.17 Картын дугаар - Хэрэглэгчийн сувгийн нууцлалыг цахимжуулсан
        хэлбэрийг;
      </p>
      <p align="left" className="m-0 line-height">
        1.3.18 Админ дугаар – Хэрэглэгч нь өөрийн смар картын дансаа цэнэглэх,
        зарцуулалт хийх, нэмэлт үйлчилгээг идэвхжүүлэх зориулалт бүхий смарт
        картын дансаа удирдах эрх бүхий үүрэн холбооны дугаарыг;
      </p>
      <h6>ХОЁР. ҮЙЛЧИЛГЭЭНИЙ НӨХЦӨЛ</h6>
      <p align="start" className="m-0 line-height">
        2.1 Хэрэглэгч нь ДДэш-ийн хүлээн авагч төхөөрөмж, смарт карттай байх
        бөгөөд энэхүү гэрээний нөхцөлүүдийг хүлээн зөвшөөрч, үйлчилгээний
        төлбөрөө төлснөөр үйлчилгээг авах боломжтой болно.
      </p>
      <p align="start" className="m-0 line-height">
        2.2 ДДэш нь үндсэн болон нэмэлт сувгуудын багцын үйлчилгээг хэрэглэгчид
        санал болгох бөгөөд хэрэглэгч нь эдгээр сувгийн багцын үйлчилгээг
        идэвхжүүлснээр нэмэлт үйлчилгээг идэвхжүүлэх эрхтэй болно.
      </p>
      <p align="start" className="m-0 line-height">
        2.3 Хэрэглэгч нь гэрээ байгуулах болон смарт картыг шинээр авах
        тохиолдолд хүчин төгөлдөр иргэний үнэмлэх /гадаад паспорт, жолооны
        үнэмлэх/-тэйгээ өөрийн биеэр ирж үйлчлүүлэх бөгөөд энэхүү гэрээнд тусгах
        мэдээллийг ДДэш-д үнэн зөв өгөх.
      </p>
      <p align="start" className="m-0 line-height">
        2.4 ДДэш-ийн үйлчилгээг авах зорилгоор тодорхой төлбөр төлж эзэмшиж буй
        смарт карт нь хэрэглэгчийн өмч байх бөгөөд хэрэглэгч уг картыг
        үрэгдүүлсэн, гэмтээсэн, бусдад шилжүүлснээс болж үүссэн харилцааг
        хэрэглэгч дангаар хариуцна.
      </p>
      <p align="start" className="m-0 line-height">
        2.5 Гэрээнд талууд гарын үсэг зурснаар хүчин төгөлдөр болж хэрэглэгч нь
        багцын үйлчилгээгээ ашиглах эрхтэй болох бөгөөд уг гэрээг талууд
        харилцан тохиролцон цуцлагдах өдөр хүртэл энэхүү гэрээ хүчин төгөлдөр
        үйлчилнэ.
      </p>
      <p align="start" className="m-0 line-height">
        2.6 Хэрэглэгч банкаар үйлчилгээний төлбөрөө төлсөн бол банк хоорондын
        гүйлгээнээс хамааран үйлчилгээний эрх нээгдэх хугацаа удааширч болно.
      </p>
      <p align="start" className="m-0 line-height">
        2.7 Хэрэглэгч нь ДДэш-н үйлчилгээг ашиглалгүйгээр хоёр жил өнгөрсөн
        тохиолдолд смарт картын үндсэн дансанд байгаа үлдэгдэл дүн автоматаар
        устгагдана.
      </p>
      <p align="start" className="m-0 line-height">
        2.8 ДДэш нь гэрээнд нэмэлт, өөрчлөлт оруулах тохиолдолд нэмэлт
        өөрчлөлтийг өөрийн мэдээллийн суваг /веб сайт, Фейсбүүк хуудас г.м/-ийг
        ашиглан олон нийтэд мэдээллийг 5 хоногийн өмнө хүргэснээс гэрээнд
        өөрчлөлт орсонд тооцно.
      </p>
      <h6>ГУРАВ.ХЭРЭГЛЭГЧИЙН ЭРХ ҮҮРЭГ</h6>
      <p align="start" className="m-0 line-height">
        3.1 Хэрэглэгч нь үйлчилгээг өөрийн хүсэлтээр сонгох, сонгосон
        үйлчилгээнийхээ талаар холбогдох мэдээллийг авах;
      </p>
      <p align="left" className="m-0 line-height">
        3.2 Хэрэглэгч нь өөрийн сонирхлын дагуу нэмэлт үйлчилгээ авах эрхтэй;
      </p>
      <p align="start" className="m-0 line-height">
        3.3 Хэрэглэгч үйлчилгээний талаарх санал, хүсэлт, гомдлоо ДДэш-ийн албан
        ёсны хаяг, утсаар хандаж гаргах;
      </p>
      <p align="left" className="m-0 line-height">
        3.4 Хэрэглэгч өөрийн хувийн мэдээллийг үнэн зөв өгөх;
      </p>
      <p align="start" className="m-0 line-height">
        3.5 Хэрэглэгч гэрээнд тусгагдсан байгаа мэдээлэл нь өөрчлөгдөх, хаягийн
        шилжилт хийсэн тохиолдолд ажлын 5 хоногт багтаан ДДэш-д мэдэгдэх бөгөөд
        мэдэгдээгүй улмаас үүсэх аливаа хариуцлагыг ДДэш хүлээхгүй.
      </p>
      <p align="left" className="m-0 line-height">
        3.6 Хэрэглэгч үйлчилгээний төлбөрийг бүрэн төлснөөр үйлчилгээг авах;
      </p>
      <p align="start" className="m-0 line-height">
        3.7 Хэрэглэгч нь үйлчилгээг нийтээр хүлээн зөвшөөрсөн хэм хэмжээнд,
        хуулийн хүрээнд ашиглах;
      </p>
      <p align="start" className="m-0 line-height">
        3.8 Хэрэглэгч нууц дугаараа гуравдагч этгээдэд дамжуулахгүй байх
        үүрэгтэй бөгөөд дамжуулснаас үүсэх аливаа хохирлыг хэрэглэгч өөрөө
        хариуцах;
      </p>
      <p align="start" className="m-0 line-height">
        3.9 Хэрэглэгч нь үйлчилгээг ДДэш-ээс батлан гаргасан үйлчилгээний
        нөхцөлийн дагуу ашиглах;
      </p>
      <p align="start" className="m-0 line-height">
        3.10 Хэрэглэгч нь үйлчилгээний төлбөрөө төлөхдөө гүйлгээний утга дээр
        смарт картын дугаар эсвэл админ дугаар, багцын код болон үйлчилгээ
        идэвхжүүлэх сарыг заавал бичнэ.
      </p>
      <p align="start" className="m-0 line-height">
        3.11 Хэрэглэгч нь ДДэш-ээс үзүүлж буй үйлчилгээний /үндсэн сувгийн багц,
        нэмэлт сувгийн багц, кино сангийн үйлчилгээ, төлбөртэй нэмэлт сувгууд
        гэх зэрэг/-ний талаар хангалттай мэдээлэл авах эрхтэй бөгөөд хэрэв
        бүтээгдэхүүн үйлчилгээний талаар эргэлзээ байвал энэ тухайгаа лавлаж,
        үнэн зөв мэдээлэл авсан байх үүрэгтэй.
      </p>
      <p align="start" className="m-0 line-height">
        3.12 Хэрэглэгчийн санаатай болон санамсаргүй үйлдлээс болж төхөөрөмжийн
        ашиглах заавар зөрчигдөж, бусдын өмч хөрөнгөд шууд болон шууд бусаар
        учирсан хохирлыг компани хариуцахгүй.
      </p>
      <h6>ДӨРӨВ.ДДЭШИЙН ЭРХ ҮҮРЭГ</h6>
      <p align="left" className="m-0 line-height">
        4.1 Хэрэглэгчээс гэрээнд заасан үүргээ биелүүлэхийг шаардах;
      </p>
      <p align="start" className="m-0 line-height">
        4.2 Техник технологийн шинэчлэл хийх, засвар хийх тохиолдолд өөрийн
        боломжит мэдээллийн хэрэгслийн аль нэгийг ашиглан Хэрэглэгчид урьдчилан
        мэдэгдсэний үндсэн дээр үйлчилгээг түр хаах;
      </p>
      <p align="start" className="m-0 line-height">
        4.3 Хэрэглэгчид шинээр үйлчилгээ санал болгох, урамшуулал, хөнгөлөлтийн
        талаар мэдээлэл өгөх, холбоо барих зорилгоор Хэрэглэгчийн хувийн
        мэдээлэлд дурдсан хаяг, утас, и-мэйлээр хандах;
      </p>
      <p align="start" className="m-0 line-height">
        4.4 Үйлчилгээг өөрийн техникийн боломж, хүчин чадлын хүрээнд, стандартын
        дагуу чанартай үзүүлэх;
      </p>
      <p align="start" className="m-0 line-height">
        4.5 Хэрэглэгчийн мэдээлэл болон дэлгэрэнгүй хуулгыг хэрэглэгч өөрт нь
        болон болон хуулийн эрх бүхий байгууллагын шаардлагаар гаргаж өгөхөөс
        бусад тохиолдолд Хүний хувийн мэдээлэл хамгаалах тухай хуулинд заасны
        дагуу задруулахгүй байх;
      </p>
      <p align="start" className="m-0 line-height">
        4.6 Хэрэглэгчээс үйлчилгээний талаар ирүүлсэн санал хүсэлтийг хүлээн
        авч, гаргасан гомдлыг түргэн шуурхай барагдуулах;
      </p>
      <p align="start" className="m-0 line-height">
        4.7 ДДэш нь Хэрэглэгчийн буруутай үйл ажиллагааны улмаас өөрт учирсан
        хохирлоо нэхэмжлэх;
      </p>
      <p align="left" className="m-0 line-height">
        4.8 ДДэш нь энэхүү гэрээнд заасны дагуу гэрээг дангаар цуцлах;
      </p>
      <p align="left" className="m-0 line-height">
        4.9 Хэрэглэгчид үйлчилгээний эрх дуусаж байгаа тухай мессеж, дэлгэцийн
        захидлаар дамжуулан сануулах;
      </p>
      <p align="left" className="m-0 line-height">
        4.10 Өргөн нэвтрүүлгийн тухай хуульд зааснаар өргөн нэвтрүүлгийн
        телевизийн үйлчилгээний хөлсийг үйлчилгээ үзүүлэгч тал нь Монголын
        телевизүүдийн холбоонд шилжүүлэх үүрэгтэй.
      </p>
      <p align="left" className="m-0 line-height">
        4.11 Хэрэглэгч үндсэн болон нэмэлт багцын үйлчилгээний төлбөрийг
        төлөөгүй тохиолдолд Харилцаа Холбооны Зохицуулах Хорооноос баталсан
        холбогдох журмын дагуу зөвхөн олон нийтийн телевизийн үндсэн сувгийг 30
        хүртэлх хоногийн хугацаанд үзүүлнэ.
      </p>
      <h6></h6>
      <h6>ТАВ. ТӨЛБӨР ТООЦОО ТҮҮНИЙГ ТӨЛӨХ АРГАЧЛАЛ</h6>
      <p align="left" className="m-0 line-height">
        5.1 Хэрэглэгч нь үйлчилгээний төлбөрийг урьдчилан төлөх бөгөөд төлсөн
        төлбөрт зохих хугацаа дуусахаас өмнө нэмж төлбөр төлөөгүй тохиолдолд
        үйлчилгээний эрх хаагдах болно.
      </p>
      <p align="left" className="m-0 line-height">
        <img
          width="7"
          height="11"
          src="file:///C:/Users/NARMAN~1.G/AppData/Local/Temp/msohtmlclip1/01/clip_image002.jpg"
        />
        5.2 Хэрэглэгч үйлчилгээний төлбөрийг ДДэш-ийн салбар, Юнителийн
        үйлчилгээний салбар болон мобайл диллер, агентад бэлнээр эсвэл
      </p>
      <p align="left" className="m-0 line-height">
        <img
          width="7"
          height="22"
          src="file:///C:/Users/NARMAN~1.G/AppData/Local/Temp/msohtmlclip1/01/clip_image006.gif"
          align="left"
        />
        Хаан банк: 505 905 0128, Төрийн банк: 106 600 004668
      </p>
      <p align="left" className="m-0 line-height">
        <img
          width="22"
          height="10"
          src="file:///C:/Users/NARMAN~1.G/AppData/Local/Temp/msohtmlclip1/01/clip_image008.jpg"
        />
        Голомт банк: 110 298 7935, Хас банк: 500 023 2657
      </p>
      <p align="left" className="m-0 line-height">
        Худалдаа хөгжлийн банк: 499 135 803
      </p>
      <p align="left" className="m-0 line-height">
        тоот дансанд банкаар болон интернэт банкаар, мөн ДДЭШ аппликейшн,
        Фейсбүүк чатбот, ДДЭШ-ийн Веб сайтаар дамжуулан тус тус төлж болно.
      </p>
      <p align="start" className="m-0 line-height">
        5.3 Төлбөртэй холбоотой маргаан үүссэн тохиолдолд Талууд асуудлыг бүрэн
        шийдвэрлэсний дараа илүү төлөлт гарсан тохиолдолд илүү төлбөрийг смарт
        картын данс руу шилжүүлэх бөгөөд дутсан бол хэрэглэгч төлбөрийг нэмж
        төлнө.
      </p>
      <p align="start" className="m-0 line-height">
        5.4 Сарын үйлчилгээний төлбөрт МҮОНРТ-ийн суурь хураамж хамаарахгүй
        бөгөөд Өргөн нэвтрүүлгийн телевизийн үйлчилгээний хөлс болон бусад
        татвар, хураамж орсон болно.
      </p>
      <p align="start" className="m-0 line-height">
        5.5 Багц төхөөрөмжийн үнэ болон бусад дагалдах хэрэгслийн үнэд НӨАТ
        орсон болно.
      </p>
      <p align="start" className="m-0 line-height">
        5.6 Банкны үйлчилгээг ашиглан төлбөр хийхдээ зааврын дагуу утгыг зөв
        бөглөх үүрэгтэй ба буруу бөглөснөөс үүдсэн асуудлыг ДДэш хариуцахгүй.
      </p>
      <h6>ЗУРГАА. ТАЛУУДЫН ХҮЛЭЭХ ХАРИУЦЛАГА, ТҮҮНЭЭС ЧӨЛӨӨЛӨГДӨХ ҮНДЭСЛЭЛ</h6>
      <p align="start" className="m-0 line-height">
        6.1 Талууд энэхүү гэрээгээр хүлээсэн үүргээ биелүүлээгүй нь гэрээнд
        заасны дагуу хариуцлага хүлээх, гэрээ цуцлах үндэслэл болно.
      </p>
      <p align="start" className="m-0 line-height">
        6.2 Хэрэглэгч энэхүү гэрээний 3-р зүйлд заасан үүргээ биелүүлээгүйгээс
        болж үүсэх аливаа хариуцлагыг ДДэш хариуцахгүй.
      </p>
      <p align="start" className="m-0 line-height">
        6.3 Үйлчилгээний чанарыг сайжруулах зорилгоор техникийн өргөтгөл,
        шинэчлэлтээс хамаарч сүлжээ доголдож болох ба энэ тохиолдолд ДДэш-ийн
        зүгээс нийтэд мэдэгдэнэ.
      </p>
      <p align="left" className="m-0 line-height">
        6.4 Хэрэглэгчийн смарт карт ашиглалттай холбоотой аливаа үйлдлийг ДДэш
        хариуцахгүй.
      </p>
      <p align="start" className="m-0 line-height">
        6.5 Монгол Улсын хууль тогтоомжид заасан гэнэтийн, мөн байгалийн
        давагдашгүй хүчин зүйлийн шинжтэй онцгой нөхцөл байдлын улмаас үйлчилгээ
        тасалдсан тохиолдолд ДДэш хариуцлага хүлээхгүй.
      </p>
      <h6>ДОЛОО. БУСАД ЗОХИЦУУЛАЛТ</h6>
      <p align="start" className="m-0 line-height">
        7.1 Энэхүү гэрээтэй холбогдон гарч болзошгүй бүх санал зөрөлдөөн, аливаа
        маргааныг талууд харилцан зөвшилцөх аргаар шийдвэрлэхийг эрмэлзэх бөгөөд
        ийнхүү шийдвэрлэж чадахгүй тохиолдолд зөвхөн Монгол Улсын хууль
        тогтоомжид заасны дагуу Монгол Улсын шүүхээр шийдвэрлүүлнэ.
      </p>
      <p align="start" className="m-0 line-height">
        7.2 Гэрээг 2 хувь үйлдэж, талууд хувь тус бүрийг хадгалсан нь хууль зүйн
        хувьд адил хүчинтэй байна.
      </p>
      <p align="start" className="m-0 line-height">
        7.3 ДДэш нь дараах үндэслэлээр Хэрэглэгчид урьдчилан мэдэгдэлгүйгээр
        үйлчилгээг зогсоох, гэрээг нэг талын санаачилгаар цуцлах эрхтэй болно.
        Үүнд:
      </p>
      <p align="left" className="m-0 line-height">
        7.3.1 Хэрэглэгч гэрээгээр хүлээсэн үүргээ зөрчсөн;
      </p>
      <p align="left" className="m-0 line-height">
        7.3.2 Хэрэглэгч төлбөрөө урьдчилан төлөөгүй;
      </p>
      <p align="left" className="m-0 line-height">
        7.3.3 Хэрэглэгч смарт картыг ДДэш-д мэдэгдэлгүйгээр гуравдагч этгээдэд
        шилжүүлсэн;
      </p>
      <p align="left" className="m-0 line-height">
        7.3.4 Хэрэглэгч эрх зүйн чадвар, чадамжгүй болсон нь тогтоогдсон;
      </p>
      <p align="start" className="m-0 line-height">
        7.4 Энэхүү гэрээгээр зохицуулаагүй аливаа харилцааг Монгол Улсын Иргэний
        хуулийн дагуу зохицуулна.
      </p>
      <p align="start" className="m-0 line-height">
        7.5 ДДэш нь гэрээг цахим хэлбэрт оруулж болох бөгөөд цахим гэрээтэй
        хэрэглэгч танилцаж үйлдэл хийсэн, эсхүл тоон гарын үсгээрээ
        баталгаажуулснаар тухайн гэрээг зөвшөөрсөнд тооцно.
      </p>
      <p align="start" className="m-0 line-height">
        ТОНОГ ТӨХӨӨРӨМЖИЙН БАТАЛГААНЫ ЗОХИЦУУЛАЛТ
      </p>
      <p align="start" className="m-0 line-height">
        Тоног төхөөрөмжийн баталгааны хуудас /цаашид “баталгааны хуудас” гэх/ нь
        зөвхөн ДДэшТВ ХХК /цаашид “компани” гэх/-иас худалдан авсан болон бусад
        хөнгөлөлт, урамшууллын нөхцөлөөр авсан Хүлээн авагч /цаашид “тоног
        төхөөрөмж” гэх/-д хамаарах бөгөөд баталгаат хугацааны доторх үйлдвэрийн
        доголдлыг засах, ижил төрлийн тоног төхөөрөмжөөр солих харилцаанд
        үйлчилнэ.
      </p>
      <p align="start" className="m-0 line-height">
        1 Зөвхөн компаниас худалдан авсан болон хөнгөлөлт, урамшууллын нөхцөлөөр
        авсан тоног төхөөрөмжийг худалдан авсан баримтыг гэрээний хувьтай хамт
        авчирсан тохиолдолд баталгааны нөхцөл үйлчилнэ. Эдгээр баримтууд
        байхгүй, бичилтийг өөрчилсөн, арилгасан, засварласан тохиолдолд
        баталгааны хуудас үйлчлэхгүй.
      </p>
      <p align="start" className="m-0 line-height">
        2 Адаптар болон HDMI кабель, залгуур, удирдлага зэрэгт баталгаа байхгүй.
      </p>
      <p align="start" className="m-0 line-height">
        3 Тоног төхөөрөмжийн баталгаат хугацаа нь Хүлээн авагчийг худалдан авсан
        болон хөнгөлөлт, урамшууллын нөхцөлөөр компаниас авсан өдрөөс хойш 6
        /зургаан/ сар байна.
      </p>
      <p align="start" className="m-0 line-height">
        4 Баталгаа нь дараах тохиолдлуудад хамаарахгүй. Үүнд:
      </p>
      <p align="start" className="m-0 line-height">
        4.1 Тоног төхөөрөмжийг зориулалтын бусаар ашигласан;
      </p>
      <p align="start" className="m-0 line-height">
        4.2 Бусад засварын газраар засвар хийлгэсэн, схем, дизайныг өөрчилсөн,
        лацыг хөдөлгөсөн, унагаасан;
      </p>
      <p align="start" className="m-0 line-height">
        4.3 Гадна болон дотор эд ангийн бүх төрлийн механик гэмтэл;
      </p>
      <p align="start" className="m-0 line-height">
        4.4 Дотор нь гадны биет, бодис, ус, шингэн, шавж орсноос үүдсэн гэмтэл;
      </p>
      <p align="start" className="m-0 line-height">
        4.5 Байгалийн гамшиг, түймэр, санамсаргүй гадаад хүчин зүйлүүдийн
        (аянга, цахилгааны хэлбэлзэл гэх мэт) нөлөөллөөс үүдэн гарсан гэмтэл;
      </p>
      <p align="start" className="m-0 line-height">
        4.6 Стандартад тохироогүй 220В-оос дээш, доош тэжээл, холбоо хэрэгсэл,
        кабель, хэрэглэснээс үүдэн гарсан гэмтэл;
      </p>
      <p align="start" className="m-0 line-height">
        5. Гэмтэлтэй тоног төхөөрөмжийг зөвхөн компанийн үйлчилгээний салбар
        болон засварын төвд худалдан авсан болон хөнгөлөлт, урамшууллын
        нөхцөлөөр авсан баримт /эсвэл үйлчилгээний гэрээ/-ыг үндэслэн хүлээж
        авна.
      </p>
      <p align="start" className="m-0 line-height">
        6. Худалдан авсан болон хөнгөлөлт, урамшууллын нөхцөлөөр авсан баримтыг
        хаяж үрэгдүүлсэн бол баталгаа хүчингүй болох ба дахин нөхөж олгохгүй.
      </p>
      <p align="start" className="m-0 line-height">
        7. Тоног төхөөрөмжөө авчирч өгөхдөө тоос шороо, хүнсний үлдэгдэл, тос
        зэргийг сайтар цэвэрлэсэн байх.
      </p>
      <p align="start" className="m-0 line-height">
        8. Гэмтэлтэй тоног төхөөрөмжийг үйлчилгээний салбар, засварын төвд
        хүргэх зэрэг аливаа зардлыг хэрэглэгч өөрөө хариуцна.
      </p>
      <p align="start" className="m-0 line-height">
        ҮЙЛЧИЛГЭЭНИЙ ЭРХЭЭ СУНГАХТАЙ ХОЛБООТОЙ ЗӨВЛӨМЖ
      </p>
      <p align="start" className="m-0 line-height">
        Хэрэглэгч үйлчилгээний төлбөрөө ДДэш-ийн үйлчилгээний салбар, Юнителийн
        үйлчилгээний салбар, Юнителийн мобайл диллер, агент болон банкны
        салбаруудад хандан төлөх эсвэл ДДэш аппликейшн, Фейсбүүк чатбот, веб
        сайт зэргээр дамжуулан төлөх боломжтой.
      </p>
      <p align="left" className="m-0 line-height">
        <strong>Банкны салбарт хандан </strong>
        <strong>багцаа сунгах</strong>
        <strong> бол:</strong>
      </p>
      <p align="start" className="m-0 line-height">
        - Админ дугаар эсвэл Смарт картын дугаарын аль нэгийг бичих
      </p>
      <p align="start" className="m-0 line-height">
        - Сунгалт хийх гэж буй багцын кодыг зөв бичих
      </p>
      <p align="start" className="m-0 line-height">
        - Сунгалт хийх хугацааг зөв бичих
      </p>
      <p align="start" className="m-0 line-height">
        <strong>Мобайл банк болон интернэт банкаар багцаа сунгах бол</strong>
        <strong>:</strong>
      </p>
      <p align="start" className="m-0 line-height">
        - Админ дугаар эсвэл Смарт картын дугаарын аль нэгийг бичих
      </p>
      <p align="start" className="m-0 line-height">
        - Сунгалт хийх гэж буй багцын код болон сунгах хугацааг зөв сонгох
      </p>
      <p align="start" className="m-0 line-height">
        - Сунгалтын мөнгөн дүнг зөв сонгох
      </p>
      <p align="start" className="m-0 line-height">
        <strong>Смарт картаа</strong>
        <strong> буюу ДДЭШ дансаа</strong>
        <strong> цэнэглэх бол:</strong>
      </p>
      <p align="start" className="m-0 line-height">
        - Смарт картын дугаар эсвэл Админ дугаарын аль нэгийг бичээд dans гэж
        бичнэ.
      </p>
      <p align="start" className="m-0 line-height">
        <strong>
          <em>Санамж: Бичилт зөв б</em>
        </strong>
        <strong>
          <em>ол</em>
        </strong>
        <strong>
          <em> таны үйлчилгээний эрх 15-20 минутын д</em>
        </strong>
        <strong>
          <em>отор</em>
        </strong>
        <strong>
          <em> нээгдэнэ.</em>
        </strong>
      </p>
    </div>
  );
}

export default Deal;
