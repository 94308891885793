import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Form, Row, Container, FormGroup } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { webApiUrl } from "../../helper/globals";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import DatePicker from "react-datepicker";
import * as messageActions from "../../actions/messages";
import { connect } from "react-redux";
import { TOAST_INFO, TOAST_WARNING } from "../../actions/types";
import { useForm } from "react-hook-form";

function CV(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [sending, setSending] = useState(false);
  const { id } = useParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [jobId, setJobId] = useState(null);
  const [birthDate, setBirthDate] = useState(new Date());
  const [generalEdu0BeginDate, setGeneralEdu0BeginDate] = useState(null);
  const [generalEdu0EndDate, setGeneralEdu0EndDate] = useState(null);
  const [generalEdu1BeginDate, setGeneralEdu1BeginDate] = useState(null);
  const [generalEdu1EndDate, setGeneralEdu1EndDate] = useState(null);
  const [generalEdu2BeginDate, setGeneralEdu2BeginDate] = useState(null);
  const [generalEdu2EndDate, setGeneralEdu2EndDate] = useState(null);
  const [university0BeginDate, setUniversity0BeginDate] = useState(null);
  const [university0EndDate, setUniversity0EndDate] = useState(null);
  const [university1BeginDate, setUniversity1BeginDate] = useState(null);
  const [university1EndDate, setUniversity1EndDate] = useState(null);
  const [university2BeginDate, setUniversity2BeginDate] = useState(null);
  const [university2EndDate, setUniversity2EndDate] = useState(null);
  const [jobExp0BeginDate, setJobExp0BeginDate] = useState(null);
  const [jobExp0EndDate, setJobExp0EndDate] = useState(null);
  const [jobExp1BeginDate, setJobExp1BeginDate] = useState(null);
  const [jobExp1EndDate, setJobExp1EndDate] = useState(null);
  const [trainingInfo0Date, setTrainingInfo0Date] = useState(null);
  const [trainingInfo1Date, setTrainingInfo1Date] = useState(null);
  const [trainingInfo2Date, setTrainingInfo2Date] = useState(null);
  const [parent0Birthday, setparent0Birthday] = useState(null);
  const [parent1Birthday, setparent1Birthday] = useState(null);
  const [parent2Birthday, setparent2Birthday] = useState(null);
  const [parent3Birthday, setparent3Birthday] = useState(null);
  const [awards0AwardedDate, setAwards0AwardedDate] = useState(null);
  const [awards1AwardedDate, setAwards1AwardedDate] = useState(null);
  const [awards2AwardedDate, setAwards2AwardedDate] = useState(null);

  const [possibleDate, setPossibleDate] = useState(new Date());
  const [isDriver, setIsDriver] = useState(null);
  useEffect(() => {
    loadCaptchaEnginge(6, "#0047ba", "white", "numbers");

    if (!isLoaded)
      axios.get(webApiUrl + "get_jobs").then((res) => {
        setJobs(res.data.result);
        setIsLoaded(true);
      });
  }, [isLoaded, jobs]);
  const onSubmit = (data) => {
    setSending(true);
    let e = document.getElementById("form");
    const captcha = document.getElementById("captcha").value;
    if (validateCaptcha(captcha)) {
      const formData = new FormData(e);
      axios
        .post(webApiUrl + "cv/addcv", formData)
        .then((res) => {
          props.setMessage({
            type: TOAST_INFO,
            text: res.data.message,
          });

          navigate("/");
        })
        .catch((err) => {
          props.setMessage({
            type: TOAST_WARNING,
            text: "Анкет оруулахад алдаа гарлаа",
          });
          setSending(false);
        });
    } else {
      props.setMessage({
        type: TOAST_WARNING,
        text: "Captcha-г зөв оруулна уу.",
      });
      setSending(false);
    }
  };
  return (
    <Container>
      <div className="section-content" style={{ overflowX: "auto" }}>
        <Row>
          <Col md={12}>
            <h6>Санамж :</h6>
            <h7>
              <p>
                1. Анкетыг бөглөхдөө бүх асуултанд тодорхой хариулж, үг
                товчлохгүй байхыг хүсье.
              </p>
              <p>2. Цээж зураг хавсаргах шаардлагатай.</p>
              <p>3. Та монгол фонтоор бичнэ үү.</p>
              <p>
                4. Улаан одтой ( <span style={{ color: "red" }}>*</span> )
                талбарыг заавал бөглөх шаардлагатайг анхаарна уу.
              </p>
            </h7>
          </Col>
        </Row>

        <Form id="form" onSubmit={handleSubmit(onSubmit, console.log(errors))}>
          <Row>
            <Col md={4}>
              <label>
                Таны сонирхож буй ажлын байр{" "}
                <span style={{ color: "red" }}>*</span>
              </label>
            </Col>
            <Col md={8}>
              <Form.Select
                class="form-control"
                name="job_id"
                required
                value={jobId ?? id}
                onChange={(e) => {
                  setJobId(e.target.value);
                }}
              >
                {jobs.map((e) => {
                  return <option value={e.id}>{e.name}</option>;
                })}
              </Form.Select>
            </Col>
          </Row>

          <hr />

          <Row>
            <Col md={4}>
              <label htmlfor="job">1. Ургийн овог: </label>
            </Col>
            <Col md={8}>
              <Form.Control type="text" name="mainUrgiinOvog" />
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <label htmlfor="job">
                2. Эцэг/Эхийн нэр : <span style={{ color: "red" }}>*</span>
              </label>
            </Col>
            <Col md={8}>
              <FormGroup>
                <Form.Control
                  type="text"
                  class="form-control"
                  name="mainLname"
                  id="mainLname"
                  isInvalid={errors.mainLname}
                  {...register("mainLname", { required: true })}
                />
                <Form.Control.Feedback type="invalid">
                  ↑ Мэдээлэл оруулаагүй байна.
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <label htmlfor="job">
                3. Өөрийн нэр : <span style={{ color: "red" }}>*</span>
              </label>
            </Col>
            <Col md={8}>
              <FormGroup>
                <Form.Control
                  type="text"
                  class="form-control"
                  id="text"
                  name="mainFname"
                  {...register("mainFname", { required: true })}
                  isInvalid={errors.mainFname}
                />
                <Form.Control.Feedback type="invalid">
                  ↑ Мэдээлэл оруулаагүй байна.
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <label htmlfor="job">4. Хүйс : </label>
            </Col>
            <Col md={8}>
              <Form.Check
                inline
                id="1"
                type="radio"
                value="1"
                name="mainGender"
                checked
                label="Эрэгтэй"
              />
              <Form.Check
                inline
                id="2"
                type="radio"
                value="2"
                name="mainGender"
                style={{ marginLeft: "10px" }}
                label="Эмэгтэй"
              />
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <label htmlfor="birthdate">
                5. Төрсөн огноо : <span style={{ color: "red" }}>*</span>
              </label>
            </Col>
            <Col md={8}>
              <FormGroup>
                <DatePicker
                  selected={birthDate}
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode="select"
                  {...register("mainBirthdate")}
                  isInvalid={errors.mainBirthdate}
                  onChange={(date) => setBirthDate(date)}
                  maxDate={new Date()}
                  required
                  name="mainBirthdate"
                />
                <Form.Control.Feedback type="invalid">
                  ↑ Мэдээлэл оруулаагүй байна.
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label>
                6. Регистрийн дугаар : <span style={{ color: "red" }}>*</span>
              </label>
            </Col>
            <Col md={8} style={{ paddingTop: "10px" }}>
              <FormGroup>
                <Form.Control
                  id="register"
                  type="text"
                  name="mainRegister"
                  maxlength="10"
                  {...register("mainRegister", {
                    required: true,
                    maxlength: 10,
                    minlength: 10,
                    pattern: /([\u0400-\u04ff]{2})([0-9]{8})/,
                  })}
                  isInvalid={errors.mainRegister}
                />
                <Form.Control.Feedback type="invalid">
                  ↑ Регистрийн дугаараа зөв оруулна уу.
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
          </Row>

          <hr />
          <Row>
            <label htmlfor="job">
              7. Гэрийн хаяг :<span style={{ color: "red" }}>*</span>
            </label>
          </Row>
          <Row>
            <Col md={4}>
              <label htmlfor="job" class="ml30">
                Хот аймаг :{" "}
              </label>
            </Col>
            <Col md={8}>
              <FormGroup>
                <Form.Select
                  class="form-control"
                  name="addressAimag"
                  {...register("addressAimag", { required: true })}
                  isInvalid={errors.addressAimag}
                >
                  <option value="Улаанбаатар">Улаанбаатар</option>
                  <option value="Архангай">Архангай</option>
                  <option value="Баянөлгий">Баянөлгий</option>
                  <option value="Баянхонгор">Баянхонгор</option>
                  <option value="Булган">Булган</option>
                  <option value="Говь-алтай">Говь-алтай</option>
                  <option value="Говьсүмбэр">Говьсүмбэр</option>
                  <option value="Дархан-Уул">Дархан-Уул</option>
                  <option value="Дорноговь">Дорноговь</option>
                  <option value="Дорнод">Дорнод</option>
                  <option value="Дундговь">Дундговь</option>
                  <option value="Орхон">Орхон</option>
                  <option value="Өвөрхангай">Өвөрхангай</option>
                  <option value="Өмнөговь">Өмнөговь</option>
                  <option value="Сүхбаатар">Сүхбаатар</option>
                  <option value="Сэлэнгэ">Сэлэнгэ</option>
                  <option value="Ховд">Ховд</option>
                  <option value="Хөвсгөл">Хөвсгөл</option>
                  <option value="Хэнтий">Хэнтий</option>
                  <option value="Төв">Төв</option>
                  <option value="Увс">Увс</option>
                  <option value="Завхан">Завхан</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  ↑ Мэдээлэл оруулаагүй байна.
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label htmlfor="job" class="ml30">
                Сум дүүрэг :
              </label>
            </Col>
            <Col md={8}>
              <FormGroup>
                <Form.Control
                  class="form-control"
                  type="text"
                  name="addressSum"
                  {...register("addressSum", { required: true })}
                  isInvalid={errors.addressSum}
                />
                <Form.Control.Feedback type="invalid">
                  ↑ Мэдээлэл оруулаагүй байна.
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label htmlfor="job" class="ml30">
                Баг хороо:{" "}
              </label>
            </Col>
            <Col md={8}>
              <FormGroup>
                <Form.Control
                  class="form-control"
                  type="text"
                  name="addressBag"
                  {...register("addressBag", { required: true })}
                  isInvalid={errors.addressBag}
                />
                <Form.Control.Feedback type="invalid">
                  ↑ Мэдээлэл оруулаагүй байна.
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label htmlfor="job" class="ml30">
                Хороолол гудамж :{" "}
              </label>
            </Col>
            <Col md={8}>
              <FormGroup>
                <Form.Control
                  class="form-control"
                  type="text"
                  name="addressHoroolol"
                  {...register("addressHoroolol", { required: true })}
                  isInvalid={errors.addressHoroolol}
                />
                <Form.Control.Feedback type="invalid">
                  ↑ Мэдээлэл оруулаагүй байна.
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label htmlfor="job" class="ml30">
                Байр хашаа :{" "}
              </label>
            </Col>
            <Col md={8}>
              <FormGroup>
                <Form.Control
                  class="form-control"
                  type="text"
                  name="addressBair"
                  {...register("addressBair", { required: true })}
                  isInvalid={errors.addressBair}
                />
                <Form.Control.Feedback type="invalid">
                  ↑ Мэдээлэл оруулаагүй байна.
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label htmlfor="job" class="ml30">
                Тоот :{" "}
              </label>
            </Col>
            <Col md={8}>
              <FormGroup>
                <Form.Control
                  class="form-control"
                  type="text"
                  name="addressToot"
                  {...register("addressToot", { required: true })}
                  isInvalid={errors.addressToot}
                />
                <Form.Control.Feedback type="invalid">
                  ↑ Мэдээлэл оруулаагүй байна.
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
          </Row>

          <hr />
          <Row>
            <label htmlfor="birthdate">8.Холбоо барих : </label>
          </Row>
          <Row>
            <Col md={4}>
              <label htmlfor="birthdate" class="ml30">
                Утас (гар) : <span style={{ color: "red" }}>*</span>
              </label>
            </Col>
            <Col md={8}>
              <FormGroup>
                <Form.Control
                  type="text"
                  class="form-control"
                  maxlength="8"
                  id="mainCellPhone"
                  name="mainCellPhone"
                  {...register("mainCellPhone", {
                    required: true,
                    pattern: /^[0-9]*$/,
                  })}
                  isInvalid={errors.mainCellPhone}
                />
                <Form.Control.Feedback type="invalid">
                  ↑ Утсаа зөв оруулна уу.
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label htmlfor="birthdate" class="ml30">
                Утас (гэр) :
              </label>
            </Col>
            <Col md={8}>
              <FormGroup>
                <Form.Control
                  type="text"
                  maxlength="8"
                  class="form-control"
                  id="text"
                  name="mainTelephone"
                  {...register("mainTelephone", { pattern: /^[0-9]*$/ })}
                  isInvalid={errors.mainTelephone}
                />
                <Form.Control.Feedback type="invalid">
                  ↑ Утсаа зөв оруулна уу.
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label htmlfor="birthdate" class="ml30">
                Утас (бусад) :{" "}
              </label>
            </Col>
            <Col md={8}>
              <FormGroup>
                <Form.Control
                  type="text"
                  maxlength="8"
                  class="form-control"
                  id="text"
                  name="mainOtherPhone"
                  {...register("mainOtherPhone", { pattern: /^[0-9]*$/ })}
                  isInvalid={errors.mainOtherPhone}
                />
                <Form.Control.Feedback type="invalid">
                  ↑ Утсаа зөв оруулна уу.
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label htmlfor="birthdate" class="ml30">
                Имэйл хаяг : <span style={{ color: "red" }}>*</span>
              </label>
            </Col>
            <Col md={8}>
              <FormGroup>
                <Form.Control
                  type="text"
                  class="form-control"
                  id="text"
                  name="mainEmail"
                  {...register("mainEmail", {
                    required: true,
                    pattern: /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/,
                  })}
                  isInvalid={errors.mainEmail}
                />
                <Form.Control.Feedback type="invalid">
                  ↑ Email хаяг зөв оруулна уу.
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
          </Row>

          <hr />

          <Row>
            <Col md={4}>
              <label htmlfor="birthdate">
                Таны зураг :<span style={{ color: "red" }}>*</span>{" "}
              </label>
            </Col>
            <Col md={8}>
              <FormGroup>
                <Form.Control
                  type="file"
                  id="portrait"
                  class="form-control"
                  name="portrait_file"
                  accept="image/jpeg,image/jpg"
                  {...register("portrait_file", {
                    required: true,
                  })}
                  isInvalid={errors.portrait_file}
                />
                <Form.Control.Feedback type="invalid">
                  ↑ Та зургаа оруулна уу.
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <label htmlfor="birthdate">Боловсролын диплом : </label>
            </Col>
            <Col md={8}>
              <Form.Control
                type="file"
                class="form-control"
                name="diploma_file"
                accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <h5>Санамж :</h5>
              <h7>
                <p>Таны зураг хэсэгт 5MB-с ихгүй зөвхөн .JPG оруулна уу</p>
                <p>
                  Боловсролын диплом хэсэгт зөвхөн .PDF, .DOC, .DOCX файл
                  хавсаргаж болно
                </p>
              </h7>
            </Col>
          </Row>

          <hr />
          <Row>
            <label htmlfor="birthdate">9. Ерөнхий боловсрол: </label>
          </Row>
          <Row>
            <div class="col-md-12">
              <table class="table table-bordered" width="1000">
                <thead>
                  <th>Улс</th>
                  <th>Аймаг/Хот</th>
                  <th>Сургуулийн нэр</th>
                  <th style={{ width: "100px" }}>Элссэн он </th>
                  <th style={{ width: "100px" }}>Төгссөн он</th>
                  <th>Боловсролын түвшин</th>
                  <th style={{ width: "70px" }}>Дүн</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="generalEdu0Country"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="generalEdu0City"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="generalEdu0School"
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={generalEdu0BeginDate}
                        onChange={(date) => setGeneralEdu0BeginDate(date)}
                        class="form-control ognoo"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        type="text"
                        name="generalEdu0BeginDate"
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={generalEdu0EndDate}
                        onChange={(date) => setGeneralEdu0EndDate(date)}
                        class="form-control ognoo"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        type="text"
                        name="generalEdu0EndDate"
                      />
                    </td>
                    <td>
                      <Form.Select
                        class="form-control"
                        type="text"
                        name="generalEdu0EduLvl"
                      >
                        <option value="1">Бага</option>
                        <option value="2">Бүрэн бус дунд</option>
                        <option value="3">Бүрэн дунд</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="generalEdu0Gpa"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="generalEdu1Country"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="generalEdu1City"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="generalEdu1School"
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={generalEdu1BeginDate}
                        onChange={(date) => setGeneralEdu1BeginDate(date)}
                        class="form-control ognoo"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        type="text"
                        name="generalEdu1BeginDate"
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={generalEdu1EndDate}
                        onChange={(date) => setGeneralEdu1EndDate(date)}
                        class="form-control ognoo"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        type="text"
                        name="generalEdu1EndDate"
                      />
                    </td>
                    <td>
                      <Form.Select
                        class="form-control"
                        type="text"
                        name="generalEdu1EduLvl"
                      >
                        <option value="1">Бага</option>
                        <option value="2">Бүрэн бус дунд</option>
                        <option value="3">Бүрэн дунд</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="generalEdu1Gpa"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="generalEdu2Country"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="generalEdu2City"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="generalEdu2School"
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={generalEdu2BeginDate}
                        onChange={(date) => setGeneralEdu2BeginDate(date)}
                        class="form-control ognoo"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        type="text"
                        name="generalEdu2BeginDate"
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={generalEdu2EndDate}
                        onChange={(date) => setGeneralEdu2EndDate(date)}
                        class="form-control ognoo"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        type="text"
                        name="generalEdu2EndDate"
                      />
                    </td>
                    <td>
                      <Form.Select
                        class="form-control"
                        type="text"
                        name="generalEdu2EduLvl"
                      >
                        <option value="1">Бага</option>
                        <option value="2">Бүрэн бус дунд</option>
                        <option value="3">Бүрэн дунд</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="generalEdu2Gpa"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Row>
          <Row>
            <label htmlfor="birthdate">
              10. Дээд, тусгай мэргэжлийн боловсрол /Та төгссөн болон одоо
              суралцаж байгаа сургууль, мэргэжил, дүнгийн мэдээ/:{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
          </Row>
          <Row>
            <div class="col-md-12">
              <table class="table table-bordered" width="1200">
                <thead>
                  <th>Улс</th>
                  <th>Аймаг/Хот</th>
                  <th>Сургуулийн нэр</th>
                  <th style={{ width: "100px" }}>Элссэн он </th>
                  <th style={{ width: "100px" }}>Төгссөн он</th>
                  <th>Эзэмшсэн мэргэжил</th>
                  <th style={{ width: "120px" }}>Боловсролын түвшин </th>
                  <th style={{ width: "80px" }}>Дүнгийн голч</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <FormGroup>
                        <Form.Control
                          class="form-control"
                          type="text"
                          name="university0Country"
                          {...register("university0Country", {
                            required: true,
                          })}
                          isInvalid={errors.university0Country}
                        />
                        <Form.Control.Feedback type="invalid">
                          ↑ Мэдээлэл оруулаагүй байна.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="university0City"
                        {...register("university0City", {
                          required: true,
                        })}
                        isInvalid={errors.university0City}
                      />
                      <Form.Control.Feedback type="invalid">
                        ↑ Мэдээлэл оруулаагүй байна.
                      </Form.Control.Feedback>
                    </td>
                    <td>
                      <FormGroup>
                        <Form.Control
                          class="form-control"
                          type="text"
                          name="university0School"
                          {...register("university0School", {
                            required: true,
                          })}
                          isInvalid={errors.university0School}
                        />
                        <Form.Control.Feedback type="invalid">
                          ↑ Мэдээлэл оруулаагүй байна.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup>
                        <DatePicker
                          {...register("university0BeginDate")}
                          isInvalid={errors.university0BeginDate}
                          selected={university0BeginDate}
                          onChange={(date) => setUniversity0BeginDate(date)}
                          class="form-control ognoo"
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          type="text"
                          required
                          name="university0BeginDate"
                        />
                        <Form.Control.Feedback type="invalid">
                          ↑ Мэдээлэл оруулаагүй байна.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup>
                        <DatePicker
                          {...register("university0EndDate")}
                          isInvalid={errors.university0EndDate}
                          selected={university0EndDate}
                          onChange={(date) => setUniversity0EndDate(date)}
                          class="form-control ognoo"
                          showYearDropdown
                          showMonthDropdown
                          dropdownMode="select"
                          type="text"
                          required
                          name="university0EndDate"
                        />
                        <Form.Control.Feedback type="invalid">
                          ↑ Мэдээлэл оруулаагүй байна.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup>
                        <Form.Control
                          class="form-control"
                          type="text"
                          name="university0Profession"
                          {...register("university0Profession", {
                            required: true,
                          })}
                          isInvalid={errors.university0Profession}
                        />
                        <Form.Control.Feedback type="invalid">
                          ↑ Мэдээлэл оруулаагүй байна.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup>
                        <Form.Select
                          class="form-control"
                          type="text"
                          name="university0EduLvl"
                          {...register("university0EduLvl", {
                            required: true,
                          })}
                          isInvalid={errors.university0EduLvl}
                        >
                          <option value="1">Бакалавр</option>
                          <option value="2">Магистр</option>
                          <option value="3">Доктор</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          ↑ Мэдээлэл оруулаагүй байна.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup>
                        <Form.Control
                          class="form-control"
                          type="text"
                          name="university0Gpa"
                          {...register("university0Gpa", {
                            required: true,
                          })}
                          isInvalid={errors.university0Gpa}
                        />
                        <Form.Control.Feedback type="invalid">
                          ↑ Мэдээлэл оруулаагүй байна.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="university1Country"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="university1City"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="university1School"
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={university1BeginDate}
                        onChange={(date) => setUniversity1BeginDate(date)}
                        class="form-control ognoo"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        type="text"
                        name="university1BeginDate"
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={university1EndDate}
                        onChange={(date) => setUniversity1EndDate(date)}
                        class="form-control ognoo"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        type="text"
                        name="university1EndDate"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="university1Profession"
                      />
                    </td>
                    <td>
                      <Form.Select
                        class="form-control"
                        type="text"
                        name="university1EduLvl"
                      >
                        <option value="1">Бакалавр</option>
                        <option value="2">Магистр</option>
                        <option value="3">Доктор</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="university1Gpa"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="university2Country"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="university2City"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="university2School"
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={university2BeginDate}
                        onChange={(date) => setUniversity2BeginDate(date)}
                        class="form-control ognoo"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        type="text"
                        name="university2BeginDate"
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={university2EndDate}
                        onChange={(date) => setUniversity2EndDate(date)}
                        class="form-control ognoo"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        type="text"
                        name="university2EndDate"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="university2Profession"
                      />
                    </td>
                    <td>
                      <Form.Select
                        class="form-control"
                        type="text"
                        name="university2EduLvl"
                      >
                        <option value="1">Бакалавр</option>
                        <option value="2">Магистр</option>
                        <option value="3">Доктор</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="university2Gpa"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Row>
          <Row>
            <label htmlfor="birthdate">11. Гадаад хэлний мэдлэг : </label>
          </Row>
          <Row>
            <div class="col-md-12">
              <table class="table table-bordered" width="1200">
                <thead>
                  <th>Гадаад хэлний нэр</th>
                  <th>Үзсэн хугацаа</th>
                  <th>Ярьсныг ойлгох</th>
                  <th>Өөрөө ярих </th>
                  <th>Уншиж ойлгох</th>
                  <th>Бичиж орчуулах</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="foreignLang0Name"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="foreignLang0TrainingTime"
                      />
                    </td>
                    <td>
                      <Form.Select
                        class="form-control"
                        name="foreignLang0Listening"
                      >
                        <option value="" selected="true" disabled="disabled">
                          -
                        </option>
                        <option value="3">Сайн</option>
                        <option value="2">Дунд</option>
                        <option value="1">Муу</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select
                        class="form-control"
                        name="foreignLang0Speaking"
                      >
                        <option value="" selected="true" disabled="disabled">
                          -
                        </option>
                        <option value="3">Сайн</option>
                        <option value="2">Дунд </option>
                        <option value="1">Муу</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select
                        class="form-control"
                        name="foreignLang0Reading"
                      >
                        <option value="" selected="true" disabled="disabled">
                          -
                        </option>
                        <option value="3">Сайн</option>
                        <option value="2">Дунд </option>
                        <option value="1">Муу</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select
                        class="form-control"
                        name="foreignLang0Writing"
                      >
                        <option value="" selected="true" disabled="disabled">
                          -
                        </option>
                        <option value="3">Сайн</option>
                        <option value="2">Дунд</option>
                        <option value="1">Муу</option>
                      </Form.Select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="foreignLang1Name"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="foreignLang1TrainingTime"
                      />
                    </td>
                    <td>
                      <Form.Select
                        class="form-control"
                        name="foreignLang1Listening"
                      >
                        <option value="" selected="true" disabled="disabled">
                          -
                        </option>
                        <option value="3">Сайн</option>
                        <option value="2">Дунд</option>
                        <option value="1">Муу</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select
                        class="form-control"
                        name="foreignLang1Speaking"
                      >
                        <option value="" selected="true" disabled="disabled">
                          -
                        </option>
                        <option value="3">Сайн</option>
                        <option value="2">Дунд</option>
                        <option value="1">Муу</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select
                        class="form-control"
                        name="foreignLang1Reading"
                      >
                        <option value="" selected="true" disabled="disabled">
                          -
                        </option>
                        <option value="3">Сайн</option>
                        <option value="2">Дунд</option>
                        <option value="1">Муу</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select
                        class="form-control"
                        name="foreignLang1Writing"
                      >
                        <option value="" selected="true" disabled="disabled">
                          -
                        </option>
                        <option value="3">Сайн</option>
                        <option value="2">Дунд</option>
                        <option value="1">Муу</option>
                      </Form.Select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="foreignLang2Name"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="foreignLang2TrainingTime"
                      />
                    </td>
                    <td>
                      <Form.Select
                        class="form-control"
                        name="foreignLang2Listening"
                      >
                        <option value="" selected="true" disabled="disabled">
                          -
                        </option>
                        <option value="3">Сайн</option>
                        <option value="2">Дунд</option>
                        <option value="1">Муу</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select
                        class="form-control"
                        name="foreignLang2Speaking"
                      >
                        <option value="" selected="true" disabled="disabled">
                          -
                        </option>
                        <option value="3">Сайн</option>
                        <option value="2">Дунд</option>
                        <option value="1">Муу</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select
                        class="form-control"
                        name="foreignLang2Reading"
                      >
                        <option value="" selected="true" disabled="disabled">
                          -
                        </option>
                        <option value="3">Сайн</option>
                        <option value="2">Дунд</option>
                        <option value="1">Муу</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select
                        class="form-control"
                        name="foreignLang2Writing"
                      >
                        <option value="" selected="true" disabled="disabled">
                          -
                        </option>
                        <option value="3">Сайн</option>
                        <option value="2">Дунд</option>
                        <option value="1">Муу</option>
                      </Form.Select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Row>
          <Row>
            <label htmlfor="birthdate">
              12. Таны ажлын туршлага /Одоо эрхэлж буй ажлаасаа эхлэн бөглөнө
              үү/:{" "}
            </label>
          </Row>
          <Row>
            <div class="col-md-12">
              <table
                class="table table-bordered"
                style={{ marginBottom: "0px" }}
                width="1200"
              >
                <thead>
                  <th>Байгууллагын нэр</th>
                  <th>Эрхэлж байсан албан тушаал</th>
                  <th>Ажилд орсон огноо</th>
                  <th>Ажлаас гарсан огноо</th>
                  <th>Сарын цалин</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="jobExp0OrgName"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="jobExp0Position"
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={jobExp0BeginDate}
                        onChange={(date) => setJobExp0BeginDate(date)}
                        class="form-control ognoo"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        type="text"
                        name="jobExp0BeginDate"
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={jobExp0EndDate}
                        onChange={(date) => setJobExp0EndDate(date)}
                        class="form-control ognoo"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        type="text"
                        name="jobExp0EndDate"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="number"
                        name="jobExp0MonthlyPay"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                class="table table-bordered"
                style={{ width: "100%", marginBottom: "0px" }}
              >
                <thead>
                  <th class="center">Таны ажлын үндсэн үүрэг</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="jobExp0JobGoal"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                class="table table-bordered"
                style={{ width: "100%", marginBottom: "0px" }}
              >
                <thead>
                  <tr>
                    <th colspan="3" class="center">
                      Байгууллагын удирдах албан тушаалтны
                    </th>
                    <th class="center">Ажлаас гарсан шалтгаан</th>
                  </tr>
                  <tr>
                    <th>Нэр</th>
                    <th>Албан тушаал</th>
                    <th>Утас</th>
                    <th rowSpan="2">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="jobExp0LeaderName"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="jobExp0LeaderPosition"
                      />
                    </td>
                    <td>
                      <FormGroup>
                        <Form.Control
                          class="form-control"
                          type="text"
                          maxlength="8"
                          name="jobExp0LeaderPhone"
                          {...register("jobExp0LeaderPhone", {
                            pattern: /^[0-9]*$/,
                          })}
                          isInvalid={errors.jobExp0LeaderPhone}
                        />
                        <Form.Control.Feedback type="invalid">
                          ↑ Утсаа зөв оруулна уу.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </td>
                    <td>
                      <Form.Control
                        as="textarea"
                        rows="2"
                        class="form-control"
                        type="text"
                        name="jobExp0QuitReason"
                        maxlength="100"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <br />
              <table
                class="table table-bordered"
                style={{ marginBottom: "0px" }}
                width="1200"
              >
                <thead>
                  <th>Байгууллагын нэр</th>
                  <th>Эрхэлж байсан албан тушаал</th>
                  <th>Ажилд орсон огноо</th>
                  <th>Ажлаас гарсан огноо</th>
                  <th>Сарын цалин</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="jobExp1OrgName"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="jobExp1Position"
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={jobExp1BeginDate}
                        onChange={(date) => setJobExp1BeginDate(date)}
                        class="form-control ognoo"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        type="text"
                        name="jobExp1BeginDate"
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={jobExp1EndDate}
                        onChange={(date) => setJobExp1EndDate(date)}
                        class="form-control ognoo"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        type="text"
                        name="jobExp1EndDate"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="number"
                        name="jobExp1MonthlyPay"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                class="table table-bordered"
                style={{ width: "100%", marginBottom: "0px" }}
              >
                <thead>
                  <th class="center">Таны ажлын үндсэн үүрэг</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="jobExp1JobGoal"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-bordered" style={{ width: "100% " }}>
                <thead>
                  <tr>
                    <th colspan="3" class="center">
                      Байгууллагын удирдах албан тушаалтны
                    </th>
                    <th class="center">Ажлаас гарсан шалтгаан</th>
                  </tr>
                  <tr>
                    <th>Нэр</th>
                    <th>Албан тушаал</th>
                    <th>Утас</th>
                    <th rowspan="2">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="jobExp1LeaderName"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="jobExp1LeaderPosition"
                      />
                    </td>
                    <td>
                      <FormGroup>
                        <Form.Control
                          class="form-control"
                          type="text"
                          maxlength="8"
                          name="jobExp1LeaderPhone"
                          {...register("jobExp1LeaderPhone", {
                            pattern: /^[0-9]*$/,
                          })}
                          isInvalid={errors.jobExp1LeaderPhone}
                        />
                        <Form.Control.Feedback type="invalid">
                          ↑ Утсаа зөв оруулна уу.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </td>
                    <td>
                      <textarea
                        class="form-control"
                        rows="2"
                        type="text"
                        maxlength="100"
                        name="jobExp1QuitReason"
                      ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Row>

          <Row>
            <Col md={4}>
              <label htmlfor="birthdate">
                13. Таны хүсч буй цалингийн хэмжээ :{" "}
              </label>
            </Col>

            <Col md={4}>
              Доод (₮){" "}
              <Form.Control
                class="form-control"
                type="number"
                name="mainLowestPay"
              />
            </Col>
            <Col md={4}>
              Дээд (₮){" "}
              <Form.Control
                class="form-control"
                type="number"
                name="mainHighestPay"
              />
            </Col>
          </Row>
          <br />
          <Row>
            <label htmlfor="birthdate">
              14. Таны ажил мэргэжил, туршлага, ур чадварын талаар тодорхойлолт
              өгөх хүний мэдээлэл : <span style={{ color: "red" }}>*</span>
            </label>
          </Row>
          <Row>
            <div class="col-md-12">
              <table class="table table-bordered">
                <thead>
                  <th>Тодорхойлолт гаргах хүний нэр</th>
                  <th>Байгууллагын нэр</th>
                  <th>Албан тушаал</th>
                  <th>Утас</th>
                  <th>Имэйл</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <FormGroup>
                        <Form.Control
                          class="form-control"
                          type="text"
                          name="giveSkillInfo0Name"
                          {...register("giveSkillInfo0Name", {
                            required: true,
                          })}
                          isInvalid={errors.giveSkillInfo0Name}
                        />
                        <Form.Control.Feedback type="invalid">
                          ↑ Мэдээлэл оруулаагүй байна.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup>
                        <Form.Control
                          class="form-control"
                          type="text"
                          name="giveSkillInfo0OrgName"
                          {...register("giveSkillInfo0OrgName", {
                            required: true,
                          })}
                          isInvalid={errors.giveSkillInfo0OrgName}
                        />
                        <Form.Control.Feedback type="invalid">
                          ↑ Мэдээлэл оруулаагүй байна.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup>
                        <Form.Control
                          class="form-control"
                          type="text"
                          name="giveSkillInfo0Position"
                          {...register("giveSkillInfo0Position", {
                            required: true,
                          })}
                          isInvalid={errors.giveSkillInfo0Position}
                        />
                        <Form.Control.Feedback type="invalid">
                          ↑ Мэдээлэл оруулаагүй байна.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup>
                        <Form.Control
                          class="form-control"
                          type="text"
                          maxlength="8"
                          name="giveSkillInfo0CellPhone"
                          {...register("giveSkillInfo0CellPhone", {
                            required: true,
                          })}
                          isInvalid={errors.giveSkillInfo0CellPhone}
                        />
                        <Form.Control.Feedback type="invalid">
                          ↑ Мэдээлэл оруулаагүй байна.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup>
                        <Form.Control
                          class="form-control"
                          type="text"
                          name="giveSkillInfo0Email"
                          {...register("giveSkillInfo0Email", {
                            required: true,
                            pattern:
                              /^\w+([-]?\w+)*@\w+([-]?\w+)*(\w{2,3})+$|^$/,
                          })}
                          isInvalid={errors.giveSkillInfo0Email}
                        />
                        <Form.Control.Feedback type="invalid">
                          ↑ Email хаяг зөв оруулна уу.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="giveSkillInfo1Name"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="giveSkillInfo1OrgName"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="giveSkillInfo1Position"
                      />
                    </td>
                    <td>
                      <FormGroup>
                        <Form.Control
                          class="form-control"
                          type="text"
                          maxlength="8"
                          name="giveSkillInfo1CellPhone"
                          {...register("giveSkillInfo1CellPhone", {
                            pattern: /^[0-9]*$/,
                          })}
                          isInvalid={errors.giveSkillInfo1CellPhone}
                        />
                        <Form.Control.Feedback type="invalid">
                          ↑ Утсаа зөв оруулна уу.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup>
                        <Form.Control
                          class="form-control"
                          type="text"
                          name="giveSkillInfo1Email"
                          {...register("giveSkillInfo1Email", {
                            pattern:
                              /^\w+([-]?\w+)*@\w+([-]?\w+)*(\w{2,3})+$|^$/,
                          })}
                          isInvalid={errors.giveSkillInfo1Email}
                        />
                        <Form.Control.Feedback type="invalid">
                          ↑ Email хаяг зөв оруулна уу.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="giveSkillInfo2Name"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="giveSkillInfo2OrgName"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="giveSkillInfo2Position"
                      />
                    </td>
                    <td>
                      <FormGroup>
                        <Form.Control
                          class="form-control"
                          type="text"
                          maxlength="8"
                          name="giveSkillInfo2CellPhone"
                          {...register("giveSkillInfo2CellPhone", {
                            pattern: /^[0-9]*$/,
                          })}
                          isInvalid={errors.giveSkillInfo2CellPhone}
                        />
                        <Form.Control.Feedback type="invalid">
                          ↑ Утсаа зөв оруулна уу.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </td>
                    <td>
                      <FormGroup>
                        <Form.Control
                          class="form-control"
                          type="text"
                          name="giveSkillInfo2Email"
                          {...register("giveSkillInfo2Email", {
                            pattern:
                              /^\w+([-]?\w+)*@\w+([-]?\w+)*(\w{2,3})+$|^$/,
                          })}
                          isInvalid={errors.giveSkillInfo2Email}
                        />
                        <Form.Control.Feedback type="invalid">
                          ↑ Email хаяг зөв оруулна уу.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Row>
          <Row>
            <label htmlfor="birthdate">
              15. Таны мэргэжлээрээ болон бусад чиглэлээр хамрагдаж байсан
              сургалтуудын мэдээлэл:{" "}
            </label>
          </Row>
          <Row>
            <div class="col-md-12">
              <table class="table table-bordered">
                <thead>
                  <th>Сургалтын байгууллагын нэр</th>
                  <th>Ямар чиглэлээр</th>
                  <th>Сургалтын үргэлжилсэн хугацаа</th>
                  <th>Хэдэн онд</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="trainingInfo0OrgName"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="trainingInfo0Direction"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="trainingInfo0PeriodTime"
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={trainingInfo0Date}
                        onChange={(date) => setTrainingInfo0Date(date)}
                        class="form-control"
                        type="text"
                        name="trainingInfo0Date"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="trainingInfo1OrgName"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="trainingInfo1Direction"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="trainingInfo1PeriodTime"
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={trainingInfo1Date}
                        onChange={(date) => setTrainingInfo1Date(date)}
                        class="form-control"
                        type="text"
                        name="trainingInfo1Date"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="trainingInfo2OrgName"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="trainingInfo2Direction"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="trainingInfo2PeriodTime"
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={trainingInfo2Date}
                        onChange={(date) => setTrainingInfo2Date(date)}
                        class="form-control"
                        type="text"
                        name="trainingInfo2Date"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Row>
          <Row>
            <label htmlfor="birthdate">16. Гэр бүлийн байдал : </label>
          </Row>
          <Row>
            <div class="col-md-12">
              <table class="table table-bordered">
                <thead>
                  <th>Таны хэн болох</th>
                  <th>Овог</th>
                  <th>Нэр</th>
                  <th style={{ width: "90px" }}>Төрсөн он</th>
                  <th>Мэргэжил </th>
                  <th>Одоо ажиллаж байгаа байгууллагын нэр</th>
                  <th>Одоо эрхэлж буй ажил, албан тушаал</th>
                  <th>Утас</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="parent0Who"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="parent0Lname"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="parent0Fname"
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={parent0Birthday}
                        onChange={(date) => setparent0Birthday(date)}
                        class="form-control"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        type="text"
                        name="parent0Birthday"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="parent0Profession"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="parent0OrgName"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="parent0Position"
                      />
                    </td>
                    <td>
                      <FormGroup>
                        <Form.Control
                          class="form-control"
                          type="text"
                          maxlength="8"
                          name="parent0Phone"
                          {...register("parent0Phone", {
                            pattern: /^[0-9]*$/,
                          })}
                          isInvalid={errors.parent0Phone}
                        />
                        <Form.Control.Feedback type="invalid">
                          ↑ Утсаа зөв оруулна уу.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="parent1Who"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="parent1Lname"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="parent1Fname"
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={parent1Birthday}
                        onChange={(date) => setparent1Birthday(date)}
                        class="form-control"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        type="text"
                        name="parent1Birthday"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="parent1Profession"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="parent1OrgName"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="parent1Position"
                      />
                    </td>
                    <td>
                      <FormGroup>
                        <Form.Control
                          class="form-control"
                          type="text"
                          maxlength="8"
                          name="parent1Phone"
                          {...register("parent1Phone", {
                            pattern: /^[0-9]*$/,
                          })}
                          isInvalid={errors.parent1Phone}
                        />
                        <Form.Control.Feedback type="invalid">
                          ↑ Утсаа зөв оруулна уу.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="parent2Who"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="parent2Lname"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="parent2Fname"
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={parent2Birthday}
                        onChange={(date) => setparent2Birthday(date)}
                        class="form-control "
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        type="text"
                        name="parent2Birthday"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="parent2Profession"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="parent2OrgName"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="parent2Position"
                      />
                    </td>
                    <td>
                      <FormGroup>
                        <Form.Control
                          class="form-control"
                          type="text"
                          maxlength="8"
                          name="parent2Phone"
                          {...register("parent2Phone", {
                            pattern: /^[0-9]*$/,
                          })}
                          isInvalid={errors.parent2Phone}
                        />
                        <Form.Control.Feedback type="invalid">
                          ↑ Утсаа зөв оруулна уу.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="parent3Who"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="parent3Lname"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="parent3Fname"
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={parent3Birthday}
                        onChange={(date) => setparent3Birthday(date)}
                        class="form-control"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        type="text"
                        name="parent3Birthday"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="parent3Profession"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="parent3OrgName"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="parent3Position"
                      />
                    </td>
                    <td>
                      <FormGroup>
                        <Form.Control
                          class="form-control"
                          type="text"
                          maxlength="8"
                          name="parent3Phone"
                          {...register("parent3Phone", {
                            pattern: /^[0-9]*$/,
                          })}
                          isInvalid={errors.parent3Phone}
                        />
                        <Form.Control.Feedback type="invalid">
                          ↑ Утсаа зөв оруулна уу.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Row>
          <Row>
            <label htmlfor="birthdate">
              17. Гавъяа шагнал /Төрийн, салбарын, байгууллагын / :{" "}
            </label>
          </Row>
          <Row>
            <div class="col-md-12">
              <table class="table table-bordered">
                <thead>
                  <th>Шагналын нэр</th>
                  <th>Шагнагдсан он</th>
                  <th>Хаана ажиллаж байх хугацаанд</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="awards0Name"
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={awards0AwardedDate}
                        onChange={(date) => setAwards0AwardedDate(date)}
                        class="form-control"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        type="text"
                        name="awards0AwardedDate"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="awards0AwardedOrg"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="awards1Name"
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={awards1AwardedDate}
                        onChange={(date) => setAwards1AwardedDate(date)}
                        class="form-control "
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        type="text"
                        name="awards1AwardedDate"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="awards1AwardedOrg"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="awards2Name"
                      />
                    </td>
                    <td>
                      <DatePicker
                        selected={awards2AwardedDate}
                        onChange={(date) => setAwards2AwardedDate(date)}
                        class="form-control"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        type="text"
                        name="awards2AwardedDate"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="awards2AwardedOrg"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Row>
          <Row>
            <label htmlfor="birthdate">18. Хувь хүний онцлогууд : </label>
          </Row>
          <Row>
            <div class="col-md-12">
              <table class="table table-bordered">
                <thead>
                  <th style={{ width: "38%" }}>
                    Онцгой сайн ур чадвар , зан үйл
                  </th>
                  <th style={{ width: "38%" }}>
                    Хөгжүүлэх сайжруулах ур чадвар, зан үйл
                  </th>
                  <th style={{ width: "30%" }}>
                    Эрүүл мэндийн хувьд анхаарах ямар нэгэн зовиуртай эсэх
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <textarea
                        rows="2"
                        class="form-control"
                        type="text"
                        name="persAdvantageSpecialTalent"
                        maxlength="100"
                      ></textarea>
                    </td>
                    <td>
                      <textarea
                        rows="2"
                        class="form-control"
                        type="text"
                        name="persAdvantageDevelopTalent"
                        maxlength="100"
                      ></textarea>
                    </td>
                    <td>
                      <textarea
                        rows="2"
                        class="form-control"
                        type="text"
                        name="persAdvantageHealthCare"
                        maxlength="100"
                      ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Row>
          <Row>
            <label htmlfor="birthdate">19. Жолооч эсэх : </label>
          </Row>
          <Row>
            <div class="col-md-12">
              <table class="table table-bordered">
                <thead>
                  <th>Та жолооны үнэмлэхтэй юу ?</th>
                  <th>Ангилал сонгох</th>
                  <th>Хэдэн жил машин барьж байгаа вэ?</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Check
                        inline
                        id="true"
                        type="radio"
                        value="true"
                        name="driverIsDriver"
                        onChange={(e) => {
                          setIsDriver(e.target.value);
                        }}
                        label="Тийм"
                      />
                      <Form.Check
                        inline
                        id="false"
                        type="radio"
                        value="false"
                        name="driverIsDriver"
                        label="Үгүй"
                        onChange={(e) => {
                          setIsDriver(e.target.value);
                        }}
                      />
                    </td>

                    <td class="driverInactive">
                      <Form.Check
                        inline
                        disabled={!(isDriver === "true")}
                        class="driverCat driverInactive"
                        type="checkbox"
                        name="driverCategory"
                        value="B"
                        label="B"
                      />
                      <Form.Check
                        inline
                        disabled={!(isDriver === "true")}
                        class="driverCat driverInactive"
                        type="checkbox"
                        name="driverCategory"
                        value="C"
                        label="C"
                      />
                      <Form.Check
                        disabled={!(isDriver === "true")}
                        class="driverCat driverInactive"
                        type="checkbox"
                        name="driverCategory"
                        value="D"
                        label="D"
                        inline
                      />
                      <Form.Check
                        disabled={!(isDriver === "true")}
                        class="driverCat driverInactive"
                        type="checkbox"
                        name="driverCategory"
                        value="E"
                        label="E"
                        inline
                      />
                      <Form.Check
                        disabled={!(isDriver === "true")}
                        class="driverCat driverInactive"
                        type="checkbox"
                        name="driverCategory"
                        value="M"
                        label="M"
                        inline
                      />
                    </td>
                    <td>
                      <Form.Control
                        disabled={!(isDriver === "true")}
                        class="form-control driverInactive"
                        type="number"
                        name="driverExpYear"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label>Жолооны үнэмлэхний дугаар</label>
                    </td>
                    <td colspan="2">
                      <Form.Control
                        disabled={!(isDriver === "true")}
                        class="form-control driverInactive"
                        type="text"
                        name="driverDriverNumber"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Row>
          <Row>
            <label htmlfor="birthdate">
              20. Компьютерийн програм дээр ажиллах чадвар :{" "}
            </label>
          </Row>
          <Row>
            <div class="col-md-12">
              <table class="table table-bordered">
                <thead>
                  <th>Microsoft Word</th>
                  <th>Microsoft Excel</th>
                  <th>Microsoft Powerpoint</th>
                  <th>Microsoft Access</th>
                  <th>Adobe Photoshop</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Select class="form-control" name="comUsageMsWord">
                        <option value="0">Мэдэхгүй</option>
                        <option value="1">Анхан шат</option>
                        <option value="2">Хэрэглээний түвшинд</option>
                        <option value="3">Бүрэн эзэмшсэн</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select class="form-control" name="comUsageMsExcel">
                        <option value="0">Мэдэхгүй</option>
                        <option value="1">Анхан шат</option>
                        <option value="2">Хэрэглээний түвшинд</option>
                        <option value="3">Бүрэн эзэмшсэн</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select
                        class="form-control"
                        name="comUsageMsPowerPoint"
                      >
                        <option value="0">Мэдэхгүй</option>
                        <option value="1">Анхан шат</option>
                        <option value="2">Хэрэглээний түвшинд</option>
                        <option value="3">Бүрэн эзэмшсэн</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select class="form-control" name="comUsageMsAccess">
                        <option value="0">Мэдэхгүй</option>
                        <option value="1">Анхан шат</option>
                        <option value="2">Хэрэглээний түвшинд</option>
                        <option value="3">Бүрэн эзэмшсэн</option>
                      </Form.Select>
                    </td>
                    <td>
                      <Form.Select
                        class="form-control"
                        name="comUsagePhotoshop"
                      >
                        <option value="0">Мэдэхгүй</option>
                        <option value="1">Анхан шат</option>
                        <option value="2">Хэрэглээний түвшинд</option>
                        <option value="3">Бүрэн эзэмшсэн</option>
                      </Form.Select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Row>
          <Row>
            <label htmlfor="birthdate">
              21. Та урлаг, спортын ямар авъяастай вэ?{" "}
            </label>
          </Row>
          <Row>
            <div class="col-md-12">
              <table class="table table-bordered">
                <thead>
                  <th>Ямар төрлөөр</th>
                  <th>Хэдэн жил хичээллэсэн</th>
                  <th>Зэрэг шагналтай эсэх</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="talent0Name"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="talent0TrainingYear"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="talent0TalentAward"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="talent1Name"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="number"
                        maxlength="2"
                        name="talent1TrainingYear"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="talent1TalentAward"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="talent2Name"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="number"
                        maxlength="2"
                        name="talent2TrainingYear"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="talent2TalentAward"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Row>
          <Row>
            <label htmlfor="birthdate">
              22. ДДэшТВ ХХК-д таны танил найз, хамаатан садан ажилладаг эсэх?{" "}
            </label>
          </Row>
          <Row>
            <div class="col-md-12">
              <table class="table table-bordered">
                <thead>
                  <th>Таны юу болох</th>
                  <th>Овог нэр</th>
                  <th>Хэлтэс</th>
                  <th>Албан тушаал</th>
                  <th>Утас</th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="ddishRel0Relation"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="ddishRel0Name"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="ddishRel0Department"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="ddishRel0Position"
                      />
                    </td>
                    <td>
                      <FormGroup>
                        <Form.Control
                          class="form-control"
                          type="text"
                          maxlength="8"
                          name="ddishRel0Phone"
                          {...register("ddishRel0Phone", {
                            pattern: /^[0-9]*$/,
                          })}
                          isInvalid={errors.ddishRel0Phone}
                        />
                        <Form.Control.Feedback type="invalid">
                          ↑ Утсаа зөв оруулна уу.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="ddishRel1Relation"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="ddishRel1Name"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="ddishRel1Department"
                      />
                    </td>
                    <td>
                      <Form.Control
                        class="form-control"
                        type="text"
                        name="ddishRel1Position"
                      />
                    </td>
                    <td>
                      <FormGroup>
                        <Form.Control
                          class="form-control"
                          type="text"
                          maxlength="8"
                          name="ddishRel1Phone"
                          {...register("ddishRel1Phone", {
                            pattern: /^[0-9]*$/,
                          })}
                          isInvalid={errors.ddishRel1Phone}
                        />
                        <Form.Control.Feedback type="invalid">
                          ↑ Утсаа зөв оруулна уу.
                        </Form.Control.Feedback>
                      </FormGroup>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Row>
          <Row>
            <label>
              23. Таны ажилд орох боломжтой хугацаа :{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
          </Row>
          <Row>
            <Col md={12}>
              <DatePicker
                selected={possibleDate}
                onChange={(date) => setPossibleDate(date)}
                minDate={new Date()}
                name="mainPossibleDateForJob"
              />
            </Col>
          </Row>
          <Row>
            <label>
              Зурган код оруулна уу
              <span style={{ color: "red" }}>*</span>
            </label>
          </Row>
          <Row>
            <Col md={5} className="text-right">
              <LoadCanvasTemplate />
            </Col>
            <Col md={7}>
              <Form.Control class="form-control" type="text" id="captcha" />
            </Col>
          </Row>
          <Row>
            <div class="box-footer">
              <button type="submit" class="btn btn-primary" disabled={sending}>
                Илгээх
              </button>
            </div>
          </Row>
        </Form>
      </div>
    </Container>
  );
}

const mapActionToProps = (dispatch) => {
  return {
    setMessage: (payload) => dispatch(messageActions.setMessage(payload)),
  };
};
export default connect(null, mapActionToProps)(CV);
