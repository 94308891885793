import { getSecured, getAnonymous } from "../helper/requests";
import {
  SEARCH_ACCOUNT,
  SEARCH_CLEAR,
  SEARCH_ERROR,
  SEARCH_SUCCESS,
} from "./types";

export const searchAccount = (searchValue) => {
  return function (dispatch, getState) {
    const isloggedIn = getState().auth.isLoggedIn;
    dispatch({
      type: SEARCH_ACCOUNT,
      payload: searchValue,
    });
    if (isloggedIn) {
      getSecured(
        "accountCharge/" + searchValue,
        (res) => {
          dispatch({ type: SEARCH_SUCCESS, payload: res.data });
        },
        (err) => {
          dispatch({ type: SEARCH_ERROR, payload: err.data });
        }
      );
    } else {
      getAnonymous(
        "accountCharge/unlogin/" + searchValue,
        (res) => {
          dispatch({ type: SEARCH_SUCCESS, payload: res.data });
        },
        (err) => {
          dispatch({ type: SEARCH_ERROR, payload: err.data });
        }
      );
    }
  };
};

export const searchClear = () => {
  return function (dispatch) {
    dispatch({
      type: SEARCH_CLEAR,
    });
  };
};
