import {
  GET_CHANNELS_SUCCESS,
  GET_MOVIES_ERROR,
  GET_MOVIES_SUCCESS,
  GET_MOVIE_SUCCESS,
} from "../actions/types";

const initialState = null;
export const nvodReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CHANNELS_SUCCESS: {
      return {
        ...state,
        channels: payload.channels,
      };
    }
    case GET_MOVIES_SUCCESS: {
      return {
        ...state,
        movies: payload.movies,
      };
    }
    case GET_MOVIES_ERROR: {
      return {
        ...state,
        movies: [],
      };
    }
    case GET_MOVIE_SUCCESS: {
      return {
        ...state,
        movie: payload,
      };
    }

    default: {
      return state;
    }
  }
};
