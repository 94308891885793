import { LOGIN, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT } from "../actions/types";

const user = JSON.parse(localStorage.getItem("key"));

const initialState = user
  ? { isLoggedIn: true, user, error: null, isLoading: false }
  : { isLoggedIn: false, user: null, error: null, isLoading: false };

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN: {
      return { ...state, isLoading: true, error: null };
    }
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload,
        isLoading: false,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        error: payload.err,
        isLoading: false,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        error: null,
        isLoading: false,
      };
    default:
      return state;
  }
};
export default authReducer;
