import { Button, Card, Col, Form, Row, Alert } from "react-bootstrap";
import { useSelector, connect } from "react-redux";
import { useNavigate } from "react-router";
import { CHARGE_PRODUCT } from "../../actions/types";
import * as actions from "../../actions/product";
function NOTT(props) {
  const product = useSelector((state) => state.product);
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  if (product?.nott == null && auth.isLoggedIn) {
    props.getNott();
  }

  var nott = auth.isLoggedIn ? (
    product?.nott != null ? (
      <Row xs={1} md={3} className="g-4 section-content">
        {product.nott.map((e) => (
          <Col>
            <Card>
              <Card.Img variant="top" src={e.productImg} />
              <Card.Title className="text-center" style={{ marginTop: "10px" }}>
                {e.productName}
              </Card.Title>
              <Card.Body
              // style={{ display: "flex", justifyContent: "space-around" }}
              >
                <Form
                  key={e.productId}
                  onSubmit={(event) => {
                    event.preventDefault();
                    props.selectProduct(JSON.parse(event.target.product.value));
                    navigate("/order");
                  }}
                >
                  <Form.Group>
                    <Form.Select name="product">
                      {e.priceList.map((s) => {
                        return (
                          <option
                            value={JSON.stringify({
                              orderType: CHARGE_PRODUCT,
                              product: {
                                productName: e.productName,
                                productId: e.productId,
                                productImg: e.productImg,
                                selected: s,
                              },
                              price: e.isPromotion ? s.promoPrice : s.price,
                            })}
                          >
                            {s.month} сар {s.price}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <Row>
                      <Button
                        variant="primary"
                        type="submit"
                        style={{ padding: "10px 0px" }}
                      >
                        Сонгох
                      </Button>
                    </Row>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    ) : null
  ) : (
    <Alert variant="warning">Тун удахгүй...</Alert>
  );

  return nott;
}

const mapActionToProps = (dispatch) => {
  return {
    selectProduct: (product) => dispatch(actions.selectProduct(product)),
    getNott: () => dispatch(actions.getNott()),
  };
};
export default connect(null, mapActionToProps)(NOTT);
