import axios from "axios";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  ListGroupItem,
  Row,
  CardImg,
} from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Slider from "react-slick";
import { CHARGE_PRODUCT, UPGRADE_PRODUCT } from "../../actions/types";
import { webApiUrl } from "../../helper/globals";
import * as actions from "../../actions/product";
import { productsInner } from "./raw";

function GetText(props) {
  const [s, setS] = useState([]);
  useEffect(() => {
    axios.get(webApiUrl + `products/${props.id}`).then((res) => {
      let desc = res?.data?.result[0]?.selectable_desc.split(";");
      setS(desc);
    });
  }, []);
  return (
    <ListGroup variant="flush">
      {s?.map((e) => {
        return <ListGroupItem>{e}</ListGroupItem>;
      })}
    </ListGroup>
  );
}

function LoginProducts(props) {
  const navigate = useNavigate();
  const product = useSelector((state) => state.product);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    props.getProduct();
    props.getUpgrade(user?.productId);
  }, []);
  var products =
    product?.productList != null
      ? product?.productList?.map((el) => {
          return productsInner.map((e, ind) => {
            var splitText = e.selectable_desc?.split(";");
            return (
              el.productId == e.product_id && (
                <Col>
                  <Card
                    style={{
                      height: "855px",
                      width: "320px",
                      marginLeft: "5px",
                      borderStyle: "none",
                      boxShadow: "0 0 5px silver",
                      padding: "10px 7px 15px 7px",
                    }}
                    className="text-center text-hover"
                  >
                    <CardImg
                      // src={webUrl + e.picture}
                      src={e.picture}
                      style={{
                        width: "250px",
                        height:
                          e.product_id === 107 || 108 || 109 ? "78px" : "120px",
                        marginTop: "10px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "5px",
                      }}
                    />
                    <div className="ch-container-x">
                      <div style={{ padding: "0 20px" }}>
                        <div
                          style={{
                            border:
                              e.product_id === 107
                                ? "2px solid #64bf75"
                                : e.product_id === 108
                                ? "2px solid #00b8c6"
                                : "2px solid #7e81bc",
                            borderRadius: "10px",
                            color: "#9099a5",
                            width: "200px",
                          }}
                          className=""
                        ></div>
                      </div>
                    </div>
                    <div style={{ padding: "0 20px" }}>
                      <div
                        style={{
                          fontSize: "12px",
                          lineHeight: "15px",
                          fontFamily: "Roboto",
                          height: "60px",
                        }}
                        className="header-text-card"
                      >
                        {e.alias}
                      </div>
                      <div
                        style={{
                          marginTop: "20px",
                        }}
                        className=""
                      >
                        <div
                          style={{
                            color: "#636f80",
                            fontWeight: "500",
                            fontFamily: "Roboto",
                            fontSize: "12px",
                            marginBottom: "-6px",
                            marginTop: "10px",
                          }}
                        >
                          30хоног
                        </div>
                        {e.product_id == 107 && (
                          <div
                            style={{
                              color: "#64bf75",
                              fontWeight: "500",
                              fontFamily: "Roboto",
                              fontSize: "22px",
                              margin: 0,
                            }}
                          >
                            {e.price}₮
                          </div>
                        )}
                        {e.product_id == 108 && (
                          <div
                            style={{
                              color: "#00b8c6",
                              fontWeight: "700",
                              fontFamily: "Roboto",
                              fontSize: "22px",
                              margin: 0,
                            }}
                          >
                            {e.price}₮
                          </div>
                        )}
                        {e.product_id == 109 && (
                          <div
                            style={{
                              color: "#7e81bc",
                              fontWeight: "700",
                              fontFamily: "Roboto",
                              fontSize: "22px",
                              margin: 0,
                            }}
                          >
                            {e.price}₮
                          </div>
                        )}
                        <div
                          style={{
                            color: "#636f80",
                            fontWeight: "500",
                            fontFamily: "Roboto",
                            fontSize: "12px",
                            marginBottom: "-8px",
                          }}
                        >
                          {e.packages}
                        </div>
                        {e.product_id === 107 && (
                          <div
                            style={{
                              color: "#64bf75",
                              fontWeight: "700",
                              fontFamily: "Roboto",
                              fontSize: "22px",
                            }}
                          >
                            {e.selectable}
                          </div>
                        )}
                        {e.product_id === 108 && (
                          <div
                            style={{
                              color: "#00b8c6",
                              fontWeight: "700",
                              fontFamily: "Roboto",
                              fontSize: "22px",
                            }}
                          >
                            {e.selectable}
                          </div>
                        )}
                        {e.product_id === 109 && (
                          <div
                            style={{
                              color: "#7e81bc",
                              fontWeight: "700",
                              fontFamily: "Roboto",
                              fontSize: "22px",
                            }}
                          >
                            {e.selectable}
                          </div>
                        )}
                      </div>
                      <p className="p-0 m-0 p-container">
                        {splitText?.map((s, indexCus) => {
                          const returnColor = (id) => {
                            if (id === 0) {
                              return "#64bf75";
                            }
                            if (id === 2) {
                              return "#64bf75";
                            }
                            if (id === 4) {
                              return "#00b8c6";
                            }
                            if (id > 4) {
                              return "#7e81bc";
                            }
                          };
                          return (
                            <div
                              style={{
                                color:
                                  indexCus % 2 == 0
                                    ? returnColor(indexCus)
                                    : "#636f80",
                                fontWeight: indexCus % 2 == 0 ? "500" : "400",
                                fontFamily: "Roboto",
                                fontSize: "12px",
                                margin: "0",
                                padding: "0",
                                lineHeight: "15px",
                                paddingTop: indexCus % 2 == 0 && "10px",
                              }}
                            >
                              {s}
                            </div>
                          );
                        })}
                      </p>
                    </div>
                    <div
                      style={{
                        marginTop: "auto",
                      }}
                    >
                      <div
                        style={{
                          padding: "0 5px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor:
                              e.product_id === 107
                                ? "#64bf75"
                                : e.product_id === 108
                                ? "#00b8c6"
                                : "#7e81bc",
                            marginTop: "",
                          }}
                          className="certain-ch"
                        >
                          {e.product_id === 107
                            ? "Онцлох гэр бүлийн сувгууд"
                            : e.product_id === 108
                            ? "Онцлох кино сувгууд"
                            : "Онцлох спортын контентууд"}
                        </div>
                        <CardImg
                          // src={webUrl + e.picture}
                          src={e.certain_img}
                          style={{
                            width: "250px",
                            height:
                              e.product_id === 107 || 108 || 109
                                ? "78px"
                                : "120px",
                            marginTop: "10px",
                            marginLeft: "auto",
                            marginRight: "auto",
                            marginBottom: "5px",
                          }}
                        />
                      </div>
                    </div>
                    <Form
                      key={el.productId}
                      onSubmit={(event) => {
                        event.preventDefault();
                        props.selectProduct(
                          JSON.parse(event.target.product.value)
                        );
                        navigate("/order");
                      }}
                      style={{
                        margin: "10px",
                        padding: "0 20px 0 20px",
                      }}
                    >
                      <Form.Group>
                        <Form.Select name="product">
                          {el.priceList.map((s) => {
                            return (
                              <option
                                className={
                                  s.promoId !== "" && s.promoId !== null
                                    ? "promo"
                                    : ""
                                }
                                value={JSON.stringify({
                                  orderType: CHARGE_PRODUCT,
                                  product: {
                                    productName: el.productName,
                                    productId: el.productId,
                                    productImg: el.productImg,
                                    selected: s,
                                  },
                                  price:
                                    s.promoId === "" || s.promoId === null
                                      ? s.price
                                      : s.promoPrice,
                                })}
                              >
                                {s.days} хоног{" "}
                                {s.promoId === "" || s.promoId === null
                                  ? s.price
                                  : s.promoPrice}
                                ₮
                              </option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                      <Row>
                        <Button variant="outline-primary" type="submit">
                          Сонгох
                        </Button>
                      </Row>
                    </Form>
                  </Card>
                </Col>
              )
            );
          });
        })
      : null;

  var upgradeProducts =
    product?.upgradeProductList != null &&
    product?.upgradeProductList.length !== 0 ? (
      <>
        <h3>Багц ахиулах</h3>
        <Row xs={1} md={3}>
          {product?.upgradeProductList?.map((el) =>
            productsInner.map((e, ind) => {
              var splitText = e.selectable_desc?.split(";");
              return (
                el.productId == e.product_id && (
                  <Col>
                    <Card
                      style={{
                        height: "855px",
                        width: "325px",
                        marginLeft: "5px",
                        borderStyle: "none",
                        boxShadow: "0 0 5px silver",
                        padding: "10px 5px 15px 15px",
                      }}
                      className="text-center text-hover"
                    >
                      <CardImg
                        // src={webUrl + e.picture}
                        src={e.picture}
                        style={{
                          width: "250px",
                          height:
                            e.product_id === 107 || 108 || 109
                              ? "78px"
                              : "120px",
                          marginTop: "10px",
                          marginLeft: "auto",
                          marginRight: "auto",
                          marginBottom: "5px",
                        }}
                      />
                      <div style={{ padding: "0 20px" }}>
                        <div
                          style={{
                            border:
                              e.product_id === 107
                                ? "2px solid #64bf75"
                                : e.product_id === 108
                                ? "2px solid #00b8c6"
                                : "2px solid #7e81bc",
                            borderRadius: "10px",
                          }}
                          className=""
                        ></div>
                      </div>
                      <div style={{ padding: "0 20px" }}>
                        <div
                          style={{
                            fontSize: "12px",
                            lineHeight: "15px",
                            fontFamily: "Roboto",
                            height: "60px",
                          }}
                          className="header-text-card"
                        >
                          {e.alias}
                        </div>
                        <div
                          style={{
                            marginTop: "20px",
                          }}
                          className=""
                        >
                          <div
                            style={{
                              color: "#636f80",
                              fontWeight: "500",
                              fontFamily: "Roboto",
                              fontSize: "12px",
                              marginBottom: "-6px",
                              marginTop: "10px",
                            }}
                          >
                            30хоног
                          </div>
                          {e.product_id == 107 && (
                            <div
                              style={{
                                color: "#64bf75",
                                fontWeight: "500",
                                fontFamily: "Roboto",
                                fontSize: "22px",
                                margin: 0,
                              }}
                            >
                              {e.price}₮
                            </div>
                          )}
                          {e.product_id == 108 && (
                            <div
                              style={{
                                color: "#00b8c6",
                                fontWeight: "700",
                                fontFamily: "Roboto",
                                fontSize: "22px",
                                margin: 0,
                              }}
                            >
                              {e.price}₮
                            </div>
                          )}
                          {e.product_id == 109 && (
                            <div
                              style={{
                                color: "#7e81bc",
                                fontWeight: "700",
                                fontFamily: "Roboto",
                                fontSize: "22px",
                                margin: 0,
                              }}
                            >
                              {e.price}₮
                            </div>
                          )}
                          <div
                            style={{
                              color: "#636f80",
                              fontWeight: "500",
                              fontFamily: "Roboto",
                              fontSize: "12px",
                              marginBottom: "-8px",
                            }}
                          >
                            {e.packages}
                          </div>
                          {e.product_id === 107 && (
                            <div
                              style={{
                                color: "#64bf75",
                                fontWeight: "700",
                                fontFamily: "Roboto",
                                fontSize: "22px",
                              }}
                            >
                              {e.selectable}
                            </div>
                          )}
                          {e.product_id === 108 && (
                            <div
                              style={{
                                color: "#00b8c6",
                                fontWeight: "700",
                                fontFamily: "Roboto",
                                fontSize: "22px",
                              }}
                            >
                              {e.selectable}
                            </div>
                          )}
                          {e.product_id === 109 && (
                            <div
                              style={{
                                color: "#7e81bc",
                                fontWeight: "700",
                                fontFamily: "Roboto",
                                fontSize: "22px",
                              }}
                            >
                              {e.selectable}
                            </div>
                          )}
                        </div>
                        <p className="p-0 m-0 p-container">
                          {splitText?.map((s, indexCus) => {
                            const returnColor = (id) => {
                              if (id === 0) {
                                return "#64bf75";
                              }
                              if (id === 2) {
                                return "#64bf75";
                              }
                              if (id === 4) {
                                return "#00b8c6";
                              }
                              if (id > 4) {
                                return "#7e81bc";
                              }
                            };
                            return (
                              <div
                                style={{
                                  color:
                                    indexCus % 2 == 0
                                      ? returnColor(indexCus)
                                      : "#636f80",
                                  fontWeight: indexCus % 2 == 0 ? "500" : "400",
                                  fontFamily: "Roboto",
                                  fontSize: "12px",
                                  margin: "0",
                                  padding: "0",
                                  lineHeight: "15px",
                                  paddingTop: indexCus % 2 == 0 && "10px",
                                }}
                              >
                                {s}
                              </div>
                            );
                          })}
                        </p>
                      </div>

                      <Card.Body
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Row
                          style={{
                            padding: "0 10px",
                            marginTop: "auto",
                            width: "100%",
                            textAlign: "center",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor:
                                e.product_id === 107
                                  ? "#64bf75"
                                  : e.product_id === 108
                                  ? "#00b8c6"
                                  : "#7e81bc",
                              marginTop: "auto",
                            }}
                            className="certain-ch"
                          >
                            {e.product_id === 107
                              ? "Онцлох гэр бүлийн сувгууд"
                              : e.product_id === 108
                              ? "Онцлох кино сувгууд"
                              : "Онцлох спортын контентууд"}
                          </div>
                          <CardImg
                            // src={webUrl + e.picture}
                            src={e.certain_img}
                            style={{
                              width: "250px",
                              height:
                                e.product_id === 107 || 108 || 109
                                  ? "78px"
                                  : "120px",
                              marginTop: "10px",
                              marginLeft: "auto",
                              marginRight: "auto",
                              marginBottom: "5px",
                            }}
                          />
                          <Button
                            variant="outline-primary"
                            style={{
                              marginTop: "auto",
                              width: "100%",
                            }}
                            onClick={() => {
                              props.selectProduct({
                                orderType: UPGRADE_PRODUCT,
                                product: el,
                                price: parseInt(el.amount),
                              });
                              navigate("/order");
                            }}
                          >
                            Сонгох
                          </Button>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                )
              );
            })
          )}
        </Row>
      </>
    ) : (
      <></>
    );
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Container fluid className="section section-content mar-top-40">
      <Container className="section-content">
        <Row className="g-4 ">
          <h3>Үндсэн багцууд</h3>
          {product?.productList?.length > 2 ? (
            <div style={{ display: "flex", gap: "5px" }} className="package">
              <Slider {...settings}>{products}</Slider>
            </div>
          ) : (
            <Row md={3}>{products}</Row>
          )}
        </Row>
        <Row className="g-4 ">{upgradeProducts}</Row>
      </Container>
    </Container>
  );
}

const mapActionToProps = (dispatch) => {
  return {
    getProduct: () => dispatch(actions.getProduct()),
    getAdditional: (id) => dispatch(actions.getAdditionalProduct(id)),
    getUpgrade: (id) => dispatch(actions.getUpgradeProduct(id)),
    selectProduct: (product) => dispatch(actions.selectProduct(product)),
  };
};
export default connect(null, mapActionToProps)(LoginProducts);
