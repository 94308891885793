import { GET_LIVE } from "../actions/types";

const initialState = null;
export const liveReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_LIVE: {
      return payload;
    }
    default: {
      return state;
    }
  }
};
