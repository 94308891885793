import { Row, Col, ListGroup, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import Packages from "./Package";
import FAQ from "../Help/FAQ";
import LoginProducts from "./LoginProducts";
import ChannelList from "./ChannelList";
export default function Products() {
  const auth = useSelector((state) => state.auth);
  return (
    <>
      {auth.isLoggedIn ? <LoginProducts /> : <Packages />}
      <div className="section-content">
        <Row className=" help-menu">
          <div className="small-12 columns">
            <Tab.Container defaultActiveKey="#zaavar">
              <Col style={{ display: "flex", justifyContent: "space-around" }}>
                <ListGroup className="list-group-horizontal-md">
                  <ListGroup.Item action href="#channels" className="tabs-title help">
                    <i class="tips"></i>Сувгийн жагсаалт
                  </ListGroup.Item>
                  <ListGroup.Item action href="#sanamj" className="tabs-title help">
                    <i class="tips"></i>Багц ахиулах
                  </ListGroup.Item>
                  <ListGroup.Item action href="#tuslamj" className="tabs-title help">
                    <i class="tips"></i>Тусламж
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <div style={{ marginTop: "20px" }}>
                <Tab.Content>
                  <Tab.Pane eventKey="#channels">
                    <div class="p-table" style={{ marginTop: "30px", textAlign: "center" }}>
                      <h4
                        style={{
                          textAlign: "center",
                          fontWeight: "500",
                          marginBottom: "30px",
                        }}
                        className=""
                      >
                        ШИНЭЧЛЭГДСЭН БАГЦУУДЫН СУВГИЙН ЖАГСААЛТ
                      </h4>
                      <ChannelList />
                    </div>
                    <div class="product-mobile" style={{ marginTop: "30px" }}>
                      <h4
                        style={{
                          textAlign: "center",
                          fontWeight: "600",
                          marginBottom: "30px",
                        }}
                        className=""
                      >
                        ШИНЭЧЛЭГДСЭН БАГЦУУДЫН СУВГИЙН ЖАГСААЛТ
                      </h4>
                      <ChannelList />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
                <Tab.Content>
                  <Tab.Pane eventKey="#sanamj">
                    <h4 class="align">Багц ахиулах</h4>
                    <p className="text-left">1. Хэрэглэгч та заавал одоо үзэж байгаа багцынхаа хугацааг дуусахыг хүлээлгүй багцын зөрүү төлбөрийг төлөөд үлдэгдэл 30 хүртэлх хоногийг илүү олон сувагтай том багцаар ахиулан үзэх боломжтой. Үйлчилгээний үлдэгдэл хоногоо тухайн ахиулах багцынхаа хоногийн тарифаар үржүүлсэн үнийн дүнгээр ДДЭШ дансаа цэнэглээрэй. </p>

                    <div class="p-table">
                      <h4 class="mar-top-30 text-left">Багц ахиулах тариф</h4>
                      <div class="row thr ">
                        <div class="medium-1 column thead">№</div>
                        <div class="medium-5 column thead">Одоо хэрэглэж байгаа багц</div>
                        <div class="medium-3 column thead">Шилжих багц</div>
                        <div class="medium-3 column thead">Хоногийн тариф</div>
                      </div>
                      <div class="row align">
                        <div class="medium-1 column trow">1</div>
                        <div class="medium-5 column trow">Standard</div>
                        <div class="medium-3 column trow">Happy /M/</div>
                        <div class="medium-3 column trow">350₮</div>
                        <div class=" column thr"></div>
                      </div>
                      <div class="row align">
                        <div class="medium-1 column trow">2</div>
                        <div class="medium-5 column trow">Standard</div>
                        <div class="medium-3 column trow">Super /L/</div>
                        <div class="medium-3 column trow">500₮</div>
                        <div class=" column thr"></div>
                      </div>
                      <div class="row align">
                        <div class="medium-1 column trow">3</div>
                        <div class="medium-5 column trow">Happy</div>
                        <div class="medium-3 column trow">Super /L/</div>
                        <div class="medium-3 column trow">170₮</div>
                        <div class=" column thr"></div>
                      </div>
                    </div>
                    <div class="product-mobile">
                      <table>
                        <thead>
                          <th>№</th>
                          <th>Одоо хэрэглэж байгаа багц</th>
                          <th>Шилжих багц</th>
                          <th>Хоногийн тариф</th>
                        </thead>
                        <tbody>
                          <th>1</th>
                          <th>Standard</th>
                          <th>Happy /M/</th>
                          <th>350₮</th>
                        </tbody>
                        <tbody>
                          <th>2</th>
                          <th>Standard</th>
                          <th>Super /L/</th>
                          <th>500₮</th>
                        </tbody>
                        <tbody>
                          <th>3</th>
                          <th>Happy</th>
                          <th>Super /L/</th>
                          <th>170₮</th>
                        </tbody>
                      </table>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
                <Tab.Content>
                  <Tab.Pane eventKey="#tuslamj">
                    <div className="text-left">
                      <FAQ id={38} />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
        </Row>
      </div>
    </>
  );
}
