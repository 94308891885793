import React, { createContext, useContext, useState } from "react";

const StateContext = createContext("");

export const ContextProvider = ({ children }) => {
  const [initial, setInitial] = useState(false);
  const [button, setButton] = useState(0);
  return (
    <StateContext.Provider
      value={{
        initial,
        setInitial,
        button,
        setButton,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
