import { Route, Routes, Navigate } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Routing } from "../helper/paths";

export default function MainRoutes() {
  return (
    <Routes>
      {Routing.Public.map((e, i) => (
        <Route path={e.path} element={e.component} key={i} />
      ))}

      {Routing.Private.map((e, i) => (
        <Route
          path={e.path}
          element={<PrivateRoute>{e.component}</PrivateRoute>}
          key={i}
        />
      ))}

      {Routing.Special.map((e, i) => (
        <Route
          key={i}
          path={e.path}
          element={<SpecialRoute>{e.component}</SpecialRoute>}
        />
      ))}
    </Routes>
  );
}

const SpecialRoute = ({ children }) => {
  const state = useSelector((state) => state, shallowEqual);
  if (state.auth.isLoggedIn) {
    return <Navigate to="/" />;
  }
  return children;
};

const PrivateRoute = ({ children }) => {
  const state = useSelector((state) => state, shallowEqual);
  if (!state.auth.isLoggedIn) {
    return <Navigate to="/login" />;
  }
  return children;
};
