import axios from "axios";
import store from "../store";
import {
  anonymousSecret,
  baseUrl,
  chatBotSecret,
  webApiUrl,
} from "../helper/globals";
import * as actions from "../actions/auth";

export const getSecured = (url, onSuccess, onError) => {
  const state = store?.getState();
  // console.log(state.auth.user.access_token);
  try {
    axios
      .get(baseUrl + url, {
        headers: {
          Authorization: state.auth.user.access_token,
        },
      })
      .then((res) => {
        interceptor(res);
        onSuccess(res);
      })
      .catch((err) => {
        onError(err);
      });
  } catch (error) {
    console.log(error);
  }
};

export const postSecured = (url, body, onSuccess, onError) => {
  const state = store.getState();
  axios
    .post(baseUrl + url, body, {
      headers: {
        Authorization: state.auth.user.access_token,
      },
    })
    .then((res, dispatch) => {
      interceptor(res, dispatch);
      onSuccess(res);
    })
    .catch((err) => {
      onError(err);
    });
};
export const getAnonymous = (url, onSuccess, onError) => {
  axios
    .get(baseUrl + url, {
      headers: {
        Authorization: anonymousSecret,
      },
    })
    .then((res) => {
      onSuccess(res);
    })
    .catch((err) => {
      onError(err);
    });
};
export const postAnonymous = (url, body, onSuccess, onError) => {
  console.log(body);
  axios
    .post(baseUrl + url, body, {
      headers: {
        Authorization: anonymousSecret,
      },
    })
    .then((res) => {
      onSuccess(res);
    })
    .catch((err) => {
      onError(err);
    });
};
export const postBank = (url, body, onSuccess, onError) => {
  axios
    .post(webApiUrl + url, body, {
      headers: {
        Authorization: anonymousSecret,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      onSuccess(res);
    })
    .catch((err) => {
      onError(err);
    });
};
export const postChatBot = (url, body, onSuccess, onError) => {
  axios
    .post(baseUrl + url, body, {
      headers: {
        Authorization: chatBotSecret,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      onSuccess(res);
    })
    .catch((err) => {
      onError(err);
    });
};
export const get = (url, onSuccess, onError) => {
  const state = store.getState();
  const isLoggedIn = state.auth.isLoggedIn;
  console.log("isLoggedIn", isLoggedIn);
  if (isLoggedIn) {
    return getSecured(url, onSuccess, onError);
  } else return getAnonymous(url, onSuccess, onError);
};
export const post = (url, body, onSuccess, onError) => {
  const state = store.getState();
  const isLoggedIn = state.auth.isLoggedIn;
  console.log("isLoggedIn", isLoggedIn);
  if (isLoggedIn) {
    return postSecured(url, body, onSuccess, onError);
  } else return postAnonymous(url, body, onSuccess, onError);
};
const interceptor = (res) => {
  if (res.data.resultCode === "Unauthorized") {
    store.dispatch(actions.logout());
  } else if (res.data.isSuccess === false) {
    throw res;
  }
};
