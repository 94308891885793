import { Button, Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import OrderForm from "./OrderForm";
import { useNavigate } from "react-router";
export default function NewOrderForm() {
  const [buttonClicked, setButtonClicked] = useState(true);
  const navigate = useNavigate();
  const [button, setButton] = useState(0);
  const handleSelectProduct = (value) => {
    if (value == button) {
      setButton(0);
    } else {
      setButton(value);
    }
  };
  return (
    <>
      {" "}
      <div className="container">
        <div className="width-100">
          <div className="select-cell mt-3 mt-md-3">
            <h6>
              Флаштай багц төхөөрөмж
              <br />
              <span style={{ fontSize: "14px" }}>299'000₮ /НӨАТ орсон/</span>
            </h6>
            <div className="img-container shadow">
              <img
                className="img-product"
                src="/lunar/300x300 bagts1.jpg"
                alt=""
              />
              <div className="content-container">
                <div className="img-content text-cus3 lineheight-cus">
                  <span className="m-0">
                    <strong>
                      <i
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        Багцад дагалдах зүйлc:
                      </i>
                    </strong>
                    <br />
                    &#x2022; SUPER багцын 3 сарын эрх
                    <br />
                    &#x2022; 60см, 75см антен (1ш)
                    <br />
                    &#x2022; Дуран (1ш)
                    <br />
                    &#x2022; Дурангийн хаалт (1ш)
                    <br />
                    &#x2022; Хүлээн авагч (1ш)
                    <br />
                    &#x2022; Смарт карт (1ш)
                    <br />
                    &#x2022; Төмөр толгой (2ш)
                    <br />
                    &#x2022; Кабел утас (10м)
                    <br />
                    &#x2022; Флаш диск /128GB/
                    <br />
                    &#x2022; Удирдлага
                  </span>
                </div>
                {button === 3 ? (
                  <div
                    onClick={() => {
                      handleSelectProduct(3);
                    }}
                    className="img-content-btn"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-check-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      handleSelectProduct(3);
                    }}
                    className="img-content-btn2"
                  >
                    Сонгох
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="select-cell mt-3 mt-md-3">
            <h6>
              Флашгүй багц төхөөрөмж <br />
              <span style={{ fontSize: "14px" }}>269'000₮ /НӨАТ орсон/</span>
            </h6>
            <div className="img-container shadow">
              <img
                className="img-product"
                src="/lunar/300x300 bagts2.jpg"
                alt=""
              />
              <div className="content-container">
                <div className="img-content text-cus3 lineheight-cus">
                  <span className="m-0">
                    <strong>
                      <i
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        Багцад дагалдах зүйлc:
                      </i>
                    </strong>
                    <br />
                    &#x2022; HAPPY багцын 3 сарын эрх
                    <br />
                    &#x2022; 60см, 75см антен (1ш)
                    <br />
                    &#x2022; Дуран (1ш)
                    <br />
                    &#x2022; Дурангийн хаалт (1ш)
                    <br />
                    &#x2022; Хүлээн авагч (1ш)
                    <br />
                    &#x2022; Смарт карт (1ш)
                    <br />
                    &#x2022; Төмөр толгой (2ш)
                    <br />
                    &#x2022; Кабел утас (10м)
                    <br />
                    &#x2022; Удирдлага
                  </span>
                </div>
                {/* test */}
                {button === 4 ? (
                  <div
                    onClick={() => {
                      handleSelectProduct(4);
                    }}
                    className="img-content-btn"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-check-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      handleSelectProduct(4);
                    }}
                    className="img-content-btn2"
                  >
                    Сонгох
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {buttonClicked ? <OrderForm callType="988" /> : null}
    </>
  );
}
