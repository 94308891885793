import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { webApiUrl, webUrl } from "../helper/globals";
import { useStateContext } from "../context/ContextProvider";
var _ = require("lodash");
export default function Device() {
  const navigate = useNavigate();
  const { button, setButton } = useStateContext();
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [pics, setPics] = useState(null);
  const [accessories, setAccessories] = useState([]);
  const [array, setArray] = useState([]);

  useEffect(() => {
    const api = [
      webApiUrl + `get_devices/accessory`,
      webApiUrl + `devices/${id}`,
    ];
    axios
      .all(api.map((e) => axios.get(e)))
      .then((res) => {
        setAccessories(res[0].data.result.result);
        setProduct(res[1].data.result[0]);
        setArray(
          res[1].data.result[0].options !== "null"
            ? res[1].data.result[0].options.split(",")
            : []
        );
        setPics(res[1].data.result[0].picture);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [id]);
  // console.log(product);
  function productView() {
    return (
      <Row className="section-content">
        <Col md={{ span: 4, offset: 1 }}>
          <Slider>
            {" "}
            <img
              src={webUrl + pics}
              alt="product"
              onError={(e) => {
                e.target.onError = null;
                e.target.src = webUrl + "img/default.png";
              }}
            />
          </Slider>
        </Col>
        <Col md={6}>
          <h4 className="device-info">{product?.device_title}</h4>
          <div class="store-item-desc mar-btm-30">
            <h5
              className="text-justify"
              style={{ fontSize: "14", color: "#868181", margin: "10px 0" }}
            >
              {product?.device_content}
            </h5>
            {array?.length > 0 ? (
              <span>
                <i>Үнэд дараах зүйлс багтсан болно:</i>
              </span>
            ) : null}
            {array?.map((e) => {
              return (
                <div class="medium-12">
                  <span>{_.find(accessories, { alias: e })?.device_title}</span>
                </div>
              );
            })}
          </div>
          <h4
            className="dd-values-con"
            style={{
              fontSize: "1.4rem",
              lineHeight: "22px",
              color: "#b0b0b0",
              marginTop: "10px",
            }}
          >
            <CurrencyFormat
              value={product?.price}
              displayType="text"
              thousandSeparator="'"
            />
            ₮
          </h4>

          {product?.purchase ? (
            <Row>
              <div
                className="small-12 medium-6 columns gift"
                style={{ marginTop: "20px" }}
              >
                {product.device_id == 23 || product.device_id == 24 ? (
                  <button
                    onClick={() => {
                      product.device_id == 23 ? setButton(1) : setButton(2);
                      navigate("/online-order-form/4");
                    }}
                    className="button expanded store-btn-more"
                    // to="/neworder"
                  >
                    ТӨХӨӨРӨМЖ ЗАХИАЛАХ
                  </button>
                ) : (
                  <Link
                    // onClick={() => {
                    //   product.price == 300000 ? setButton(2) : setButton(1);
                    //   navigate("/online-order-form/4");
                    // }}
                    className="button expanded store-btn-more"
                    to="/neworder"
                  >
                    ЗАХИАЛАХ
                  </Link>
                )}{" "}
              </div>
            </Row>
          ) : null}
        </Col>
      </Row>
    );
  }
  return (
    <Container fluid className="section">
      {productView()}
    </Container>
  );
}
