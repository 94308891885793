import { CLEAR_ROUTE, SET_ROUTE } from "../actions/types";

const initialState = null;
export default function routeReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ROUTE:
      return {
        ...state,
        route: payload,
      };
    case CLEAR_ROUTE:
      return {
        state: null,
      };
    default:
      return state;
  }
}
