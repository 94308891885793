import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Container, Form, ListGroup, Row, Tab } from "react-bootstrap";
import { webApiUrl, webUrl } from "../../helper/globals";
import Maps from "./Map";
import BranchMap from "../BranchMap";

export function timeTableParse(text) {
  if (text === null) return "";
  let timetable = text?.split("$$$");
  let rText = "";
  timetable?.forEach((e) => {
    let s = e.split("@");
    if (s[0][0] === ",") {
      s[0] = s[0].substring(1);
    }
    s[0] = s[0].replace(new RegExp("([1])+", "g"), "Да");
    s[0] = s[0].replace(new RegExp("([2])+", "g"), "Мя");
    s[0] = s[0].replace(new RegExp("([3])+", "g"), "Лх");
    s[0] = s[0].replace(new RegExp("([4])+", "g"), "Пү");
    s[0] = s[0].replace(new RegExp("([5])+", "g"), "Ба");
    s[0] = s[0].replace(new RegExp("([6])+", "g"), "Бя");
    s[0] = s[0].replace(new RegExp("([7])+", "g"), "Ня");
    if (s[2] === "rest") {
      s[1] = "Амарна";
    }
    rText += `<div class="col-sm">${s[0]}</div><div class="col-sm"> ${s[1]}</div>`;
  });
  return rText;
}
export default function Branches() {
  const [tab, setTab] = useState("branch-map");
  const [branches, setBranches] = useState([]);
  const [areas] = useState([
    {
      areaName: "Улаанбаатар",
      areaCode: "ub",
    },
    {
      areaName: "Орон нутаг",
      areaCode: "rural",
    },
  ]);
  const [selectedArea, setSelectedArea] = useState("ub");
  const [branchType] = useState([
    {
      typeName: "Бүгд",
      typeCode: "all",
    },
    {
      typeName: "ДДэш салбар",
      typeCode: "undsen",
    },
    {
      typeName: "Юнител салбар",
      typeCode: "unitel",
    },
    {
      typeName: "Засварын төв",
      typeCode: "zasvar",
    },
  ]);
  const [selectedType, setSelectedType] = useState("all");
  const [services] = useState([
    {
      serviceName: "Бүгд",
      serviceCode: "all",
    },
    {
      serviceName: "Багц сунгах",
      serviceCode: "s1",
    },
    {
      serviceName: "Данс цэнэглэх",
      serviceCode: "s2",
    },
    {
      serviceName: "Төхөөрөмж борлуулах",
      serviceCode: "s3",
    },
    {
      serviceName: "Хүлээн авагч борлуулах",
      serviceCode: "s4",
    },
    {
      serviceName: "Дагалдах хэрэгсэл борлуулах",
      serviceCode: "s5",
    },
  ]);
  const [selectedService, setSelectedService] = useState("all");
  const [selectedDoor, setSelectedDoor] = useState("all");
  const [door] = useState([
    {
      doorName: "Бүгд",
      doorCode: "all",
    },
    {
      doorName: "Нээлттэй",
      doorCode: "open",
    },
  ]);
  useEffect(() => {
    axios
      .get(
        webApiUrl +
          `get_branches/${selectedArea}/${selectedType}/${selectedService}/${selectedDoor}`
      )
      .then((res) => {
        let re = [];
        res.data.result.map((e) => {
          return re.push({ ...e, time: timeTableParse(e.timetable) });
        });
        setBranches(re);
      });
  }, [selectedArea, selectedService, selectedType, selectedDoor]);

  return (
    <>
      <Tab.Container activeKey={tab} onSelect={(k) => setTab(k)}>
        <Container
          fluid
          className="section-content"
          style={{ paddingBottom: 0 }}
        >
          <Row>
            <ListGroup className="list-group-horizontal-md">
              <ListGroup.Item
                action
                eventKey="branch-map"
                href
                className="tabs-title tabs"
              >
                БОРЛУУЛАЛТЫН СУВГУУД
              </ListGroup.Item>
              <ListGroup.Item
                action
                eventKey="map"
                href
                className="tabs-title tabs"
              >
                ГАЗРЫН ЗУРАГ ДЭЭР ХАРАХ
              </ListGroup.Item>
              <ListGroup.Item
                action
                eventKey="list"
                href
                className="tabs-title tabs"
              >
                ЖАГСААЛТААР ХАРАХ
              </ListGroup.Item>
            </ListGroup>
          </Row>
        </Container>

        {tab != "branch-map" && (
          <Container fluid className="section">
            <Form>
              <Row xs={1} s={1} md={3}>
                <Col>
                  <Form.Label>Хот, аймаг</Form.Label>
                  <Form.Select
                    onChange={(e) => setSelectedArea(e.target.value)}
                  >
                    {areas.map((e) => {
                      return <option value={e.areaCode}>{e.areaName}</option>;
                    })}
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Label>Салбарын төрөл</Form.Label>
                  <Form.Select
                    onChange={(e) => setSelectedType(e.target.value)}
                  >
                    {branchType.map((e) => {
                      return <option value={e.typeCode}>{e.typeName}</option>;
                    })}
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Label>Салбарын төлөв</Form.Label>
                  <Form.Select
                    onChange={(e) => setSelectedDoor(e.target.value)}
                  >
                    {door.map((e) => {
                      return <option value={e.doorCode}>{e.doorName}</option>;
                    })}
                  </Form.Select>
                </Col>
              </Row>
            </Form>
          </Container>
        )}

        <Tab.Content style={{ marginTop: tab == "branch-map" ? "-25px" : "0" }}>
          <Tab.Pane eventKey="branch-map" fluid>
            <BranchMap />
          </Tab.Pane>
        </Tab.Content>
        <Tab.Content style={{ padding: "0px 12 px" }}>
          <Tab.Pane eventKey="map" fluid>
            <Maps
              area={selectedArea}
              marker={branches}
              visible={tab === "map"}
            />
          </Tab.Pane>
        </Tab.Content>
        <Tab.Content>
          <Tab.Pane eventKey="list">
            <Row xs={1} md={3}>
              {branches?.map((e) => {
                return (
                  <Col style={{ marginBottom: "20px" }}>
                    <div
                      class="branch-list-item"
                      style={{ textAlign: "center" }}
                    >
                      <img
                        src={webUrl + e.picture}
                        alt=""
                        style={{
                          maxHeight: "195px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      />
                      <div class="item-content" style={{ maxHeight: "325px" }}>
                        <h5> {e.branch_title}</h5>
                        <div style={{ minHeight: "100px" }}>
                          <i class="bairshil"></i>
                          <p class="text-left">{e.branch_content}</p>
                        </div>
                        <div>
                          <i class="timework"></i>
                          <ul class="timetable ">
                            <Row
                              md={2}
                              dangerouslySetInnerHTML={{ __html: e.time }}
                            />
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
}
