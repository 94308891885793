import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { CHARGE_PRODUCT } from "../../actions/types";
import { webUrl } from "../../helper/globals";
import * as actions from "../../actions/product";
import { useState } from "react";
import PleaseLogin from "../modals/PleaseLogin";

function AdditionalChannels(props) {
  const navigate = useNavigate();
  const product = useSelector((state) => state.product);
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  const [openModal, setOpenModal] = useState(false);
  function handleClose() {
    setOpenModal(false);
  }
  if (product?.additionalProductList == null && user != null) {
    props.getAdditional(user?.productId);
    console.log(user?.productId);
    // console.log(user.additionalProducts);
    // console.log(product);
  }
  function getProduct(id) {
    let e = product?.additionalProductList?.find((el) => el.productId === id);
    // console.log(e);
    if (e === undefined) {
      return;
    } else {
      return (
        <Form
          key={e?.productId}
          onSubmit={(event) => {
            event.preventDefault();
            props.selectProduct(
              JSON.parse(event.target.additionalproduct.value)
            );
            navigate("/order");
          }}
        >
          <Form.Select name="additionalproduct" style={{ paddingRight: 5 }}>
            {e?.priceList?.map((s) => {
              return (
                <option
                  value={JSON.stringify({
                    orderType: CHARGE_PRODUCT,
                    product: {
                      productName: e?.productName,
                      productId: e?.productId,
                      productImg: e?.productImg,
                      selected: s,
                    },
                    price: e?.isPromotion ? s.promoPrice : s.price,
                  })}
                >
                  {s.month} сар {s.price}₮
                </option>
              );
            })}
          </Form.Select>
          <Button
            variant="primary"
            type="submit"
            className="float-right"
            style={{ marginBottom: "10px" }}
          >
            Сонгох
          </Button>
        </Form>
      );
    }
  }
  return (
    <Container fluid>
      <Row style={{ textAlign: "justify" }}>
        <Col
          style={{
            display: " flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          xs={12}
          md={6}
        >
          <img src={"/kinochin.png"} alt="" />
        </Col>
        <Col xs={12} md={6}>
          <Row>
            <p>Кино сонирхогчдод зоиулсан нэмэлт багц</p>
            <p>
              <p>
                Энэ багцыг сунгаснаар Холливуд, Монгол болон дэлхийн шинэ, шилдэг кинонуудыг монгол дуу оруулалт, дуу, дүрсний өндөр чанартайгаар монгол орны хаанаас ч ДДэш-ийн бүх кино сувгуудыг үзэх боломжтой. 
              </p>
              <p>
                Сувгийн тоо: 15 / 14HD
                <br />
                Багцын код: К
                <br />
                Үнэ: 10000₮/1сар = 30хоног
              </p>
              <p>
                Багц сунгах заавар: ДДэш-ийн смарт картаа цэнэглээд админ болон гишүүн дугаараасаа <b>К</b> зай аваад <b>Сунгах сарын тоо</b> бичиж 139898 дугаарт илгээнэ. /Жишээ нь: K 1/ 
              </p>
            </p>
          </Row>
          <Row>
            {auth.isLoggedIn ? (
              getProduct("111")
            ) : (
              <Row>
                <Button onClick={() => setOpenModal(true)}>Захиалах</Button>
              </Row>
            )}
          </Row>
        </Col>
      </Row>
      <hr />
      <Row style={{ textAlign: "justify" }}>
        <Col
          style={{
            display: " flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          xs={12}
          md={6}
        >
          <img src={"/fanat.png"} alt="f" />
        </Col>
        <Col xs={12} md={6}>
          <Row>
            <p>Спортын фанатуудад зориулсан нэмэлт багц</p>
            <p>
              <p>
                Монголын болон дэлхийн хамгийн алдартай, олон мянган фанатуудтай спортын лиг, тоглолт, арга хэмжээнүүдийг дуу, дүрсний өндөр чанартайгаар монгол орны хаанаас ч хүлээн авч үзээрэй. 
              </p>
              <p>
                Сувгийн тоо: 5 / 5HD
                <br />
                Багцын код: C
                <br />
                Үнэ: 10000₮/1сар = 30хоног
              </p>
              <p>
                Багц сунгах заавар: ДДэш-ийн смарт картаа цэнэглээд админ болон гишүүн дугаараасаа <b>C</b> зай аваад <b>Сунгах сарын тоо</b> бичиж 139898 дугаарт илгээнэ. /Жишээ нь: C 1/ 
              </p>
            </p>
          </Row>
          <Row>
            {auth.isLoggedIn ? (
              getProduct("112")
            ) : (
              <Row>
                <Button onClick={() => setOpenModal(true)}>Захиалах</Button>
              </Row>
            )}
          </Row>
        </Col>
      </Row>

      <hr />
      <Row style={{ textAlign: "justify" }}>
        <Col
          style={{
            display: " flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          xs={12}
          md={6}
        >
          <img
            src={webUrl + "/u/logo/Banner playboy.jpg"}
            alt="playboy"
            style={{ marginBottom: "10px" }}
          />
        </Col>
        <Col
          xs={12}
          md={6}
          style={{
            display: "flex",
            marginBottom: "10px",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Row>
            <p>Насанд хүрэгчдэд зориулсан нэмэлт багц</p>
            <p>
              Зөвхөн насанд хүрэгчдэд зориулсан телевизийн реалити шоу, кино, контенттой Playboy TV-г албан ёсны эрхтэйгээр хүлээн авч үзээрэй. 
            </p>
              <p>
              Багцын код: 41 
              <br />
              Үнэ: 5900₮/1сар = 30хоног
              </p>
              <p>
                Багц сунгах заавар: ДДэш-ийн смарт картаа цэнэглээд админ болон гишүүн дугаараасаа <b>41</b> зай аваад <b>Сунгах сарын тоо</b> бичиж 139898 дугаарт илгээнэ. /Жишээ нь: 41 1/ 
              </p>
          </Row>
          <Row>
            {auth.isLoggedIn ? (
              getProduct("26")
            ) : (
              <Row>
                <Button
                  style={{ marginBottom: "10px" }}
                  onClick={() => setOpenModal(true)}
                >
                  Захиалах
                </Button>
              </Row>
            )}
          </Row>
        </Col>
      </Row>
      <PleaseLogin isOpen={openModal} onHide={handleClose} />
    </Container>
  );
}
const mapActionToProps = (dispatch) => {
  return {
    getAdditional: (id) => dispatch(actions.getAdditionalProduct(id)),
    selectProduct: (product) => dispatch(actions.selectProduct(product)),
  };
};

export default connect(null, mapActionToProps)(AdditionalChannels);
