import MovieLayout from "./MovieLayout";
import { toast, ToastContainer } from "react-toastify";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { useState, useEffect } from "react";
function Movie_FileUpload() {
  const [file, setFile] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleFileInputChange = (e) => {
    setFile(e.target.files[0]);
  };
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const formData = new FormData();
  formData.append("files", file);
  formData.append("firstname", firstname);
  formData.append("lastname", lastname);
  formData.append("phone_number", phone_number);
  formData.append("title", title);

  const [n_firstname, setN_firstname] = useState(false);
  const [n_lastname, setN_lastname] = useState(false);
  const [n_phone_numher, setN_phone_numher] = useState(false);
  const [n_title, setN_title] = useState(false);
  const [n_history, setN_history] = useState(false);

  // console.log(n_firstname);
  // console.log(n_lastname);
  // console.log(n_phone_numher);
  // console.log(n_title);
  // console.log(n_history);
  const handleNoti = () => {
    if (firstname == "") {
      setN_firstname(true);
    }
    if (lastname == "") {
      setN_lastname(true);
    }
    if (phone_number == "") {
      setN_phone_numher(true);
    }
    if (title == "") {
      setN_title(true);
    }

    if (
      firstname == "" ||
      lastname == "" ||
      phone_number == "" ||
      title == "" ||
      title == "" ||
      file == null
    ) {
      return false;
    } else {
      return true;
    }
  };
  const handleSubmit = async (e) => {
    handleNoti();
    if (handleNoti() == true) {
      e.preventDefault();
      setLoading(true);
      try {
        await axios
          .post(
            `${process.env.REACT_APP_MOVIE_HISTORY_URL}/api/file_upload`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            if (res.data.isSuccess === true) {
              setFirstname("");
              setLastname("");
              setPhone_number("");
              setTitle("");
              setFile(null);
              setLoading(false);
              toast.success(
                "Таны түүхийг хүлээн авлаа, Бид бүртгэгдсэн дугаараар эргэн холбогдох болно. Баярлалаа",
                {
                  position: "top-right",
                }
              );
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error(JSON.stringify(err), {
              position: "top-right",
            });
          });
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.info("Утгыг бүрэн оруулна уу.");
    }
  };
  console.log(file);

  return (
    <MovieLayout>
      {loading && (
        <div className="online-order-container-main-absolute center">
          <div className="loading-body">
            <div className="spinner-border text-primary" role="status"></div>
            <span className="sr-only loading-body-text">Түр хүлээнэ үү...</span>
          </div>
        </div>
      )}
      <ToastContainer />
      <div
        style={{
          color: "black",
          minHeight: "calc(100vh - 130px)",
          paddingBottom: "",
          display: "flex",
          flexDirection: "column",
        }}
        className="uploader-cus px-2"
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingTop: "18px",
          }}
        >
          <Form
            style={{
              width: "400px",
            }}
          >
            <Form.Group className="mb-3" controlId="formFirstName">
              <Form.Control
                style={{
                  color: "black",
                }}
                value={lastname}
                onChange={(e) => {
                  setLastname(e.target.value);
                }}
                type="text"
                placeholder="Овог"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formLastName">
              <Form.Control
                style={{
                  color: "black",
                }}
                value={firstname}
                onChange={(e) => {
                  setFirstname(e.target.value);
                }}
                type="text"
                placeholder="Нэр"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formFirstName">
              <Form.Control
                style={{
                  color: "black",
                }}
                value={phone_number}
                onChange={(e) => {
                  setPhone_number(e.target.value);
                }}
                type="number"
                placeholder="Утасны дугаар"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formTitle">
              <Form.Control
                style={{
                  color: "black",
                }}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                type="text"
                placeholder="Гарчиг"
              />
            </Form.Group>
          </Form>
        </div>

        <form id="file-upload-form" className="uploader">
          <input
            id="file-upload"
            type="file"
            name="fileUpload"
            accept=".pdf,.doc,.docx"
            onChange={handleFileInputChange}
          />

          <label htmlFor="file-upload" id="file-drag">
            {file ? (
              file.name
            ) : (
              <div id="start">
                <i className="fa fa-download" aria-hidden="true"></i>
                <div>Түүхээ файлаар илгээх (.pdf,.doc,.doc)</div>
                <span id="file-upload-btn" className="btn btn-primary">
                  Файл сонгох
                </span>
              </div>
            )}
          </label>
        </form>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            onClick={(e) => {
              handleSubmit(e);
            }}
            style={{
              background: "#2545b8",
              marginTop: "20px",
              width: "400px",
              height: "46px",
              fontWeight: "500",
              color: "white",
            }}
            className="btn"
          >
            Илгээх
          </button>
        </div>
      </div>
    </MovieLayout>
  );
}

export default Movie_FileUpload;
